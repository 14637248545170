import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import DateRange from "../../../components/DataFilter/DateRange";
import DataNotFound from "../../State/DataNotFound";
import ChartsScrollView from "../../Info/ChartsScrollView";
import { GetStoreAnalysis } from "../../../api/services/store-analysis";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { fusionChartGeneralDefaults, fusionChartExportDefaults, windowWidth } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const DATE_FORMAT = "YYYY-MM-DD";
const END_DATE =
    moment().isoWeekday() <= 5 ? moment().subtract(1, "weeks").endOf("isoweek").toDate() : moment().toDate();

const chartConfigs = (data, translations, scrollView) => ({
    type: "scrollcolumn2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(scrollView),
            ...fusionChartExportDefaults(translations.chart),
            numberSuffix: "%",
            showPercentValues: "1",
            showLegend: "0",
            numVisiblePlot: windowWidth ? 6 : 14,
            xAxisname: translations.week,
            yAxisname: translations.visitors
        },
        // Chart Data
        ...data
    }
});

const getWeekStartEndDays = (week_no, format) => {
    format = format || "YYYY-MM-DD";
    let startDate = moment().isoWeek(week_no).startOf("week").format(format);
    let endDate = moment().isoWeek(week_no).endOf("week").format(format);

    return [startDate, endDate];
};

const momentSubtract = (date, duration, unit) => moment(date).subtract(duration, unit).toDate();

const WeeklyVisitors = ({ subProcesses }) => {
    const [startDate, setStartDate] = useState(momentSubtract(END_DATE, 45, "days"));
    const [minDate] = useState(momentSubtract(undefined, 90, "days"));
    const [endDate, setEndDate] = useState(END_DATE);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState("");
    const [data, setData] = useState([{}]);
    const [scrollView, setScrollView] = useState(true);
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Weekly Visitor Average"),
        week: t("Week"),
        ratio: t("Ratio"),
        visitors: t("Visitors Ratio"),
        weekdays: t("Weekdays"),
        weekend: t("Weekend")
    };

    const handleStart = date => setStartDate(date);
    const handleEnd = date => setEndDate(date);
    const handleSubmit = () => {
        if (startDate && endDate && endDate >= startDate) {
            setErrors("");
            setLoading(true);
            fetchData();
        } else {
            setErrors("is-invalid");
        }
    };

    const generateData = (data, key, color, text) => {
        return _.map(data, item => {
            let week = item.week;
            let weekData = { weekdays: Math.round(item.weekdays), weekend: Math.round(item.weekend) };
            let total = weekData.weekdays + weekData.weekend;
            let ratio = parseFloat(((weekData[key] / total) * 100).toFixed(2));

            return {
                value: ratio,
                color: color,
                tooltext: `
                    <div style="font-size: 110%;margin-bottom:5px">${t(text)}</div>
                    ${week}. ${TRANSLATIONS.week} (${getWeekStartEndDays(week, "DD MMMM YYYY").join(" - ")})<br/>
                    <b>${TRANSLATIONS.ratio}:</b> ${ratio}%<br/>
                    <b>${TRANSLATIONS.visitors}:</b> ${Math.round(item[key]).readable()}
                `
            };
        });
    };

    const fetchData = () => {
        try {
            GetStoreAnalysis({
                sid: getStoreKey(),
                interval: "weekly",
                startDate: moment(startDate).format(DATE_FORMAT),
                endDate: moment(endDate).format(DATE_FORMAT),
                tz: getStoreTimezone(),
                spid: subProcesses,
                rnd: new Date().getTime()
            }).then(data => {
                if (isMountedRef.current) {
                    if (data && !_.isEmpty(data)) {
                        let category = _.map(data, item => {
                            return {
                                label: `${item.week}. ${TRANSLATIONS.week}`
                            };
                        });

                        let weekdays = generateData(data, "weekdays", "#00c9a7", "Weekdays");
                        let weekend = generateData(data, "weekend", "#f2b876", "Weekend");

                        setData({
                            categories: [
                                {
                                    category
                                }
                            ],
                            dataset: [
                                {
                                    seriesname: TRANSLATIONS.weekdays,
                                    data: weekdays
                                },
                                {
                                    seriesname: TRANSLATIONS.weekend,
                                    data: weekend
                                }
                            ]
                        });
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (subProcesses) fetchData();
    }, [isMountedRef, subProcesses]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={moment().toDate()}
                    handleStart={handleStart}
                    handleEnd={handleEnd}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    className={errors}
                />
            </div>
            <ChartsScrollView onChange={event => setScrollView(event.target.checked)} />
            <div className="card-body pb-1 pl-1">
                {data ? (
                    <ReactFC {...chartConfigs(data, TRANSLATIONS, scrollView)} />
                ) : (
                    <DataNotFound className="pb-3 pl-3" />
                )}
            </div>
        </div>
    );
};

export default WeeklyVisitors;
