import React from "react";
import { useTranslation } from "react-i18next";

const DataNotFound = ({ className = "" }) => {
    const { t } = useTranslation();

    return (
        <div className={`text-center text-warning font-italic ${className}`}>
            {t("There is no log to display selected dates")}
        </div>
    );
};

export default DataNotFound;
