import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import authRoutes from "../routes/authRoutes";
import Title from "../components/Title";

const Auth = () => {
    return (
        <Switch>
            <Redirect exact from="/auth" to="/auth/login" />
            {authRoutes.map((route, key) => {
                if (route.layout === "/auth") {
                    return (
                        <Route
                            {...route.prop}
                            key={key}
                            path={route.layout + route.path}
                            component={props => (
                                <>
                                    <Title title={route.title} />
                                    <route.component {...props} />
                                </>
                            )}
                        />
                    );
                } else return <Redirect key="404" exact to="/404" />;
            })}
        </Switch>
    );
};

export default Auth;
