import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import think from "../../assets/images/illustrations/think.svg";
import Logo from "../../components/Logo";
import Title from "../../components/Title";
import Footer from "../../components/App/Footer";

const _404 = () => {
    const { t } = useTranslation();

    return (
        <main id="content" role="main" className="main">
            <Title title="404" />
            <div className="container">
                <Link className="position-absolute top-0 left-0 right-0 pt-5" to="/">
                    <Logo className="avatar avatar-xl w-100 avatar-centered" />
                </Link>
                <div className="footer-height-offset d-flex justify-content-center align-items-center flex-column">
                    <div className="row align-items-sm-center w-100">
                        <div className="col-sm-6">
                            <div className="text-center text-sm-right mr-sm-4 mb-5 mb-sm-0">
                                <img
                                    className="w-60 w-sm-100 mx-auto"
                                    src={think}
                                    alt="404"
                                    style={{ maxWidth: "15rem" }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 text-center text-sm-left">
                            <h1 className="display-1 mb-0">404</h1>
                            <p className="lead">{t("Sorry, the page you are looking for cannot be found")}.</p>
                            <Link className="btn btn-primary" to="/">
                                {t("Go back to the App")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default _404;
