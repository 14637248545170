import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageHeader from "../../PageHeader";
import PermissionChecker from "../../../PermissionChecker";
import DataLoader from "../../../../components/DataLoader";
import CardCheckbox from "../components/CardCheckbox";
import Default from "../../../Buttons/Default";
import { useData } from "../DataContext";
import { isUserGroup } from "../../../../utils/information";
import { ListCameras } from "../../../../api/services/cameras";
import { CreateAnalysis, UpdateAnalysis } from "../../../../api/services/analysis";
import { getAccountInformation } from "../../../../utils/information";
import { useIsMountedRef } from "../../../../utils/core";

const Cameras = () => {
    const { setValues, data } = useData();
    const isMountedRef = useIsMountedRef();
    const [selected, setSelected] = useState(data?.camera || 0);
    const [cameras, setCameras] = useState(null);
    const [loading, setLoading] = useState(false);
    const { current_store_id } = getAccountInformation();
    const { push } = useHistory();
    const { t } = useTranslation();

    const onSubmit = () => {
        setLoading(true);

        if (data.sub_process) {
            if (data.camera !== selected) {
                UpdateAnalysis({ sub_process_id: data.sub_process, camera_id: selected }).then(result => {
                    if (isMountedRef.current) {
                        if (result?.status === 200) {
                            setValues({ camera: selected });
                            push("/analysis/select-product");
                        }

                        setLoading(false);
                    }
                });
            } else push("/analysis/select-product");
        } else {
            let name = t("Untitled");

            CreateAnalysis({ camera_id: selected, name: name }).then(result => {
                if (isMountedRef.current) {
                    if (result?.status === 200) {
                        setValues({
                            sub_process: result.sub_process_id,
                            name: name,
                            camera: selected
                        });

                        push("/analysis/select-product");
                    }

                    setLoading(false);
                }
            });
        }
    };

    const fetchCameras = () => {
        ListCameras({ store_id: current_store_id }).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) {
                    setCameras(result?.data || []);
                } else setCameras([]);
            }
        });
    };

    useEffect(() => {
        fetchCameras();
    }, [isMountedRef]);

    return (
        <PermissionChecker condition={isUserGroup("support")} description="You don't have permission for Analysis.">
            <div className="content container-fluid">
                <PageHeader
                    description={data?.name || t("Create Analysis")}
                    component={() => (
                        <Default
                            loading={loading}
                            type="button"
                            color="success"
                            disabled={selected ? false : true}
                            onClick={onSubmit}>
                            {t("Next")}
                        </Default>
                    )}>
                    {t("Select Camera")}
                </PageHeader>
                <div className="row">
                    <DataLoader data={cameras}>
                        {cameras?.map(camera => (
                            <div key={camera.id} className="col-md-4 col-sm-6 mb-4">
                                <CardCheckbox
                                    id={camera.id}
                                    title={camera.name}
                                    onChange={setSelected}
                                    selected={selected}
                                    buttonText="Camera"
                                    name="cameras"
                                    icon="tio-surveillance-camera"
                                />
                            </div>
                        ))}
                    </DataLoader>
                </div>
            </div>
        </PermissionChecker>
    );
};

export default Cameras;
