import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import CustomSelect from "../../DataFilter/CustomSelect";
import DataNotFound from "../../State/DataNotFound";
import { GetStoreAnalysis } from "../../../api/services/store-analysis";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const chartConfigs = (data, translations, scrollView) => {
    let total = _.sumBy(data, "value");

    return {
        type: "doughnut2d",
        width: "100%",
        height: "450",
        dataFormat: "json",
        dataSource: {
            chart: {
                ...fusionChartGeneralDefaults(scrollView),
                ...fusionChartExportDefaults(translations.chart),
                showPercentValues: "1",
                showLegend: "0",
                manageLabelOverflow: "1",
                pieRadius: "120",
                showPlotBorder: 1,
                plotBorderThickness: 1,
                plotBorderColor: "#ffffff",
                xAxisname: translations.month,
                yAxisname: translations.visitors,
                defaultCenterLabel: `${translations.total}: ${total.readable()}`
            },
            // chart Data
            data: data
        }
    };
};

const VisitorDistributions = ({ subProcesses }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dates, setDates] = useState();
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Monthly Visitor Distribution"),
        month: t("Month"),
        visitors: t("Visitors Count"),
        total: t("Total Visitor")
    };

    const fetchData = () => {
        try {
            const [startDate, endDate] = dates;
            setLoading(true);

            GetStoreAnalysis({
                sid: getStoreKey(),
                interval: "monthly_distribution",
                startDate: startDate,
                endDate: endDate,
                tz: getStoreTimezone(),
                spid: subProcesses,
                rnd: new Date().getTime()
            }).then(data => {
                if (isMountedRef.current) {
                    if (!_.isEmpty(data)) {
                        let total = _.sumBy(data, "value");

                        data = _.map(data, item => {
                            let color;
                            let month = moment(item.month, "MM-YYYY");

                            if (item.value < total * 0.06) color = "#ccf4ed";
                            else if (item.value < total * 0.11) color = "#b3efe5";
                            else if (item.value < total * 0.17) color = "#99e9dc";
                            else if (item.value < total * 0.22) color = "#80e4d3";
                            else if (item.value < total * 0.28) color = "#66dfca";
                            else if (item.value < total * 0.33) color = "#4dd9c1";
                            else if (item.value < total * 0.39) color = "#33d4b9";
                            else if (item.value < total * 0.44) color = "#1aceb0";
                            else if (item.value < total * 0.5) color = "#00c9a7";
                            else if (item.value < total * 0.56) color = "#00b596";
                            else if (item.value < total * 0.61) color = "#00b596";
                            else if (item.value < total * 0.67) color = "#00a186";
                            else if (item.value < total * 0.72) color = "#008d75";
                            else if (item.value < total * 0.78) color = "#007964";
                            else if (item.value < total * 0.83) color = "#006554";
                            else if (item.value < total * 0.89) color = "#005043";
                            else if (item.value < total * 0.94) color = "#003c32";
                            else color = "#002821";

                            return {
                                value: item.value,
                                label: month.format("MMM YYYY"),
                                color: color,
                                isSliced: moment().month() === month.month() ? "1" : "0",
                                tooltext: `
                                    <b>${TRANSLATIONS.month}:</b> ${month.format("MMMM YYYY")}<br/>
                                    <b>${TRANSLATIONS.visitors}:</b> ${item.value.readable()}<br/>
                                `
                            };
                        });

                        setData(data);
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (dates && subProcesses) fetchData();
    }, [dates, isMountedRef, subProcesses]);
    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <CustomSelect
                    options={[
                        {
                            id: 0,
                            name: "This Year",
                            interval: [moment().startOf("year"), moment().endOf("year")]
                        },
                        {
                            id: 1,
                            name: "Last Year",
                            interval: [
                                moment().subtract(1, "years").startOf("year"),
                                moment().subtract(1, "years").endOf("year")
                            ]
                        },
                        {
                            id: 2,
                            name: "Last 12 Months",
                            interval: [moment().subtract(12, "months"), moment()]
                        }
                    ]}
                    selected={2}
                    onChange={interval => setDates(interval)}
                    loading={loading}
                />
            </div>
            <div className="card-body pb-1">
                {data ? <ReactFC {...chartConfigs(data, TRANSLATIONS)} /> : <DataNotFound className="pb-3" />}
            </div>
        </div>
    );
};

export default VisitorDistributions;
