import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import DateRange from "../../DataFilter/DateRange";
import DataNotFound from "../../State/DataNotFound";
import { GetStoreAnalysis } from "../../../api/services/store-analysis";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const DATE_FORMAT = "YYYY-MM-DD";

const chartConfigs = (data, translations, scrollView) => ({
    type: "scrollcolumn2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(scrollView),
            ...fusionChartExportDefaults(translations.chart),
            showPercentValues: "1",
            paletteColors: "#00c9a7",
            xAxisname: translations.hour,
            yAxisname: translations.visitors
        },
        // Chart Data
        ...data
    }
});

const MostIntenseHours = ({ subProcesses }) => {
    const [startDate, setStartDate] = useState(moment().subtract(30, "days").toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState("");
    const [data, setData] = useState([]);
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Most Intense Hours"),
        hour: t("Hour"),
        visitors: t("Visitors Average")
    };

    const handleStart = date => setStartDate(date);
    const handleEnd = date => setEndDate(date);
    const handleSubmit = () => {
        if (startDate && endDate && endDate >= startDate) {
            setErrors("");
            setLoading(true);
            fetchData();
        } else {
            setErrors("is-invalid");
        }
    };

    const fetchData = () => {
        try {
            GetStoreAnalysis({
                sid: getStoreKey(),
                interval: "hours",
                startDate: moment(startDate).format(DATE_FORMAT),
                endDate: moment(endDate).format(DATE_FORMAT),
                tz: getStoreTimezone(),
                spid: subProcesses,
                rnd: new Date().getTime()
            }).then(data => {
                if (isMountedRef.current) {
                    if (!_.isEmpty(data?.data?.intervals)) {
                        let hours = _.orderBy(
                            _.uniqBy(_.map(data.data.intervals, item => moment(item.date).format("HH:mm")))
                        );
                        let generatedData = _.map(hours, hour => {
                            let value =
                                Math.round(
                                    _.meanBy(
                                        _.filter(
                                            data.data.intervals,
                                            item => moment(item.date).format("HH:mm") === hour
                                        ),
                                        item => item["visitor_count"]
                                    ).toFixed(2)
                                ) || 0;

                            return {
                                label: hour,
                                value: value,
                                tooltext: `
                                    <b>${TRANSLATIONS.hour}:</b> ${hour}<br/>
                                    <b>${TRANSLATIONS.visitors}:</b> ${value}
                                `
                            };
                        });

                        setData({
                            categories: [
                                {
                                    category: _.map(generatedData, ({ label }) => ({ label }))
                                }
                            ],
                            dataset: [{ data: _.map(generatedData, ({ value, tooltext }) => ({ value, tooltext })) }]
                        });
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (subProcesses) fetchData();
    }, [isMountedRef, subProcesses]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={moment().toDate()}
                    handleStart={handleStart}
                    handleEnd={handleEnd}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    className={errors}
                />
            </div>
            <div className="card-body pb-1 pl-1">
                {data ? <ReactFC {...chartConfigs(data, TRANSLATIONS)} /> : <DataNotFound className="pb-3 pl-3" />}
            </div>
        </div>
    );
};

export default MostIntenseHours;
