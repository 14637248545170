import axios from "axios";
import Swal from "sweetalert2";

import i18n from "../../utils/i18n";
import "../defaults";
import { CREATE_EXPORT_REPORT, LIST_EXPORT_REPORTS, DOWNLOAD_REPORT } from "../constants";

export const CreateExportReport = params => {
    try {
        return axios
            .post(CREATE_EXPORT_REPORT, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const ListExportReports = params => {
    try {
        return axios
            .post(LIST_EXPORT_REPORTS, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const DownloadReport = path => {
    try {
        return axios
            .post(DOWNLOAD_REPORT, {
                report_path: path
            })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};
