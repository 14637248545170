import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import DateRange from "../../../components/DataFilter/DateRange";
import DataNotFound from "../../State/DataNotFound";
import ChartsScrollView from "../../Info/ChartsScrollView";
import { GetStoreAnalysis } from "../../../api/services/store-analysis";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const DATE_FORMAT = "YYYY-MM-DD";

const chartConfigs = (data, translations, scrollView) => {
    return {
        type: "scrollcombi2d",
        width: "100%",
        height: "450",
        dataFormat: "json",
        dataSource: {
            chart: {
                ...fusionChartGeneralDefaults(scrollView),
                ...fusionChartExportDefaults(translations.chart),
                showLegend: 1,
                lineColor: "#f2b876",
                paletteColors: "#00c9a7",
                drawcrossline: 1,
                crossLineAlpha: 30,
                plotColorinTooltip: 0,
                xAxisname: translations.date,
                yAxisname: translations.visitors
            },
            // Chart Data
            ...data
        }
    };
};

const TotalDailyVisitor = ({ subProcesses }) => {
    const [startDate, setStartDate] = useState(moment().subtract(30, "days"));
    const [endDate, setEndDate] = useState(moment());
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState("");
    const [data, setData] = useState([{}]);
    const [scrollView, setScrollView] = useState(true);
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Total Daily Visitor Count"),
        date: t("Date"),
        average: t("Last 30 Days Average"),
        visitors: t("Visitors Count")
    };

    const handleStart = date => setStartDate(moment(date));
    const handleEnd = date => setEndDate(moment(date));
    const handleSubmit = () => {
        if (startDate && endDate && endDate >= startDate) {
            setErrors("");
            setLoading(true);
            fetchData();
        } else {
            setErrors("is-invalid");
        }
    };

    const fetchData = () => {
        try {
            GetStoreAnalysis({
                sid: getStoreKey(),
                interval: "day",
                average: 1,
                startDate: startDate.format(DATE_FORMAT),
                endDate: endDate.format(DATE_FORMAT),
                tz: getStoreTimezone(),
                spid: subProcesses,
                rnd: new Date().getTime()
            }).then(data => {
                if (isMountedRef.current) {
                    if (!_.isEmpty(data?.data?.intervals)) {
                        data = _.orderBy(data.data.intervals, "date");

                        setData({
                            categories: [
                                {
                                    category: _.map(data, item => {
                                        return {
                                            label: moment(item.date).format("DD MMM")
                                        };
                                    })
                                }
                            ],
                            dataset: [
                                {
                                    seriesname: TRANSLATIONS.visitors,
                                    renderAs: "column",
                                    showValues: "1",
                                    data: _.map(data, item => {
                                        let value = Math.round(item.visitor_count);

                                        return {
                                            value: value,
                                            tooltext: `
                                                <div style="font-size: 110%">
                                                    ${moment(item.date).format("dddd, DD MMMM YYYY")}
                                                </div><br/>
                                                <b><span className="legend-indicator bg-success"></span>
                                                ${TRANSLATIONS.visitors}:</b> ${value.readable()}
                                            `
                                        };
                                    })
                                },
                                {
                                    seriesname: TRANSLATIONS.average,
                                    renderAs: "line",
                                    showValues: "0",
                                    data: _.map(data, item => {
                                        let value = Math.round(item.last_30_days_average);

                                        return {
                                            value: item.last_30_days_average,
                                            tooltext: `
                                                <b><span className="legend-indicator bg-warning"></span>
                                                ${TRANSLATIONS.average}:</b> ${value.readable()}
                                            `
                                        };
                                    })
                                }
                            ]
                        });
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (subProcesses) fetchData();
    }, [isMountedRef, subProcesses]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <DateRange
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    handleStart={handleStart}
                    handleEnd={handleEnd}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    className={errors}
                />
            </div>
            <ChartsScrollView onChange={event => setScrollView(event.target.checked)} />
            <div className="card-body pb-1 pl-1">
                {data ? (
                    <ReactFC {...chartConfigs(data, TRANSLATIONS, scrollView)} />
                ) : (
                    <DataNotFound className="pb-3 pl-3" />
                )}
            </div>
        </div>
    );
};

export default TotalDailyVisitor;
