import axios from "axios";
import Swal from "sweetalert2";

import i18n from "../../utils/i18n";
import "../defaults";
import { ANALYSIS_LIST, ANALYSIS_CREATE, ANALYSIS_UPDATE } from "../constants";

export const ListAnalyses = params => {
    try {
        return axios
            .post(ANALYSIS_LIST, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const CreateAnalysis = params => {
    try {
        return axios
            .post(ANALYSIS_CREATE, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const UpdateAnalysis = params => {
    try {
        return axios
            .post(ANALYSIS_UPDATE, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};
