import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const AlertBar = ({ errorText = "There is a connection issue." }) => {
    const [display, setDisplay] = useState("cursor-pointer");
    const { t } = useTranslation();

    return (
        <div
            className={`position-fixed top-0 left-0 right-0 w-100 z-index-9999 border-0 px-2 py-1 bg-danger text-white font-size-sm d-flex justify-content-between align-items-center ${display}`}
            onClick={() => setDisplay("fade")}>
            <div>{t(errorText)}</div>
            <i className="fa fa-times"></i>
        </div>
    );
};

export default AlertBar;
