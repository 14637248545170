import axios from "axios";

import "../defaults";
import { LOGIN } from "../constants";
import { captureException } from "../../utils/handlers";

export const Login = data => {
    try {
        delete axios.defaults.headers.common["Authorization"];

        return axios
            .post(LOGIN, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error);
            });
    } catch (error) {}
};
