/*
 * Cameralyze — CMZCore
 * @version: 1.0.0 (03 Nov 2020)
 * @requires: jQuery v3.0 or later
 * @author: oguzhan-cameralyze
 * @event-namespace: .CMZCore
 * Copyright 2020 Cameralyze
 */
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import "../vendor/navbar-vertical-aside/navbar-vertical-aside";
import "../vendor/navbar-vertical-aside/navbar-mini-cache";
import "../vendor/unfold/unfold";

$.extend({
    CMZCore: {
        init: function() {
            $(document).ready(function() {
                // Botostrap Tootltips
                $('[data-toggle="tooltip"]').tooltip();

                // Bootstrap Popovers
                $('[data-toggle="popover"]').popover();
            });
        },

        components: {}
    }
});

$.CMZCore.init();
