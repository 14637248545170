import React from "react";
import { useTranslation } from "react-i18next";

import permission from "../assets/images/illustrations/permission.svg";

const PermissionChecker = ({ children, condition, description }) => {
    const { t } = useTranslation();

    if (condition) {
        return children;
    } else {
        return (
            <div className="content container-fluid">
                <div className="row justify-content-center text-center pt-10">
                    <div className="col-9 col-md-7 col-lg-5 col-xl-4">
                        <img className="img-fluid mb-4" src={permission} alt={t("You don't have permission")} />
                        <h1 className="h2">{t("You don't have permission")} 😞</h1>
                        <p>{t(description)}</p>
                    </div>
                </div>
            </div>
        );
    }
};

export default PermissionChecker;
