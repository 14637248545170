import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import MaskedInput from "react-text-mask";
import $ from "jquery";
import _ from "lodash";

import { timeMask } from "../../utils/mask";
import { GetMainProcess, UpdateMainProcess } from "../../api/services/main-processes";
import { useIsMountedRef } from "../../utils/core";
import Loading from "../State/Loading";
import Default from "../Buttons/Default";
import Swal from "sweetalert2";
import { getAccountInformation } from "../../utils/information";

const formFields = [
    {
        text: "Capacity",
        name: "capacity",
        validation: { valueAsNumber: true },
        placeholder: "500",
        mask: null
    },
    {
        text: "Opening Hour",
        name: "open",
        validation: { required: true },
        placeholder: "12:34",
        mask: timeMask
    },
    {
        text: "Closing Hour",
        name: "close",
        validation: { required: true },
        placeholder: "12:34",
        mask: timeMask
    },
    {
        text: "Page Title",
        name: "title",
        validation: {},
        placeholder: "Hoş Geldiniz",
        mask: null
    },
    {
        text: "Entry Message",
        name: "entry_message",
        validation: {},
        placeholder: "Giriş Yapabilirsiniz",
        mask: null
    },
    {
        text: "Warning Message",
        name: "warning_message",
        validation: {},
        placeholder: "Limitimiz Dolmuştur",
        mask: null
    },
    {
        text: "Visitor Count Text",
        name: "visitor_text",
        validation: {},
        placeholder: "Ziyaretçi Sayısı",
        mask: null
    },
    {
        text: "Fullness Ratio Text",
        name: "fullness_text",
        validation: {},
        placeholder: "Doluluk Oranı",
        mask: null
    },
    {
        text: "Max Fullness Text",
        name: "max_fullness_text",
        validation: {},
        placeholder: "Maksimum Kapasite",
        mask: null
    },
    {
        text: "Logo",
        name: "logo",
        validation: {},
        placeholder: "http://...",
        mask: null
    },
    {
        text: "Page Title Color Code",
        name: "title_color",
        validation: {},
        placeholder: "#000000",
        mask: null
    },
    {
        text: "Messages Color Code",
        name: "messages_color",
        validation: {},
        placeholder: "#000000",
        mask: null
    },
    {
        text: "Success Color Code",
        name: "success_color",
        validation: {},
        placeholder: "#000000",
        mask: null
    },
    {
        text: "Warning Color Code",
        name: "warning_color",
        validation: {},
        placeholder: "#000000",
        mask: null
    },
    {
        text: "Danger Color Code",
        name: "danger_color",
        validation: {},
        placeholder: "#000000",
        mask: null
    },
    {
        text: "Max Fullness Color Code",
        name: "max_fullness_color",
        validation: {},
        placeholder: "#000000",
        mask: null
    }
];

const SettingsForm = () => {
    const { register, handleSubmit, control, errors, setValue } = useForm();
    const { fields, append } = useFieldArray({
        control,
        name: "messages"
    });
    //const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();
    const { main_process_id } = useParams();
    const { current_store_id } = getAccountInformation();

    const onSubmit = data => {
        setButtonLoading(true);

        UpdateMainProcess({
            main_process_id: main_process_id,
            store_id: current_store_id,
            settings: {
                ...data,
                messages: _.map(data.messages, message => message.value)
            }
        }).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) {
                    Swal.fire({
                        title: t("Settings are successfully updated."),
                        icon: "success"
                    });
                }
            }
            setButtonLoading(false);
        });
    };

    const fetchData = () => {
        GetMainProcess({
            main_process_id: main_process_id,
            store_id: current_store_id
        }).then(result => {
            if (isMountedRef.current) {
                let settings = result?.data?.settings;

                if (settings && !_.isEmpty(settings)) {
                    _.forEach(settings.messages, message =>
                        append({
                            value: message
                        })
                    );

                    delete settings.messages;
                    _.forEach(_.keys(settings), item => setValue(item, settings[item]));
                }
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        $("#settingsFormModal").on("show.bs.modal", function(e) {
            // set Loading state true
            setLoading(true);
            // reset messages fields
            setValue("messages", []);
            // request fn
            fetchData();
        });
    }, [isMountedRef]);
    return (
        <div
            className="modal fade"
            id="settingsFormModal"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="settingsFormLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="settingsFormLabel">
                            {t("Social Distance Counter Settings")}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-xs btn-icon btn-ghost-dark"
                            data-dismiss="modal"
                            aria-label="Close">
                            <i className="tio-clear tio-lg"></i>
                        </button>
                    </div>
                    <Loading loading={loading} />
                    <form className="modal-body" id="settingsForm" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row g-2">
                            {formFields.map((field, key) => {
                                return (
                                    <div className="col-sm-4" key={key}>
                                        <div className="form-group">
                                            <label htmlFor={field.name} className="form-label">
                                                {t(field.text)}
                                            </label>
                                            {!field.mask ? (
                                                <input
                                                    ref={register(field.validation)}
                                                    type="text"
                                                    className={`form-control ${errors[field.name] ? "is-invalid" : ""}`}
                                                    placeholder={field.placeholder}
                                                    name={field.name}
                                                    id={field.name}
                                                />
                                            ) : (
                                                <MaskedInput
                                                    ref={ref => ref && register(ref.inputElement, field.validation)}
                                                    type="text"
                                                    className={`form-control ${errors[field.name] ? "is-invalid" : ""}`}
                                                    placeholder={field.placeholder}
                                                    name={field.name}
                                                    id={field.name}
                                                    mask={field.mask}
                                                    guide={false}
                                                    keepCharPositions={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <span className="divider mb-2">{t("Messages")}</span>
                        <div className="row g-2">
                            {fields.map((field, index) => {
                                let id = index + 1;

                                return (
                                    <div className="col-sm-4" key={index}>
                                        <div className="form-group">
                                            <label htmlFor={`messages[${index}]`} className="form-label">
                                                {id}. {t("Message")}
                                            </label>
                                            <textarea
                                                ref={register()}
                                                name={`messages[${index}].value`}
                                                id={`messages[${index}].value`}
                                                rows="3"
                                                className="form-control"
                                                placeholder={`${id}. ${t("Message")}`}
                                                defaultValue={field.value}></textarea>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">
                            Close
                        </button>
                        <Default loading={buttonLoading} form="settingsForm">
                            Save
                        </Default>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsForm;
