import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import PageHeader from "../../PageHeader";
import DataLoader from "../../../../components/DataLoader";
import CardCheckbox from "../components/CardCheckbox";
import Default from "../../../Buttons/Default";
import PermissionChecker from "../../../PermissionChecker";
import { useData } from "../DataContext";
import { isUserGroup } from "../../../../utils/information";
import { ListProducts } from "../../../../api/services/products";
import { useIsMountedRef } from "../../../../utils/core";

const Products = () => {
    const { setValues, data } = useData();
    const isMountedRef = useIsMountedRef();
    const [selected, setSelected] = useState(data?.product || 0);
    const [products, setProducts] = useState(null);
    const { push } = useHistory();
    const { t } = useTranslation();

    const onSubmit = () => {
        setValues({ product: selected });
        push("/analysis/select-algorithm");
    };

    const fetchProducts = () => {
        ListProducts().then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) {
                    setProducts(result?.data || []);
                } else setProducts([]);
            }
        });
    };

    useEffect(() => {
        if (!data.camera) push("/analysis/select-camera");
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [isMountedRef]);

    return (
        <PermissionChecker condition={isUserGroup("support")} description="You don't have permission for Analysis.">
            <div className="content container-fluid">
                <PageHeader
                    description={data.name}
                    component={() => (
                        <>
                            <Link to="/analysis/select-camera" className="btn btn-outline-secondary mr-2">
                                {t("Back")}
                            </Link>
                            <Default
                                type="button"
                                color="success"
                                disabled={selected ? false : true}
                                onClick={onSubmit}>
                                {t("Next")}
                            </Default>
                        </>
                    )}>
                    {t("Select Product")}
                </PageHeader>
                <div className="row">
                    <DataLoader data={products}>
                        {products?.map(product => (
                            <div key={product.id} className="col-md-4 col-sm-6 mb-4">
                                <CardCheckbox
                                    id={product.id}
                                    title={product.name}
                                    onChange={setSelected}
                                    selected={selected}
                                    buttonText="Product"
                                    name="products"
                                    description={product.description}
                                    icon={product.icon}
                                />
                            </div>
                        ))}
                    </DataLoader>
                </div>
            </div>
        </PermissionChecker>
    );
};

export default Products;
