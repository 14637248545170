import React from "react";
import { useTranslation } from "react-i18next";

import { version } from "../../../package.json";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="footer footer-bordered flex-column flex-sm-row py-sm-0 py-2">
            <p className="font-size-sm mb-0">
                Copyright &copy; <span>2020 Cameralyze</span>
            </p>
            <div className="d-flex justify-content-end">
                <ul className="list-inline list-separator">
                    <li className="list-inline-item">
                        <span className="badge badge-pill badge-soft-secondary">v{version}</span>
                    </li>
                    <li className="list-inline-item">
                        <a className="list-separator-link" href="mailto:info@cameralyze.com">
                            {t("Contact us")}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
