import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import Mini from "../Cards/Mini";
import { GetStoreAnalysis } from "../../api/services/store-analysis";
import { getStoreKey, getStoreTimezone } from "../../utils/information";
import { useIsMountedRef } from "../../utils/core";

const DATE_FORMAT = "YYYY-MM-DD";
const LAST_90_DAYS = 90;
const YESTERDAY = moment().subtract(1, "days");

const differentLast90DaysAverage = (data, average) => Math.round(((data - average) / average) * 100);

const getCount = (data, day) => data.find(interval => interval.date.indexOf(day) > -1)?.visitor_count;

const filterDataForThisMonth = data =>
    _.filter(data, item =>
        moment(item.date).isBetween(
            moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
            moment().format("YYYY-MM-DD 00:00:00"),
            undefined,
            "[]"
        )
    );

const VisitorCard = ({ subProcesses }) => {
    const [startDate] = useState(moment().subtract(LAST_90_DAYS, "days"));
    const [endDate] = useState(moment());
    const [cardData, setCardData] = useState({});
    const [trendingData, setTrendingData] = useState({});
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const generateCardData = data => {
        let filteredData = filterDataForThisMonth(data?.intervals);
        let dayCount = moment().date();
        let todayCount = getCount(filteredData, endDate.format(DATE_FORMAT));
        let yesterdayCount = getCount(filteredData, YESTERDAY.format(DATE_FORMAT));
        let total = _.sumBy(filteredData, "visitor_count");

        setCardData({
            today: {
                count: todayCount?.readable()
            },
            yesterday: {
                count: yesterdayCount?.readable()
            },
            month: {
                count: total?.readable(),
                average: parseInt(total / dayCount).readable()
            }
        });
    };

    const generateLast90DaysData = data => {
        let todayCount = getCount(data?.intervals, endDate.format(DATE_FORMAT));
        let yesterdayCount = getCount(data?.intervals, YESTERDAY.format(DATE_FORMAT));
        let totalVisitorCount = _.sumBy(data?.intervals, "visitor_count");
        let last90DaysAverage = Math.round(totalVisitorCount / LAST_90_DAYS);
        let todayAverage = differentLast90DaysAverage(todayCount, last90DaysAverage);
        let yesterdayAverage = differentLast90DaysAverage(yesterdayCount, last90DaysAverage);

        setTrendingData({
            today: {
                count: Math.abs(todayAverage),
                icon: todayAverage > 0 ? "tio-trending-up" : "tio-trending-down",
                color: todayAverage > 0 ? "success" : "danger",
                text: `{{count}}% ${todayAverage > 0 ? "More Visitor" : "Less Visitor"}`
            },
            yesterday: {
                count: Math.abs(yesterdayAverage),
                icon: yesterdayAverage > 0 ? "tio-trending-up" : "tio-trending-down",
                color: yesterdayAverage > 0 ? "success" : "danger",
                text: `{{count}}% ${yesterdayAverage > 0 ? "More Visitor" : "Less Visitor"}`
            }
        });
    };

    const fetchData = () => {
        GetStoreAnalysis({
            sid: getStoreKey(),
            interval: "day",
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
            tz: getStoreTimezone(),
            spid: subProcesses,
            rnd: new Date().getTime()
        }).then(result => {
            if (isMountedRef.current) {
                if (result && result.data) {
                    generateCardData(result.data);
                    generateLast90DaysData(result.data);
                }
            }
        });
    };

    useEffect(() => {
        if (subProcesses) fetchData();
    }, [isMountedRef, subProcesses]);

    return (
        <div className="row">
            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                <Mini
                    title="Today"
                    count={cardData.today?.count}
                    trending={() => (
                        <div className="h4 mb-0">
                            <span className={`badge badge-soft-${trendingData.today?.color} mt-2`}>
                                <i className={trendingData.today?.icon}></i>{" "}
                                {t(trendingData.today?.text, { count: trendingData.today?.count || 0 })}
                            </span>
                        </div>
                    )}
                />
            </div>
            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                <Mini
                    title="Yesterday"
                    color="danger"
                    icon="tio-date-range"
                    count={cardData.yesterday?.count}
                    trending={() => (
                        <div className="h4 mb-0">
                            <span className={`badge badge-soft-${trendingData.yesterday?.color} mt-2`}>
                                <i className={trendingData.yesterday?.icon}></i>{" "}
                                {t(trendingData.yesterday?.text, { count: trendingData.yesterday?.count || 0 })}
                            </span>
                        </div>
                    )}
                />
            </div>
            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                <Mini
                    title="This Month"
                    color="warning"
                    icon="tio-calendar-month"
                    count={cardData.month?.count}
                    trending={() => (
                        <div className="h4 mb-0">
                            <span className="badge badge-soft-secondary mt-2">
                                {t("Daily Average {{count}} Visitors", { count: cardData.month?.average })}
                            </span>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default VisitorCard;
