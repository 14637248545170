import axios from "axios";
import Swal from "sweetalert2";

import i18n from "../../utils/i18n";
import "../defaults";
import {
    SUB_PROCESS_LIST,
    SUB_PROCESS_CREATE,
    SUB_PROCESS_UPDATE,
    SUB_PROCESS_CONFIGS_GET,
    SUB_PROCESS_CONFIGS_SET
} from "../constants";
import { captureException } from "../../utils/handlers";

export const ListSubProcesses = params => {
    try {
        return axios
            .post(SUB_PROCESS_LIST, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};

export const CreateSubProcess = params => {
    try {
        return axios
            .post(SUB_PROCESS_CREATE, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};

export const UpdateSubProcess = params => {
    try {
        return axios
            .post(SUB_PROCESS_UPDATE, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};

export const GetSubProcessConfigs = params => {
    try {
        return axios
            .post(SUB_PROCESS_CONFIGS_GET, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};

export const SetSubProcessConfigs = params => {
    try {
        return axios
            .post(SUB_PROCESS_CONFIGS_SET, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};
