import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import { ListQueueOptimizer } from "../../api/services/queue-optimizer";
import PageHeader from "../../components/App/PageHeader";
import WaitingTime from "../../components/QueueOptimizer/Charts/WaitingTime";
import QueueCounter from "../../components/QueueOptimizer/Charts/QueueCounter";
import { useIsMountedRef } from "../../utils/core";
import { captureException } from "../../utils/handlers";
import { getAccountInformation } from "../../utils/information";
import IconPopover from "../../components/Info/IconPopover";
import SingleDate from "../../components/DataFilter/SingleDate";
import ChartsScrollView from "../../components/Info/ChartsScrollView";

const Detail = () => {
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(moment());
    const [data, setData] = useState([]);
    const [scrollView, setScrollView] = useState(true);
    const isMountedRef = useIsMountedRef();
    const { current_store_key } = getAccountInformation();
    const { sub_process_id } = useParams();
    const { goBack, location } = useHistory();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        time: t("Waiting Time"),
        counter: t("Queue Counter"),
        descriptionTime: t("It gives you the average waiting time per hour for the selected line."),
        descriptionCounter: t("It gives you the average and maximum queue length.")
    };

    const fetchData = () => {
        try {
            ListQueueOptimizer({
                sid: current_store_key,
                dt: "waiting",
                startDate: selected.format("YYYY-MM-DD"),
                spid: sub_process_id,
                rnd: new Date().getTime()
            }).then(response => {
                if (isMountedRef.current) {
                    if (response && !_.isEmpty(response)) {
                        setData(_.uniqBy(response, "hour"));
                    } else {
                        setData([]);
                    }

                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    const handleChange = date => {
        date = moment(date);

        if (date.isValid()) setSelected(date);
    };

    const handleSubmit = () => {
        if (selected.isValid()) {
            setLoading(true);
            fetchData();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="content container-fluid">
            <PageHeader backButton backClick={goBack}>
                {location?.state?.name}
            </PageHeader>
            <div className="row">
                <div className="col-12 mb-lg-5 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">
                                {TRANSLATIONS.time}
                                <IconPopover
                                    title={TRANSLATIONS.time}
                                    content={TRANSLATIONS.descriptionTime}
                                    className="ml-1"
                                />
                            </h4>
                            <SingleDate
                                selected={selected.toDate()}
                                maxDate={moment().toDate()}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                                loading={loading}
                            />
                        </div>
                        <ChartsScrollView onChange={event => setScrollView(event.target.checked)} />
                        <div className="card-body pb-2 pl-1">
                            <WaitingTime data={data} loading={loading} scrollView={scrollView} />
                        </div>
                        <div className="card-header border-top">
                            <h4 className="card-header-title">
                                {TRANSLATIONS.counter}
                                <IconPopover
                                    title={TRANSLATIONS.counter}
                                    content={TRANSLATIONS.descriptionCounter}
                                    className="ml-1"
                                />
                            </h4>
                        </div>
                        <div className="card-body pb-1 pl-1">
                            <QueueCounter data={data} loading={loading} scrollView={scrollView} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detail;
