import $ from "jquery";

export default class CMZScrollspy {
    constructor(element, config) {
        this.element = element;
        this.defaults = {
            topLevelComponent: "html, body",
            duration: 450,
            easing: "linear",
            beforeScroll: null,
            afterScroll: null
        };
        this.config = config;
    }

    init() {
        const self = this,
            element = self.element,
            BSData = {
                offset: element.data("offset") ? element.data("offset") : 0,
                target: element.data("target") ? element.data("target") : null,
                method: element.data("method") ? element.data("method") : "auto"
            },
            dataSettings = $(element).attr("data-cmz-scrollspy-options")
                ? JSON.parse($(element).attr("data-cmz-scrollspy-options"))
                : {};

        self.config = Object.assign({}, self.defaults, BSData, dataSettings, self.config);

        self._bindEvents();

        $(element).scrollspy(self.config);
        $(element).attr("data-cmz-scrollspy-invoker", "");
    }

    _bindEvents() {
        const self = this;

        $(self.config.target).on("click", 'a:not([href="#"]):not([href="#0"])', function(e) {
            let $this = this,
                hash,
                offsetTop;

            e.preventDefault();

            let promise = new Promise(resolve => {
                if ($.isFunction(self.config.beforeScroll)) {
                    self.config.beforeScroll(resolve);
                } else {
                    resolve("completed");
                }
            });

            promise.then(completed => {
                if ($this.hash !== "" && $($this.hash).length) {
                    hash = $this.hash;
                    offsetTop =
                        self.config.topLevelComponent === "html, body"
                            ? $(hash).offset().top + 2 - self.config.offset
                            : $(self.config.topLevelComponent).scrollTop() -
                              $(self.config.topLevelComponent).offset().top +
                              ($(hash).offset().top + 2 - self.config.offset);

                    // Smooth scroll
                    $(self.config.topLevelComponent).animate(
                        {
                            scrollTop: offsetTop
                        },
                        {
                            duration: self.config.duration,
                            easing: self.config.easing,
                            complete: function() {
                                /*if (window.history.replaceState) {
                                    window.history.replaceState(null, null, hash);
                                }*/

                                if ($.isFunction(self.config.afterScroll)) {
                                    self.config.afterScroll();
                                }
                            }
                        }
                    );

                    return false;
                }
            });
        });
    }
}
