import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import $ from "jquery";

import Default from "../../Buttons/Default";
import { ListMainProcesses } from "../../../api/services/main-processes";
import { useIsMountedRef } from "../../../utils/core";
import { getAccountInformation } from "../../../utils/information";
import { ListStores } from "../../../api/services/stores";
import { selectCustomStyles } from "../../../utils/react-select";
import { CreateExportReport } from "../../../api/services/export-report";

const CreateModal = ({ onCreated }) => {
    const [startDate, setStartDate] = useState(moment().subtract(30, "days").toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [loading, setLoading] = useState({ stores: true, main_processes: false, create_button: false });
    const [mainProcesses, setMainProcesses] = useState([]);
    const [stores, setStores] = useState([]);
    const { handleSubmit, register, control, watch, setValue, getValues } = useForm();
    const { user_id, current_store_id } = getAccountInformation();
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();

    const DATE_PICKER_DEFAULTS = {
        dateFormat: "dd/MM/yyyy",
        maxDate: moment().toDate(),
        className: "form-control",
        wrapperClassName: "d-block",
        autoComplete: "off",
        disabledKeyboardNavigation: true,
        startDate: startDate,
        endDate: endDate
    };

    const onSubmit = data => {
        setLoading(prevState => ({ ...prevState, create_button: true }));

        CreateExportReport({
            user_id: user_id,
            data: data.stores.map(store => ({
                store_id: store.value,
                main_process_id: _.map(
                    _.filter(data.process, process => process.store_id === store.value),
                    "value"
                )
            })),
            start_date: moment(data.start_date).format("YYYY-MM-DD"),
            end_date: moment(data.end_date).format("YYYY-MM-DD"),
            interval: data.interval
        }).then(result => {
            if (result?.status === 200) {
                $("#createReportModal").modal("hide");

                onCreated();
                setValue("stores", null);
                setValue("process", null);
            }

            setLoading(prevState => ({ ...prevState, create_button: false }));
        });
    };

    useEffect(() => {
        ListStores({
            user_id: user_id,
            store_id: current_store_id
        }).then(result => {
            if (isMountedRef.current) {
                if (result && result.data) {
                    setStores(
                        _.map(result.data, item => {
                            return {
                                value: item.id,
                                label: item.name
                            };
                        })
                    );
                }
            }

            setLoading(prevState => ({ ...prevState, stores: false }));
        });
    }, [isMountedRef]);

    useEffect(() => {
        if (watch("stores")) {
            const stores = getValues("stores");

            setLoading(prevState => ({ ...prevState, main_processes: true }));
            setValue("process", null);

            _.forEach(stores, store => {
                setMainProcesses([]);
                ListMainProcesses({
                    menu_id: 29,
                    store_id: store.value
                }).then(result => {
                    if (isMountedRef.current) {
                        if (result && result.data) {
                            setMainProcesses(prevState => {
                                return _.uniqBy(
                                    [
                                        ...prevState,
                                        {
                                            id: store.value,
                                            label: store.label,
                                            options: _.map(result.data, item => ({
                                                store_id: store.value,
                                                value: item.id,
                                                label: item.name
                                            }))
                                        }
                                    ],
                                    "id"
                                );
                            });
                        }
                    }

                    setLoading(prevState => ({ ...prevState, main_processes: false }));
                });
            });
        }
    }, [watch("stores")]);

    return (
        <div
            className="modal fade"
            id="createReportModal"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="createReportLabel"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="createReportLabel">
                            {t("Create Report")}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-xs btn-icon btn-ghost-dark"
                            data-dismiss="modal"
                            aria-label="Close">
                            <i className="tio-clear tio-lg"></i>
                        </button>
                    </div>
                    <form className="modal-body" id="createReport" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="stores" className="form-label">
                                        {t("Select Store")}
                                    </label>
                                    <Controller
                                        name="stores"
                                        control={control}
                                        as={Select}
                                        rules={{ required: true }}
                                        options={stores}
                                        isMulti
                                        styles={selectCustomStyles}
                                        isLoading={loading.stores}
                                        isDisabled={loading.stores}
                                        placeholder={t("Select...")}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="process" className="form-label">
                                        {t("Process Name")}
                                    </label>
                                    <Controller
                                        name="process"
                                        control={control}
                                        as={Select}
                                        rules={{ required: true }}
                                        options={mainProcesses}
                                        isMulti
                                        styles={selectCustomStyles}
                                        isLoading={loading.main_processes}
                                        isDisabled={loading.main_processes}
                                        placeholder={t("Select...")}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="interval" className="form-label">
                                        {t("Interval")}
                                    </label>
                                    <div className="form-row">
                                        <div className="col-sm mb-2 mb-sm-0">
                                            <div className="form-control">
                                                <div className="custom-control custom-radio custom-radio-reverse">
                                                    <input
                                                        ref={register({ required: true })}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="interval"
                                                        id="intervalDay"
                                                        value={"day"}
                                                        defaultChecked
                                                    />
                                                    <label
                                                        className="custom-control-label media align-items-center"
                                                        htmlFor="intervalDay">
                                                        <i className="tio-event text-muted mr-2"></i>
                                                        <span className="media-body">{t("Day")}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm mb-2 mb-sm-0">
                                            <div className="form-control">
                                                <div className="custom-control custom-radio custom-radio-reverse">
                                                    <input
                                                        ref={register({ required: true })}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="interval"
                                                        id="intervalHour"
                                                        value={"hours"}
                                                    />
                                                    <label
                                                        className="custom-control-label media align-items-center"
                                                        htmlFor="intervalHour">
                                                        <i className="tio-time text-muted mr-2"></i>
                                                        <span className="media-body">{t("Hour")}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-6">
                                <div className="form-group mb-sm-0">
                                    <label htmlFor="start_date" className="form-label">
                                        {t("Start Date")}
                                    </label>
                                    <Controller
                                        control={control}
                                        name="start_date"
                                        defaultValue={startDate}
                                        render={({ value, onChange }) => (
                                            <DatePicker
                                                {...DATE_PICKER_DEFAULTS}
                                                id="start_date"
                                                onChange={value => {
                                                    if (value) {
                                                        value = moment(value).toDate();

                                                        setStartDate(value);
                                                        onChange(value);
                                                    }
                                                }}
                                                selected={value}
                                                selectsStart
                                                maxDate={moment().toDate()}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-0">
                                    <label htmlFor="end_date" className="form-label">
                                        {t("End Date")}
                                    </label>
                                    <Controller
                                        control={control}
                                        name="end_date"
                                        defaultValue={endDate}
                                        render={({ value, onChange }) => (
                                            <DatePicker
                                                {...DATE_PICKER_DEFAULTS}
                                                id="end_date"
                                                onChange={value => {
                                                    if (value) {
                                                        value = moment(value).toDate();

                                                        setEndDate(value);
                                                        onChange(value);
                                                    }
                                                }}
                                                selected={value}
                                                selectsEnd
                                                maxDate={moment().toDate()}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">
                            {t("Close")}
                        </button>
                        <Default loading={loading.create_button} form="createReport">
                            {t("Create")}
                        </Default>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateModal;
