import React from "react";
import colorful from "../assets/images/logo/colorful.svg";
import beta from "../assets/images/logo/beta.svg";
import miniBeta from "../assets/images/logo/mini-beta.svg";
import miniColorful from "../assets/images/logo/mini-colorful.svg";

import { version } from "../../package.json";

/**
 *
 * @param {string} [mini=true] Logo size [true, false]
 * @param {boolean} [gray=false] Apply gray effect on logo [true, false]
 * @param {string} [color=colorful] The color of the text on the logo  [colorful, white, dark]
 * @param {string} [alt=Cameralyze Logo] Text explaining the logo
 */
const Logo = ({ mini = false, gray = false, color = "colorful", alt = "Cameralyze Logo", ...props }) => {
    let logoType = mini
        ? {
              colorful: version.indexOf("beta") > -1 ? miniBeta : miniColorful
          }
        : {
              colorful: version.indexOf("beta") > -1 ? beta : colorful
          };

    return <img src={logoType[color]} alt={alt} {...props} />;
};

export default Logo;
