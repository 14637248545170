import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import PageHeader from "../../PageHeader";
import TimeRange from "../../../DataFilter/TimeRange";
import Default from "../../../Buttons/Default";
import CheckboxButton from "../../../Forms/CheckboxButtonGroup";
import PermissionChecker from "../../../PermissionChecker";
import { useData } from "../DataContext";
import { isUserGroup } from "../../../../utils/information";
import { useIsMountedRef } from "../../../../utils/core";
import { UpdateAnalysis } from "../../../../api/services/analysis";

const Settings = () => {
    const { clearValues, data } = useData();
    const isMountedRef = useIsMountedRef();
    const [rules, setRules] = useState({
        name: data?.name || null,
        start_time: data?.rules?.start_time ? moment(data?.rules?.start_time, "HH:mm") : moment(),
        end_time: data?.rules?.end_time ? moment(data?.rules?.end_time, "HH:mm") : moment().add(1, "hours"),
        working_days: data?.rules?.working_days || []
    });
    const [weekdays, setWeekdays] = useState([]);
    const [errors, setErrors] = useState({ name: false, time: false, working_days: false });
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();
    const { t } = useTranslation();

    const handleStart = date => {
        if (date) setRules(prevState => ({ ...prevState, start_time: moment(date) }));

        setErrors(prevState => ({
            ...prevState,
            time: moment(date) >= rules.end_time
        }));
    };

    const handleEnd = date => {
        if (date) setRules(prevState => ({ ...prevState, end_time: moment(date) }));

        setErrors(prevState => ({
            ...prevState,
            time: rules.start_time >= moment(date)
        }));
    };

    const handleName = value => {
        setRules(prevState => ({ ...prevState, name: value }));
        setErrors(prevState => ({ ...prevState, name: !value }));
    };

    const handleWorkingDays = value => {
        setRules(prevState => {
            let working_days =
                rules.working_days.indexOf(value) === -1
                    ? [...rules.working_days, value]
                    : _.filter(rules.working_days, item => item !== value);

            setErrors(prevState => ({ ...prevState, working_days: !working_days.length }));

            return { ...prevState, working_days };
        });
    };

    const onSubmit = () => {
        const { name, start_time, end_time, working_days } = rules;
        const { sub_process, camera, framer } = data;

        if (sub_process && name && working_days.length && start_time < end_time) {
            let settings = {
                name: name,
                camera_id: camera,
                framer: framer,
                rules: {
                    start_time: moment(start_time).format("HH:mm"),
                    end_time: moment(end_time).format("HH:mm"),
                    working_days: working_days
                }
            };

            setLoading(true);
            UpdateAnalysis({
                sub_process_id: sub_process,
                name: name,
                settings: settings,
                status: 1
            }).then(result => {
                if (isMountedRef.current) {
                    if (result?.status === 200) {
                        clearValues();
                        push("/analysis");
                    }

                    setLoading(false);
                }
            });
        } else {
            setErrors({
                name: !name,
                time: start_time >= end_time,
                working_days: !working_days.length
            });
        }
    };

    useEffect(() => {
        setWeekdays(_.map(moment.weekdays(true), (day, index) => ({ value: day, id: index + 1 })));

        if (!data.camera) push("/analysis/select-camera");
        else if (!data.product) push("/analysis/select-product");
        else if (!data.algorithm) push("/analysis/select-algorithm");
    }, []);

    return (
        <PermissionChecker condition={isUserGroup("support")} description="You don't have permission for Analysis.">
            <div className="content container-fluid">
                <PageHeader
                    description={data.name}
                    component={() => (
                        <>
                            <Link to="/analysis/framer" className="btn btn-outline-secondary mr-2">
                                {t("Back")}
                            </Link>
                            <Default
                                type="button"
                                color="success"
                                onClick={onSubmit}
                                loading={loading}
                                disabled={_.find(_.keys(errors), key => errors[key]) ? true : false}>
                                {t("Next")}
                            </Default>
                        </>
                    )}>
                    {t("Settings")}
                </PageHeader>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="input-label" htmlFor="name">
                                {t("Name")}
                            </label>
                            <input
                                type="text"
                                id="name"
                                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                onChange={event => handleName(event.target.value)}
                                placeholder={data.name}
                                defaultValue={rules.name}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="input-label">{t("Time Range")}</label>
                            <TimeRange
                                size="md"
                                placement="bottom-center"
                                startTime={rules.start_time.toDate()}
                                endTime={rules.end_time.toDate()}
                                handleStart={handleStart}
                                handleEnd={handleEnd}
                                className={errors.time ? "is-invalid" : ""}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="input-label">{t("Working Days")}</label>
                            <CheckboxButton
                                name="working_days"
                                onChange={item => handleWorkingDays(item)}
                                selected={rules.working_days}
                                items={weekdays}
                                error={errors.working_days}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionChecker>
    );
};

export default Settings;
