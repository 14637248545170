import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

import Default from "../../../Buttons/Default";
import Workspace from "../components/Workspace";
import PermissionChecker from "../../../PermissionChecker";
import { useData } from "../DataContext";
import { isUserGroup } from "../../../../utils/information";
import { useIsMountedRef } from "../../../../utils/core";
import { difference } from "../../../../utils/data";
import { SetSubProcessConfigs } from "../../../../api/services/sub-processes";

const Framer = () => {
    const { data } = useData();
    const isMountedRef = useIsMountedRef();
    const [dataset, setDataset] = useState({ coords: {} });
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();
    const { t } = useTranslation();

    const onSubmit = () => {
        setLoading(true);

        SetSubProcessConfigs({
            sub_process_id: data.sub_process,
            configs: difference(dataset.coords, dataset.prevCoords)
        }).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) push("/analysis/settings");
                else setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (!data.camera) push("/analysis/select-camera");
        else if (!data.product) push("/analysis/select-product");
        else if (!data.algorithm) push("/analysis/select-algorithm");
    }, []);

    return (
        <PermissionChecker condition={isUserGroup("support")} description="You don't have permission for Analysis.">
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card border-0 shadow-none cmz-fullscreen">
                            <div className="card-body position-relative">
                                <Workspace onMessage={setDataset} />
                            </div>
                            <div className="card-footer border-top-0 text-right">
                                <Link to="/analysis/select-algorithm" className="btn btn-outline-secondary mr-2">
                                    {t("Back")}
                                </Link>
                                <Default
                                    className="btn btn-success"
                                    loading={loading}
                                    disabled={_.isEmpty(dataset.coords)}
                                    onClick={onSubmit}>
                                    {t("Next")}
                                </Default>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PermissionChecker>
    );
};

export default Framer;
