import axios from "axios";
import Swal from "sweetalert2";
import i18n from "../../utils/i18n";
import "../defaults";
import { STORE_ANALYSIS_API_URL } from "../constants";

export const GetStoreAnalysis = params => {
    try {
        return axios(STORE_ANALYSIS_API_URL, { params })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};
