import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";

import "../../utils/datatables-settings";
import PageHeader from "../../components/App/PageHeader";
import { getStoreTimezone, getUserStores } from "../../utils/information";
import { useIsMountedRef } from "../../utils/core";
import { captureException } from "../../utils/handlers";
import { ListMainProcesses } from "../../api/services/main-processes";
import { GetStoreAnalysis } from "../../api/services/store-analysis";

const DAY_COUNT = 15;
const REQUEST_DURATION = 60000;
const DATE_FORMAT = "YYYY-MM-DD";

const QuickView = () => {
    const START_DATE = moment()
        .subtract(DAY_COUNT, "days")
        .format(DATE_FORMAT);
    const END_DATE = moment().format(DATE_FORMAT);
    const tableEl = useRef();
    const [updatedAt, setUpdatedAt] = useState(moment());
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();
    let table;

    const generateDataTable = () => {
        let columns = [
            {
                title: t("Store"),
                data: "store"
            }
        ];

        for (let i = 0; i <= DAY_COUNT; i++) {
            let date = moment(END_DATE)
                .subtract(i, "days")
                .format("MMM DD");

            columns.push({
                data: date,
                title: date,
                render: function(data) {
                    return i == 0 ? `<span class="legend-indicator bg-success mr-1"></span>${data}` : data;
                }
            });
        }

        table = $(tableEl.current).DataTable({
            responsive: false,
            order: [4, "desc"],
            aLengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, t("All")]
            ],
            columns: columns
        });

        table.on("error.dt", function(e, settings, techNote, message) {
            captureException("An error has been reported by DataTables", message);
        });

        table.on("draw.dt", function() {
            $('[data-toggle="tooltip"]').tooltip();
        });
    };

    const getMainProcesses = (store_name, store_id, store_key) => {
        ListMainProcesses({
            menu_id: 29, // VISITORS MENU ID
            store_id: store_id,
            quick_view: true
        }).then(result => {
            if (isMountedRef.current) {
                if (result && result.data) {
                    let mainProsesses = result.data;

                    _.forEach(mainProsesses, ({ name, id, sub_process }) =>
                        GetStoreAnalysis({
                            sid: store_key,
                            interval: "day",
                            startDate: START_DATE,
                            endDate: END_DATE,
                            tz: getStoreTimezone(),
                            spid: sub_process.map(({ id }) => id).join(","),
                            rnd: new Date().getTime()
                        }).then(response => fillData(store_name, name, id, response.data))
                    );
                }
                setUpdatedAt(moment());
            }
        });
    };

    const fillData = (store_name, main_process_name, main_process_id, data) => {
        let generatedData = {
            name: store_name,
            id: main_process_id,
            store: `${store_name} (${main_process_name})`
        };

        for (let i = 0; i <= DAY_COUNT; i++) {
            generatedData[
                moment(START_DATE)
                    .add(i, "days")
                    .format("MMM DD")
            ] = "—";
        }

        _.forEach(
            data.intervals,
            item => (generatedData[moment(item.date).format("MMM DD")] = item.visitor_count.readable())
        );

        let exists = table
            .rows()
            .indexes()
            .filter(value => main_process_id === table.row(value).data().id)[0];

        if (exists !== undefined) {
            table
                .row(exists)
                .data(generatedData)
                .draw();
        } else {
            table.rows.add([generatedData]).draw();
        }
    };

    useEffect(() => {
        generateDataTable();
        _.forEach(getUserStores(), store => getMainProcesses(store.name, store.store_id, store.store_key));

        const fetchInterval = setInterval(() => {
            _.forEach(getUserStores(), store => getMainProcesses(store.name, store.store_id, store.store_key));
        }, REQUEST_DURATION);

        return () => {
            clearInterval(fetchInterval);
            $(".data-table-wrapper")
                .find("table")
                .DataTable()
                .destroy(true);
        };
    }, [isMountedRef]);

    return (
        <div className="content container-fluid">
            <PageHeader>{t("Quick View")}</PageHeader>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">
                                {t("Last {{day}} Days Visitor Count for Stores", { day: DAY_COUNT })}
                            </h4>
                            <small className="ml-auto text-right">
                                <div className="font-weight-bold">{t("Last Updated Date")}</div>
                                <div className="text-muted">
                                    {updatedAt.format("LL HH:mm:ss")} ({updatedAt.fromNow()})
                                </div>
                            </small>
                        </div>
                        <div className="datatable-custom table-responsive">
                            <table
                                ref={tableEl}
                                className="table table-bordered table-nowrap table-align-middle card-table w-100">
                                <thead className="thead-light"></thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickView;
