import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import PageHeader from "../../App/PageHeader";

const Detail = () => {
    const { push, goBack, location } = useHistory();
    const { t } = useTranslation();
    let data = location?.state?.data;
    console.log(location);

    useEffect(() => {
        if (!data?.id) push("/cart-thief-protection");
    }, []);

    return (
        <div className="content container-fluid">
            <PageHeader backButton backClick={goBack}>
                {t("Alarm Detail")}
            </PageHeader>
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">Alarm Detail</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group mb-md-0">
                                        <label htmlFor="test" className="input-label">
                                            {t("Store Name")}
                                        </label>
                                        <div>{data?.store_name}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group mb-md-0">
                                        <label htmlFor="test" className="input-label">
                                            {t("Camera Name")}
                                        </label>
                                        <div>{data?.camera_name}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group mb-md-0">
                                        <label htmlFor="test" className="input-label">
                                            {t("Alarm Date")}
                                        </label>
                                        <div>{data?.created_at}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">{t("Alarm Image")}</h4>
                        </div>
                        <div className="card-body">
                            <img
                                alt={data?.camera_name}
                                src={data?.image}
                                className="img-fluid rounded overflow-hidden d-flex h-100 w-100"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detail;
