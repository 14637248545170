import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

/**
 *
 * @param {integer} [selected=0] Select the default option, matches option ids
 * @param {function} onChange Returns a date range when options change
 * @param {array} options Select menu options
 * @param {boolean} [loading=false] [true/false]
 * @param {string} [format=YYYY-MM-DD] Returns a date range when options change
 */
const CustomSelect = ({ selected = 0, onChange, options, loading = false, format = "YYYY-MM-DD" }) => {
    const [current, setCurrent] = useState(selected);
    const { t } = useTranslation();

    useEffect(() => {
        onChange(_.map(_.find(options, option => option.id === current)?.interval, item => item.format(format)));
    }, []);

    return (
        <div className="btn-group mt-2 mt-sm-0">
            <button
                className={`btn btn-secondary dropdown-toggle ${loading ? "disabled" : ""}`}
                type="button"
                id="yearDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={loading}>
                {loading ? (
                    <span>
                        <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                    </span>
                ) : (
                    t(options.find(item => item.id === current)?.name)
                )}
            </button>
            <div className="dropdown-menu" aria-labelledby="yearDropdown">
                {_.map(options, item => (
                    <button
                        type="button"
                        className={`dropdown-item ${item.id === current ? "active" : ""}`}
                        onClick={() => {
                            if (current !== item.id) {
                                setCurrent(item.id);
                                onChange(_.map(item.interval, item => item.format(format)));
                            }
                        }}
                        key={item.id}>
                        {t(item.name)}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default CustomSelect;
