import _ from "lodash";

export const filterByWorkHours = (open_hour, close_hour, key) => data => {
    let hour = parseInt(data[key]);
    let start = parseInt(open_hour);
    let end = parseInt(close_hour);

    return _.inRange(hour, start, end + 1);
};

/**
 * Deep diff between two object, using lodash
 * @param  {object} object Object compared
 * @param  {object} base   Object to compare with
 * @return {object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
    const changes = (object, base) => {
        return _.transform(object, (result, value, key) => {
            if (!_.isEqual(value, base[key])) {
                result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    };

    return changes(object, base);
};
