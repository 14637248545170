import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import SingleDate from "../../DataFilter/SingleDate";
import DataNotFound from "../../State/DataNotFound";
import { getAccountInformation, getStoreKey, getStoreTimezone } from "../../../utils/information";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";
import { GetDailyCapacityRatio } from "../../../api/services/social-distance-counter";
import { GetMainProcess } from "../../../api/services/main-processes";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const chartConfigs = (data, translations, scrollView) => ({
    type: "scrollcolumn2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(scrollView),
            ...fusionChartExportDefaults(translations.chart),
            numberSuffix: "%",
            showPercentValues: "1",
            paletteColors: "#00c9a7",
            labelDisplay: "rotate",
            rotateValues: 1,
            xAxisname: translations.hour,
            yAxisname: translations.ratio
        },
        // Chart Data
        ...data
    }
});

const DailyCapacityRatio = () => {
    const [selected, setSelected] = useState(moment().subtract(1, "days"));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { current_store_id } = getAccountInformation();
    const { main_process_id } = useParams();
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Daily Capacity Ratio"),
        hour: t("Hour"),
        ratio: t("Visitors Ratio"),
        instant_count: t("Instant Count"),
        fullness: t("Fullness Ratio")
    };

    const handleChange = date => {
        date = moment(date);

        if (date.isValid()) setSelected(date);
    };

    const handleSubmit = () => {
        if (selected.isValid()) {
            setLoading(true);
            fetchData();
        }
    };

    const fetchData = () => {
        try {
            setLoading(true);

            GetMainProcess({
                main_process_id: main_process_id,
                store_id: current_store_id
            }).then(result => {
                if (isMountedRef.current) {
                    let settings = result?.data?.settings;
                    let capacity = settings?.capacity || settings?.threshold;

                    if (capacity) {
                        GetDailyCapacityRatio({
                            sid: getStoreKey(),
                            mpid: main_process_id,
                            date: selected.format("YYYY-MM-DD"),
                            capacity: parseInt(capacity),
                            timezone: getStoreTimezone(),
                            rnd: new Date().getTime()
                        }).then(response => {
                            if (isMountedRef.current) {
                                if (!_.isEmpty(response?.data)) {
                                    let generatedData = _.orderBy(
                                        response.data.map(function (item) {
                                            let hour = moment(item.hour, "HH").format("HH:00");
                                            let ratio = item.ratio.toFixed(2);

                                            return {
                                                label: hour,
                                                value: ratio,
                                                tooltext: `
                                                <div style="font-size: 110%">
                                                    ${hour} — ${TRANSLATIONS.ratio}
                                                </div><br/>
                                                <b>${TRANSLATIONS.fullness}:</b> ${ratio}%<br/>
                                                <b>${TRANSLATIONS.instant_count}:</b> ${item.count}<br/>
                                            `
                                            };
                                        }),
                                        ["label"]
                                    );

                                    setData({
                                        categories: [
                                            {
                                                category: _.map(generatedData, ({ label }) => ({ label }))
                                            }
                                        ],
                                        dataset: [
                                            {
                                                data: _.map(generatedData, ({ value, tooltext }) => ({
                                                    value,
                                                    tooltext
                                                }))
                                            }
                                        ]
                                    });
                                } else setData(null);
                                setLoading(false);
                            }
                        });
                    }
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (main_process_id) fetchData();
    }, [isMountedRef, main_process_id]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <SingleDate
                    selected={selected.toDate()}
                    maxDate={moment().toDate()}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    loading={loading}
                />
            </div>
            <div className="card-body pb-1 pl-1">
                {data ? <ReactFC {...chartConfigs(data, TRANSLATIONS)} /> : <DataNotFound className="pb-3 pl-3" />}
            </div>
        </div>
    );
};

export default DailyCapacityRatio;
