import axios from "axios";
import Swal from "sweetalert2";

import i18n from "../../utils/i18n";
import "../defaults";
import { REAL_TIME_ANALYTICS_API_URL, REAL_TIME_ANALYTICS_LOGS } from "../constants";

export const GetRealTimeAnalytics = (params, alert = true) => {
    try {
        return axios(REAL_TIME_ANALYTICS_API_URL, { params })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (alert) {
                    Swal.fire({
                        title: "Oops!",
                        text: i18n.t("Sorry, your request failed. Please try again later."),
                        icon: "error"
                    });
                } else {
                    return undefined;
                }
            });
    } catch (error) {}
};

export const GetRealTimeAnalyticsLogs = params => {
    try {
        return axios
            .post(REAL_TIME_ANALYTICS_LOGS, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const GetDailyCapacityRatio = (params, alert = true) => {
    try {
        return axios(REAL_TIME_ANALYTICS_API_URL + "/daily-counts", { params })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (alert) {
                    Swal.fire({
                        title: "Oops!",
                        text: i18n.t("Sorry, your request failed. Please try again later."),
                        icon: "error"
                    });
                } else {
                    return undefined;
                }
            });
    } catch (error) {}
};
