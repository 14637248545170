import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import "../../utils/core";

import { getAccountInformation, isUserGroup } from "../../utils/information";
import { ListSubProcesses } from "../../api/services/sub-processes";
import { useIsMountedRef } from "../../utils/core";
import PageHeader from "../../components/App/PageHeader";
import VisitorsDensity from "../../components/StoreAnalysis/Charts/VisitorsDensity";
import VisitorCard from "../../components/StoreAnalysis/VisitorCard";
import DailyPerformance from "../../components/StoreAnalysis/Charts/DailyPerformance";
import MostIntenseDay from "../../components/StoreAnalysis/Charts/MostIntenseDay";
import MostIntenseHours from "../../components/StoreAnalysis/Charts/MostIntenseHours";
import WeeklyVisitors from "../../components/StoreAnalysis/Charts/WeeklyVisitors";
import VisitorDistributions from "../../components/StoreAnalysis/Charts/VisitorDistributions";
import TotalDailyVisitor from "../../components/StoreAnalysis/Charts/TotalDailyVisitor";
import DailyMaximumDensity from "../../components/StoreAnalysis/Charts/DailyMaximumDensity";
import DailyCapacityRatio from "../../components/StoreAnalysis/Charts/DailyCapacityRatio";
import RequestProvider from "../../components/RequestProvider";

const StoreAnalysis = () => {
    const [subProcesses, setSubProcesses] = useState("");
    const { main_process_id } = useParams();
    const { current_store_id } = getAccountInformation();
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    useEffect(() => {
        ListSubProcesses({
            store_id: current_store_id,
            main_process_id: main_process_id
        }).then(result => {
            if (isMountedRef.current) {
                if (result?.data) {
                    setSubProcesses(_.map(result.data, "id").join(","));
                }
            }
        });
    }, [isMountedRef, main_process_id]);

    return (
        <div className="content container-fluid position-relative">
            <RequestProvider>
                <PageHeader>{t("Store Analysis")}</PageHeader>
                <VisitorCard subProcesses={subProcesses} />
                <div className="row">
                    <div className="col-12 mb-lg-5 mb-3">
                        <TotalDailyVisitor subProcesses={subProcesses} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-lg-5 mb-3">
                        <WeeklyVisitors subProcesses={subProcesses} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-12 mb-lg-5 mb-3">
                        <MostIntenseDay subProcesses={subProcesses} />
                    </div>
                    <div className="col-sm-6 col-12 mb-lg-5 mb-3">
                        <DailyPerformance subProcesses={subProcesses} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-lg-5 mb-3">
                        <MostIntenseHours subProcesses={subProcesses} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-lg-5 mb-3">
                        <DailyMaximumDensity />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-lg-5 mb-3">
                        <VisitorsDensity subProcesses={subProcesses} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-12 mb-lg-5 mb-3">
                        <VisitorDistributions subProcesses={subProcesses} />
                    </div>
                    {([18, 46, 47].indexOf(current_store_id) > -1 || isUserGroup("support")) && (
                        <div className="col-md-6 col-12 mb-lg-5 mb-3">
                            <DailyCapacityRatio subProcesses={subProcesses} />
                        </div>
                    )}
                </div>
            </RequestProvider>
        </div>
    );
};

export default StoreAnalysis;
