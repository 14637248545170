import React, { useState } from "react";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

import { selectCustomStyles } from "../../../utils/react-select";
import { getUserStores, getAccountInformation, getMenus } from "../../../utils/information";
import { ChangeUserStores } from "../../../api/services/stores";
import { useIsMountedRef } from "../../../utils/core";
import { SessionStart } from "../../../api/services/sessions";
import { useTranslation } from "react-i18next";

const customStyles = `
    @media (max-width: 767.98px) {
        .navbar-fixed ~ .main {
            padding-top: 6.75rem;
        }
    }
`;

const SelectStore = () => {
    const { t } = useTranslation();
    const options = [
        ..._.map(getUserStores(), store => ({
            value: store.store_id,
            label: store.name
        })),
        { value: "all", label: t("View All") }
    ];
    const { current_store_id, user_id } = getAccountInformation();
    const { pathname } = useLocation();
    const [selectedOption, setSelectedOption] = useState(_.find(options, option => option.value === current_store_id));
    const [loading, setLoading] = useState(false);
    const isMountedRef = useIsMountedRef();
    const history = useHistory();

    const changeStore = store => {
        let currentPath = pathname.split("/")[1];
        setLoading(true);

        ChangeUserStores({
            user_id: user_id,
            store_id: store.value
        }).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) {
                    setSelectedOption(store);

                    SessionStart().then(result => {
                        if (isMountedRef.current) {
                            if (result?.data) {
                                localStorage.setItem("CMZ:L", JSON.stringify({ ...result.data }));

                                if (!_.isEmpty(getMenus())) {
                                    let redirect = _.find(getMenus(), menu => menu.to.indexOf(currentPath) > -1);

                                    if (redirect) {
                                        if (_.isEmpty(redirect.submenus)) {
                                            window.location.href = redirect.to;
                                        } else {
                                            window.location.href = redirect.submenus[0].to;
                                        }
                                    } else {
                                        window.location.reload();
                                    }
                                } else {
                                    window.location.href = "/";
                                }
                            }
                        }

                        setLoading(false);
                    });
                }

                setLoading(false);
            }
        });
    };

    return (
        <div className="w-100 w-md-auto">
            <style>{customStyles}</style>
            <Select
                autosize={true}
                value={selectedOption}
                styles={selectCustomStyles}
                isDisabled={loading}
                isLoading={loading}
                onChange={option => {
                    if (option.value === "all") history.push("/stores");
                    else if (option.value !== selectedOption.value) changeStore(option);
                }}
                options={options}
            />
        </div>
    );
};

export default SelectStore;
