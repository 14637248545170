import React from "react";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import _ from "lodash";

import appRoutes from "../routes/appRoutes";
import Menu from "../components/App/Menu";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Title from "../components/Title";
import SessionProvider from "../components/SessionProvider";
import { redirectMainMenu, redirectCorrectMenu } from "../utils/redirect";
import { getMenus } from "../utils/information";

const App = () => {
    const { pathname } = useLocation();

    return (
        <SessionProvider>
            <div
                id="app-body"
                className="footer-offset footer-offset has-navbar-vertical-aside navbar-vertical-aside-show-xl">
                <Navbar />
                <Menu />
                <main id="content" role="main" className="main pointer-event">
                    <Switch>
                        <Redirect exact from="/" to={redirectMainMenu()} />
                        <Redirect exact from="/account" to={redirectMainMenu()} />
                        {appRoutes(getMenus()).map((route, key) => {
                            if (route.layout === "/" || route.layout === "/account") {
                                return (
                                    <Route
                                        {...route.prop}
                                        key={key}
                                        path={route.layout + route.path}
                                        render={props => {
                                            let hasMainProcess = _.has(props.match.params, "main_process_id");
                                            let mainProcessId = props.match.params.main_process_id;

                                            if (hasMainProcess && !mainProcessId) {
                                                return <Redirect to={redirectCorrectMenu(route.path, false)} />;
                                            } else if (
                                                hasMainProcess &&
                                                mainProcessId &&
                                                redirectCorrectMenu(pathname, true)
                                            ) {
                                                return <Redirect to={redirectCorrectMenu(pathname, true)} />;
                                            } else {
                                                return (
                                                    <>
                                                        <Title title={route.title} />
                                                        <route.component {...props} />
                                                    </>
                                                );
                                            }
                                        }}
                                    />
                                );
                            } else return <Redirect key="404" exact to="/404" />;
                        })}
                    </Switch>
                    <Footer />
                </main>
            </div>
        </SessionProvider>
    );
};

export default App;
