import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import Core from "./layouts/Core";

import "./utils/i18n";
import "./assets/js/theme";
import "./assets/vendor/icon-set/style.css"; //tio icon set
import "./assets/css/theme.css"; // general CSS file
import "./assets/css/datatables.responsive.css";
import { getLanguage } from "./utils/i18n";

// HMTL lang set browser language
document.documentElement.lang = getLanguage();

const Loading = () => {
    return (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 right-0 left-0 bottom-0">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://4c4fb0102d4b4fdea3b14d1fc903522e@o271128.ingest.sentry.io/5592892"
    });
}

const rootElement = document.getElementById("root");

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Loading />}>
            <Core />
        </Suspense>
    </React.StrictMode>,
    rootElement
);
