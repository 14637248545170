import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";

import CMZFullscreen from "../../assets/vendor/fullscreen/fullscreen";

const Fullscreen = ({ cardId }) => {
    const { t } = useTranslation();

    useEffect(() => {
        $(".js-fullscreen-invoker").each(function () {
            if ($(this).attr("data-cmz-fullscreen-invoker") === undefined) {
                new CMZFullscreen($(this)).init();
            }
        });
    });

    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip();

        return () => $('[data-toggle="tooltip"]').tooltip("dispose");
    }, []);

    return (
        <button
            type="button"
            className="js-fullscreen-invoker btn btn-sm btn-icon btn-soft-secondary"
            data-cmz-fullscreen-options={`{"targetEl": "${cardId}"}`}>
            <i
                onClick={() => $('[data-toggle="tooltip"]').tooltip("hide")}
                className="tio-fullscreen-1-1 cmz-fullscreen-icon-default"
                data-toggle="tooltip"
                data-placement="left"
                title={t("Fullscreen")}></i>
            <i
                onClick={() => $('[data-toggle="tooltip"]').tooltip("hide")}
                className="tio-exit-fullscreen-1-1 cmz-fullscreen-icon-active"
                data-toggle="tooltip"
                data-placement="left"
                title={t("Exit fullscreen")}></i>
        </button>
    );
};

export default Fullscreen;
