import React from "react";
import { useTranslation } from "react-i18next";

const customStyle = `
    body {
        overflow: hidden;
    }
`;

const SuspenseLoader = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex justify-content-center align-items-center position-fixed top-0 right-0 left-0 bottom-0 z-index-9999 bg-white">
            <style>{customStyle}</style>
            <div className="spinner-border" role="status">
                <span className="sr-only">{t("Loading")}...</span>
            </div>
        </div>
    );
};

export default SuspenseLoader;
