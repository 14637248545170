import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../components/App/PageHeader";
import FilterSidebar from "../../components/App/Heatmap/FilterSidebar";
import Details from "../../components/App/Heatmap/Details";

const Heatmap = () => {
    const [detail, setDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [data, setData] = useState({});
    const { t } = useTranslation();

    return (
        <div className="content container-fluid">
            <PageHeader>{t("Heatmaps")}</PageHeader>
            <div className="row">
                <div className="col-12 mb-4">
                    <FilterSidebar
                        onSelect={setData}
                        onLoading={setLoading}
                        onLoaded={data => {
                            setDetail(data);
                            setFirstTime(false);
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-8 mb-md-0">
                    <div className="card overflow-hidden">
                        <div className="card-header">
                            <h4 className="card-header-title">{t("Preview")}</h4>
                        </div>
                        <div className="card-body bg-light text-center">
                            {!firstTime && loading ? (
                                <div className="spinner-border m-auto" role="status">
                                    <span className="sr-only">{t("Loading")}...</span>
                                </div>
                            ) : detail?.url ? (
                                <img className="img-fluid shadow-sm rounded" src={detail.url} alt={detail.process} />
                            ) : (
                                <div className="text-muted font-italic">
                                    {t("There is no heatmap image at selected date. Please try again later.")}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-4 mt-4 mt-lg-0">
                    <Details subProcess={data.sub_process_id} date={data.date} hour={data.hour} />
                </div>
            </div>
        </div>
    );
};

export default Heatmap;
