import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import moment from "moment";

import chat from "../../assets/images/illustrations/chat.svg";
import kolektifhouse from "../../assets/images/brands/kolektifhouse.png";
import metromarket from "../../assets/images/brands/metromarket.png";
import perlavista from "../../assets/images/brands/perlavista.png";
import Default from "../../components/Buttons/Default";
import Text from "../../components/Forms/Text";
import Password from "../../components/Forms/Password";
import Logo from "../../components/Logo";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { Login as LoginRequest } from "../../api/services/login";

const customStyle = `
    html,
    body {
        height: 100%;
    }

    body {
        display: flex;
        align-items: center;
        min-height: 100%;
    }

    body > div {
        flex-grow: 1;
    }
`;

const BRANDS = [
    {
        name: "Kolektif House",
        src: kolektifhouse
    },
    {
        name: "Perlavista AVM",
        src: perlavista
    },
    {
        name: "Metro Market",
        src: metromarket
    }
];

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true
});

const Login = () => {
    const { register, handleSubmit, errors, getValues, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const onSubmit = () => {
        const loginData = getValues(["email", "password"]);

        setLoading(true);

        LoginRequest(loginData).then(result => {
            const status = result.status;

            if (status === 200) {
                let data = result.data;
                let expired = moment()
                    .add(23, "hours")
                    .format("YYYY-MM-DD HH:mm:ss");

                localStorage.setItem("CMZ:email", loginData.email);
                localStorage.setItem("CMZ:R", data.refresh_token);
                localStorage.setItem(
                    "CMZ:T",
                    JSON.stringify({
                        token: data.access_token,
                        expired: expired
                    })
                );

                Toast.fire({
                    icon: "success",
                    title: t("Signed in successfully")
                });

                setTimeout(() => window.location.reload(), 1000);
            } else {
                Swal.fire({
                    title: `${t("Error code")}: ${status}`,
                    text: t(result.message),
                    icon: "error"
                });
            }

            setLoading(false);
        });
    };

    useEffect(() => {
        const rememberMeEmail = localStorage.getItem("CMZ:email") || "";

        setValue("email", rememberMeEmail, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, []);

    return (
        <main id="content" role="main" className="main pt-0">
            <style>{customStyle}</style>
            <div className="container-fluid px-3">
                <div className="row">
                    <div className="position-absolute top-0 left-0 right-0 mt-3 mx-3 z-index-2">
                        <LanguageSwitcher />
                    </div>
                    <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0">
                        <div style={{ maxWidth: "23rem" }}>
                            <div className="text-center mb-5">
                                <img className="img-fluid" src={chat} alt="Features Illustrator" />
                            </div>
                            <div>
                                <h2 className="display-4 mb-0 text-center">Cameralyze</h2>
                            </div>
                            <p className="text-muted text-center my-4">
                                {t(
                                    "Start to use ready-to-use products without needing any technical knowledge and hardware investment"
                                )}
                                .
                            </p>
                            <div className="row align-items-center justify-content-between gx-3">
                                {BRANDS.map((brand, key) => (
                                    <div className="col" key={key}>
                                        <img className="colorless-image img-fluid" src={brand.src} alt={brand.name} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                        <div className="w-100 pt-10 pt-lg-7 pb-7" style={{ maxWidth: "25rem" }}>
                            <form className="js-validate" onSubmit={handleSubmit(onSubmit)}>
                                <Link className="d-flex justify-content-center mb-5" to="/">
                                    <Logo className="w-85" />
                                </Link>
                                <div className="text-center mb-5">
                                    <h1 className="display-4">{t("Sign in")}</h1>
                                </div>
                                <div className="js-form-message form-group">
                                    <label className="input-label" htmlFor="signInEmail">
                                        {t("Email")}
                                    </label>
                                    <Text
                                        tabIndex="1"
                                        ref={register({
                                            required: true,
                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })}
                                        name="email"
                                        id="signInEmail"
                                        size="lg"
                                        placeholder="johndoe@example.com"
                                        autoFocus={true}
                                        className={errors.email ? "is-invalid" : ""}
                                    />
                                </div>
                                <div className="js-form-message form-group">
                                    <label className="input-label" htmlFor="signInPassword">
                                        {t("Password")}
                                    </label>
                                    <Password
                                        tabIndex="2"
                                        ref={register({ required: true })}
                                        size="lg"
                                        name="password"
                                        id="signInPassword"
                                        placeholder={t("Enter your password")}
                                        toggle={true}
                                        className={errors.password ? "is-invalid" : ""}
                                    />
                                </div>
                                <Default tabIndex="4" className="btn-block btn-lg" loading={loading}>
                                    {t("Sign in")}
                                </Default>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Login;
