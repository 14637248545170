import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import _ from "lodash";

import PageHeader from "../../components/App/PageHeader";
import DateRange from "../../components/DataFilter/DateRange";
import { API_URL, SHOPPING_CART_THIEF_PROTECTION_LIST } from "../../api/constants";
import { formatDate, getAccessToken, getUserStores } from "../../utils/information";
import { captureException } from "../../utils/handlers";

$.DataTable = require("datatables.net-responsive");

const ShoppingCartThiefProtection = () => {
    const tableEl = useRef();
    const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
    const [endDate, setEndDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const { t } = useTranslation();
    const { push } = useHistory();
    const REQUEST_DURATION = 1000 * 10; // ms * sec

    const handleStart = date => setStartDate(moment(date));
    const handleEnd = date => setEndDate(moment(date));
    const handleSubmit = () => {
        $(tableEl.current).DataTable().destroy();
        loadTable(startDate, endDate);
    };

    const loadTable = (startDate, endDate) => {
        const table = $(tableEl.current).DataTable({
            order: [0, "desc"],
            aLengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, t("All")]
            ],
            processing: true,
            ajax: {
                url: API_URL + SHOPPING_CART_THIEF_PROTECTION_LIST,
                type: "GET",
                datatype: "json",
                contentType: "application/json",
                beforeSend: function (request) {
                    request.setRequestHeader("Authorization", getAccessToken());
                },
                data: {
                    sid: _.map(getUserStores(), "store_key").join(","),
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate)
                },
                dataSrc: function (d) {
                    if (d.status !== 200) {
                        return [];
                    } else {
                        return _.orderBy(d.data, ["id", "desc"]);
                    }
                }
            },
            columnDefs: [
                ...$.fn.dataTable.defaults.columnDefs,
                {
                    targets: "detail",
                    createdCell: (td, cellData, row) => {
                        ReactDOM.render(
                            <button
                                onClick={() =>
                                    push({
                                        pathname: "/cart-thief-protection/detail",
                                        state: {
                                            data: row
                                        }
                                    })
                                }
                                className="btn btn-white"
                                to="/cart-thief-protection/detail">
                                <i className="fa fa-eye mr-0"></i>
                            </button>,
                            td
                        );
                    }
                }
            ],
            columns: [
                {
                    data: "id",
                    responsivePriority: 3,
                    render: function (data, type, row, meta) {
                        return row.is_read
                            ? meta.row + 1
                            : `<span class="legend-indicator bg-danger"></span> ${meta.row + 1}`;
                    }
                },
                {
                    data: "store_name",
                    responsivePriority: 0
                },
                {
                    data: "camera_name",
                    responsivePriority: 2
                },
                {
                    data: "created_at",
                    responsivePriority: 3,
                    render: function (data) {
                        return formatDate(data, "DD MMM YYYY HH:mm:ss");
                    }
                },
                {
                    data: null,
                    responsivePriority: 1
                }
            ]
        });

        table.on("error.dt", function (e, settings, techNote, message) {
            captureException("An error has been reported by DataTables", message);
        });

        table.on("draw.dt", function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    };

    useEffect(() => {
        loadTable(startDate, endDate);

        let dataTableInverval = setInterval(() => {
            $(tableEl.current).DataTable().ajax.reload();
        }, REQUEST_DURATION);

        return () => {
            $(".data-table-wrapper").find("table").DataTable().destroy(true);
            clearInterval(dataTableInverval);
        };
    }, []);

    return (
        <div className="content container-fluid">
            <PageHeader>{t("Shopping Cart Thief Protection")}</PageHeader>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">{t("Shopping Cart Thief Protection")}</h4>
                            <DateRange
                                startDate={startDate.toDate()}
                                endDate={endDate.toDate()}
                                handleStart={handleStart}
                                handleEnd={handleEnd}
                                handleSubmit={handleSubmit}
                                loading={loading}
                                className={errors}
                            />
                        </div>
                        <div className="datatable-custom">
                            <table
                                ref={tableEl}
                                className="table table-bordered table-nowrap table-align-middle card-table w-100">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="id">#</th>
                                        <th className="no-sort">{t("Store")}</th>
                                        <th className="no-sort">{t("Camera")}</th>
                                        <th>{t("Alarm Date")}</th>
                                        <th className="detail no-sort">{t("Detail")}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartThiefProtection;
