import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";

import { selectCustomStyles } from "../../../utils/react-select";
import { useIsMountedRef } from "../../../utils/core";
import { ListHeatmapProcesses, GetHeatmapImage } from "../../../api/services/heatmaps";
import { getAccountInformation, getStoreTimezone } from "../../../utils/information";
import Default from "../../Buttons/Default";

const FilterSidebar = ({ onLoaded, onLoading, onSelect }) => {
    const refDatepicker = useRef();
    const refButton = useRef(null);
    const { handleSubmit, control, setValue } = useForm();
    const [processes, setProcesses] = useState([]);
    const [date, setDate] = useState(moment().toDate());
    const [hour, setHour] = useState(moment().subtract(1, "hours").startOf("hours").toDate());
    const [loading, setLoading] = useState({ process: true, button: false });
    const [isAllDay, setIsAllDay] = useState(false);
    const timezone = getStoreTimezone();
    const { current_store_id } = getAccountInformation();
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const isToday = value => moment(value || date).isSame(moment(), "day");
    const maxTime = () => (isToday() ? moment().startOf("hours") : moment().endOf("days")).toDate();
    const getDate = () => (moment(hour).format("H") === "0" ? moment().subtract(1, "days").toDate() : date);

    const calculateTime = (hour, date) => {
        if (hour !== null) {
            let fulltime = moment(`${date} ${hour}`).subtract(timezone, "hours");

            hour = parseInt(fulltime.format("H"));
            date = fulltime.format();
        }

        return { hour, date };
    };

    const onSubmit = data => {
        handleLoading("button", true);
        onLoading(true);

        let hour = isAllDay ? null : moment(data.hour).format("HH:00");
        let date = moment(data.date).format();
        let time = calculateTime(hour, date);
        let requestData = {
            sub_process_id: data.process.value,
            date: time.date,
            hour: time.hour
        };

        onSelect(requestData);
        GetHeatmapImage(requestData).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) onLoaded({ url: result.url, process: data.process.label });
                else onLoaded({});

                handleLoading("button", false);
                onLoading(false);
            }
        });
    };

    const handleLoading = (key, state) => setLoading(prevState => ({ ...prevState, [key]: state }));

    const handleAllTime = () => {
        setIsAllDay(true);
        refDatepicker.current.setOpen(false);
    };

    const listProcesses = () => {
        ListHeatmapProcesses({ sk: current_store_id }).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) {
                    let data = _.map(result.data, item => ({ value: item.id, label: item.name }));

                    setProcesses(data);
                    setValue("process", _.first(data));
                    refButton.current.click();
                }

                handleLoading("process", false);
            }
        });
    };

    useEffect(() => {
        listProcesses();
    }, [isMountedRef]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{t("Filter")}</h4>
            </div>
            <form className="card-body" id="filterForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="row gx-2">
                    <div className="col-lg-4 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="process" className="form-label">
                                {t("Process Name")}
                            </label>
                            <Controller
                                name="process"
                                as={Select}
                                options={processes}
                                styles={selectCustomStyles}
                                control={control}
                                isLoading={loading.processes}
                                isDisabled={loading.processes}
                                rules={{ required: true }}
                                placeholder={t("Select...")}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="form-group">
                            <label htmlFor="date" className="form-label">
                                {t("Date")}
                            </label>
                            <Controller
                                control={control}
                                name="date"
                                defaultValue={getDate()}
                                render={({ value, onChange }) => (
                                    <div className="input-group input-group-merge">
                                        <DatePicker
                                            id="date"
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            wrapperClassName="d-block"
                                            autoComplete="off"
                                            disabledKeyboardNavigation
                                            showPopperArrow={false}
                                            selected={value}
                                            maxDate={moment().toDate()}
                                            onChange={value => {
                                                if (value) {
                                                    value = moment(value).toDate();

                                                    setDate(value);
                                                    onChange(value);

                                                    if (isToday(value)) {
                                                        let hour = moment()
                                                            .subtract(1, "hours")
                                                            .startOf("hours")
                                                            .toDate();

                                                        setValue("hour", hour);
                                                        setHour(hour);
                                                    }
                                                }
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="date">
                                                <i className="tio-calendar-month"></i>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="form-group">
                            <label htmlFor="hour" className="form-label">
                                {t("Hour")}
                            </label>
                            <Controller
                                control={control}
                                name="hour"
                                defaultValue={hour}
                                render={({ value, onChange }) => (
                                    <div className="input-group input-group-merge">
                                        <DatePicker
                                            id="hour"
                                            dateFormat="HH:mm"
                                            popperClassName="all-time"
                                            className="form-control"
                                            autoComplete="off"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            disabledKeyboardNavigation
                                            showPopperArrow={false}
                                            minTime={moment().startOf("days").toDate()}
                                            maxTime={maxTime()}
                                            ref={refDatepicker}
                                            timeIntervals={60}
                                            selected={value}
                                            timeCaption={t("Hour")}
                                            value={isAllDay ? t("All Day") : null}
                                            onChangeRaw={e => e.preventDefault()}
                                            onChange={value => {
                                                if (value) {
                                                    setIsAllDay(false);
                                                    value = moment(value).toDate();

                                                    setHour(value);
                                                    onChange(value);
                                                }
                                            }}>
                                            <button
                                                onClick={handleAllTime}
                                                type="button"
                                                className="btn btn-white btn-block rounded-top-0 rounded-bottom shadow-none">
                                                {t("All Day")}
                                            </button>
                                        </DatePicker>
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="hour">
                                                <i className="tio-time"></i>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 d-flex align-items-center mt-1">
                        <Default className="w-auto w-lg-100 d-inline-block d-lg-block ml-auto" loading={loading.button} ref={refButton} form="filterForm">
                            {t("Apply")}
                        </Default>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FilterSidebar;
