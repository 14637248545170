import $ from "jquery";
import i18n, { getLanguage } from "./i18n";

import trTR from "./locales/datatables/tr-TR";
import enUS from "./locales/datatables/en-US";

$.DataTable = require("datatables.net-responsive");

const locales = {
    "tr-TR": trTR,
    "en-US": enUS
};

// All DataTables Turkish Letters Sorting Settings
if ($.fn.dataTableExt) {
    $.extend($.fn.dataTableExt.oSort, {
        "turkish-pre": function (data) {
            let special_letters = {
                C: "Ca",
                c: "ca",
                Ç: "Cb",
                ç: "cb",
                G: "Ga",
                g: "ga",
                Ğ: "Gb",
                ğ: "gb",
                I: "Ia",
                ı: "ia",
                İ: "Ib",
                i: "ib",
                O: "Oa",
                o: "oa",
                Ö: "Ob",
                ö: "ob",
                S: "Sa",
                s: "sa",
                Ş: "Sb",
                ş: "sb",
                U: "Ua",
                u: "ua",
                Ü: "Ub",
                ü: "ub"
            };

            for (let val in special_letters) {
                data = typeof data === "string" ? data.split(val).join(special_letters[val]).toLowerCase() : data;
            }

            return data;
        },

        "turkish-asc": function (a, b) {
            return a < b ? -1 : a > b ? 1 : 0;
        },

        "turkish-desc": function (a, b) {
            return a < b ? 1 : a > b ? -1 : 0;
        }
    });
}

// All DataTables errMode Settings
$.fn.DataTable.ext.errMode = "none";

// All DataTables Responsive Function
let _childNodeStore = {};

function _childNodes(dt, row, col) {
    let name = row + "-" + col;

    if (_childNodeStore[name]) return _childNodeStore[name];

    // https://jsperf.com/childnodes-array-slice-vs-loop
    let nodes = [];
    let children = dt.cell(row, col).node().childNodes;

    for (var i = 0, ien = children.length; i < ien; i++) {
        nodes.push(children[i]);
    }

    _childNodeStore[name] = nodes;

    return nodes;
}

// All DataTables Responsive Settings [DEFAULTS]
$.extend(true, $.fn.dataTable.defaults, {
    dom: '<"top card-body"lf>rt<"bottom card-body"ip><"clear">',
    aLengthMenu: [
        [2, 25, 50, -1],
        [2, 25, 50, i18n.t("All")]
    ],
    responsive: {
        details: {
            renderer: function (api, rowIdx, columns) {
                let tbl = $('<table class="w-100"/>');
                let found = false;
                columns.forEach(col => {
                    if (col.hidden) {
                        $(`<tr data-dt-row="${col.rowIndex}" data-dt-column="${col.columnIndex}">
                        <th class="w-auto text-wrap">${col.title}</th></tr>`)
                            .append($("<td/>").append(_childNodes(api, col.rowIndex, col.columnIndex)))
                            .appendTo(tbl);
                        found = true;
                    }
                });

                return found ? tbl : false;
            }
        }
    },
    columnDefs: [{ targets: "no-sort", orderable: false }],
    language: locales[getLanguage()]
});
