import axios from "axios";
import Swal from "sweetalert2";

import i18n from "../../utils/i18n";
import "../defaults";
import { PRODUCTS_LIST, PRODUCT_ALGORITHMS_LIST } from "../constants";


export const ListProducts = () => {
    try {
        return axios(PRODUCTS_LIST)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const ListAlgorithms = data => {
    try {
        return axios
            .post(PRODUCT_ALGORITHMS_LIST, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};
