import _ from "lodash";

import Profile from "../views/Account/Profile";
import _404 from "../views/Pages/404";
import SocialDistanceCounter from "../views/App/SocialDistanceCounter";
import StoreAnalysis from "../views/App/StoreAnalysis";
import ExportReport from "../views/App/ExportReport";
import TechnicalMetrics from "../views/App/TechnicalMetrics";
import Stores from "../views/App/Stores";
import QueueOptimizer from "../views/App/QueueOptimizer";
import Detail from "../views/App/Detail";
import QuickView from "../views/App/QuickView";
import Analysis from "../views/App/Analysis";
import ShoppingCartThiefProtection from "../views/App/ShoppingCartThiefProtection";
import Heatmap from "../views/App/Heatmap";

import Cameras from "../components/App/Analysis/Page/Cameras";
import Products from "../components/App/Analysis/Page/Products";
import Algorithms from "../components/App/Analysis/Page/Algorithms";
import Framer from "../components/App/Analysis/Page/Framer";
import Settings from "../components/App/Analysis/Page/Settings";
import ShoppingCartThiefProtectionDetail from "../components/App/ShoppingCartThiefProtection/Detail";

import LiveCameras from "../views/App/LiveCameras";
import Watch from "../views/App/LiveCameras/Watch";

const staticRoutes = [
    {
        path: "social-distance-counter/:main_process_id?",
        title: "Social Distance Counter",
        component: SocialDistanceCounter,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "store-analysis/:main_process_id?",
        title: "Store Analysis",
        component: StoreAnalysis,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "export-data",
        title: "Data Export",
        component: ExportReport,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "queue-optimizer/:main_process_id?",
        title: "Queue Optimizer",
        component: QueueOptimizer,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "live-cameras",
        title: "Live Cameras",
        component: LiveCameras,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "quick-view",
        title: "Quick View",
        component: QuickView,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "analysis",
        title: "Analysis",
        component: Analysis,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "cart-thief-protection",
        title: "Shopping Cart Thief Protection",
        component: ShoppingCartThiefProtection,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "heatmaps",
        title: "Heatmaps",
        component: Heatmap,
        layout: "/",
        prop: { exact: true }
    }
];

const defaultRoutes = [
    {
        path: "technical-metrics",
        title: "Technical Metrics",
        component: TechnicalMetrics,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "stores",
        title: "Stores",
        component: Stores,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "live-cameras/watch/:camera_id",
        title: "Watch — Live Cameras",
        component: Watch,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "queue-optimizer/detail/:sub_process_id",
        title: "Detail • Queue Optimizer",
        component: Detail,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "analysis/select-camera",
        title: "Select Camera • Analysis",
        component: Cameras,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "analysis/select-product",
        title: "Select Product • Analysis",
        component: Products,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "analysis/select-algorithm",
        title: "Select Algorithm • Analysis",
        component: Algorithms,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "analysis/framer",
        title: "Framer • Analysis",
        component: Framer,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "analysis/settings",
        title: "Settings • Analysis",
        component: Settings,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "cart-thief-protection/detail",
        title: "Detail • Shopping Cart Thief Protection",
        component: ShoppingCartThiefProtectionDetail,
        layout: "/",
        prop: { exact: true }
    },
    {
        path: "/profile",
        title: "Profile & Account",
        component: Profile,
        layout: "/account",
        prop: { exact: true }
    },
    {
        path: "*",
        layout: "",
        title: "404",
        component: _404
    }
];

const appRoutes = menus =>
    _.xor(
        _.filter(staticRoutes, route => {
            let filteredMenu = _.map(menus, menu => menu.to.split("/")[1]);
            let path = route.path.replace("/:main_process_id?", "");

            return filteredMenu.indexOf(path) > -1;
        }),
        defaultRoutes
    );

export default appRoutes;
