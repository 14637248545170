import _ from "lodash";

import { getMenus } from "./information";
import { captureException } from "./handlers";

export const getRedirectMenu = menu => {
    let redirect = "/";

    try {
        if (menu) {
            if (_.isEmpty(menu.submenus)) redirect = menu.to;
            else redirect = _.head(menu.submenus).to;
        }

        return redirect;
    } catch (error) {
        captureException(error);

        return redirect;
    }
};

export const redirectMainMenu = () => {
    let menu = _.head(_.orderBy(getMenus(), "sort_key"));

    return getRedirectMenu(menu);
};

export const redirectCorrectMenu = (url, correctMainProcessId) => {
    let cleanURL,
        menus = getMenus();

    if (correctMainProcessId) cleanURL = url.split("/")[1];
    else cleanURL = url.replace("/:main_process_id?", "");

    let menu = _.find(menus, menu => menu.to.indexOf(cleanURL) > -1);

    return JSON.stringify(menus).indexOf(url) === -1 ? getRedirectMenu(menu) : "";
};
