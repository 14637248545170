import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import DateRange from "../../../components/DataFilter/DateRange";
import { GetStoreAnalysis } from "../../../api/services/store-analysis";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import DataNotFound from "../../State/DataNotFound";

ReactFC.fcRoot(FusionCharts, PowerCharts, ExcelExports, FusionTheme);

const DATE_FORMAT = "YYYY-MM-DD";

const chartConfigs = (data, maxValue, translations) => ({
    type: "heatmap",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(false),
            ...fusionChartExportDefaults(translations.chart),
            showLabels: "1",
            showPlotBorder: "1",
            plotBorderColor: "#ffffff",
            plotBorderThickness: "3",
            valueFontColor: "#ffffff",
            animation: "0",
            numberScaleValue: "1000,1000,1000",
            numberScaleUnit: "b,M,Mr",
            labelDisplay: "rotate",
            xAxisName: translations.hour
        },
        // chart Data
        ...data,
        // color palette
        colorRange: {
            gradient: "1",
            minValue: "0",
            code: "#c3f1e8",
            color: [
                {
                    code: "#c3f1e8",
                    minValue: "0",
                    maxValue: maxValue * 0.25
                },
                {
                    code: "#75deca",
                    minValue: maxValue * 0.25,
                    maxValue: maxValue * 0.5
                },
                {
                    code: "#51d7bd",
                    minValue: maxValue * 0.5,
                    maxValue: maxValue * 0.75
                },
                {
                    code: "#009980",
                    minValue: maxValue * 0.75,
                    maxValue: maxValue
                }
            ]
        }
    }
});

const momentSubtract = (date, duration, unit) => moment(date).subtract(duration, unit).toDate();

const VisitorsDensity = ({ subProcesses }) => {
    const [startDate, setStartDate] = useState(momentSubtract(undefined, 7, "days"));
    const [minDate, setMinDate] = useState(momentSubtract(undefined, 90, "days"));
    const [endDate, setEndDate] = useState(moment().toDate());
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState("");
    const [data, setData] = useState([]);
    const [maxValue, setMaxValue] = useState(0);
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Visitor Density"),
        hour: t("Hour"),
        visitors: t("Visitors Average"),
        day: t("Day"),
        densityAverage: t("Density Average")
    };
    const handleStart = date => setStartDate(date);
    const handleEnd = date => {
        setEndDate(date);
        setMinDate(momentSubtract(date, 90, "days"));
    };
    const handleSubmit = () => {
        if (startDate && endDate && endDate >= startDate) {
            setErrors("");
            setLoading(true);
            fetchData();
        } else {
            setErrors("is-invalid");
        }
    };

    const fetchData = () => {
        try {
            GetStoreAnalysis({
                sid: getStoreKey(),
                interval: "density",
                startDate: moment(startDate).format(DATE_FORMAT),
                endDate: moment(endDate).format(DATE_FORMAT),
                tz: getStoreTimezone(),
                spid: subProcesses,
                rnd: new Date().getTime()
            }).then(data => {
                if (isMountedRef.current) {
                    if (data && !_.isEmpty(data)) {
                        // all values sum
                        let totalValue = _.sumBy(data, "value");

                        // processed data added displayValue
                        data = _.map(data, item => {
                            let percentage = ((item.value / totalValue) * 100).toFixed(2);
                            let displayValue = `${item.value.readable()} (${percentage}%)`;

                            return {
                                ...item,
                                tooltext: `<b>${TRANSLATIONS.densityAverage}</b>{br}<b>${TRANSLATIONS.day}:</b> $rowLabel{br}<b>${TRANSLATIONS.hour}:</b> $columnLabel{br}<b>${TRANSLATIONS.visitors}:</b> ${displayValue}`
                            };
                        });

                        // generate row data (it means weekdays)
                        let row = _.map(moment.weekdays(true), (day, index) => {
                            return {
                                id: (index + 1).toString(),
                                label: day
                            };
                        });

                        //generate column data (it means hours)
                        let column = _.orderBy(
                            _.uniqBy(
                                _.map(data, item => {
                                    return {
                                        id: item["columnid"],
                                        label: moment(item["columnid"], "H").format("HH:00")
                                    };
                                }),
                                "id"
                            ),
                            item => {
                                return parseInt(item.id);
                            },
                            "asc"
                        );

                        // set max value
                        setMaxValue(_.maxBy(data, item => item.value)?.value);

                        // set processed & generated data
                        setData({
                            rows: {
                                row
                            },
                            columns: {
                                column
                            },
                            dataSet: [
                                {
                                    data
                                }
                            ]
                        });
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {}
    };

    useEffect(() => {
        if (subProcesses) fetchData();
    }, [isMountedRef, subProcesses]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={moment().toDate()}
                    handleStart={handleStart}
                    handleEnd={handleEnd}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    className={errors}
                />
            </div>
            <div className="card-body pb-1 pl-1">
                {data ? (
                    <ReactFC {...chartConfigs(data, maxValue, TRANSLATIONS)} />
                ) : (
                    <DataNotFound className="pb-3 pl-3" />
                )}
            </div>
        </div>
    );
};

export default VisitorsDensity;
