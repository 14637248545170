import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import $ from "jquery";

import PageHeader from "../../components/App/PageHeader";
import Default from "../../components/Buttons/Default";
import Modal from "../../components/App/QueueOptimizer/Modal";
import { useIsMountedRef } from "../../utils/core";
import { ListSubProcesses } from "../../api/services/sub-processes";
import { ListQueueOptimizer } from "../../api/services/queue-optimizer";
import { getAccountInformation, formatDate, getStoreTimezone } from "../../utils/information";
import DataLoader from "../../components/DataLoader";

const QueueOptimizer = () => {
    const queueInterval = useRef(null);
    const [data, setData] = useState(null);
    const [values, setValues] = useState({});
    const { current_store_id, current_store_key } = getAccountInformation();
    const { main_process_id } = useParams();
    const history = useHistory();
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();
    const REQUEST_DURATION = { sub_process: 1000 * 60, queue_optimizer: 1000 * 5 };

    const generateStatus = ({ live, warning_limit, capacity, is_tracking }) => {
        let status, message;

        if (!is_tracking) {
            status = "secondary";
            message = t("Closed");
        } else if (live < warning_limit) {
            status = "success";
            message = t("Available");
        } else if (live >= warning_limit && live < capacity) {
            status = "warning";
            message = t("Warning");
        } else {
            status = "danger";
            message = t("Overload");
        }

        return { status, message };
    };

    const generateProgressBar = (live, capacity) => (100 / capacity) * live;

    const listQueueOptimizer = data => {
        let sub_process_id = _.join(_.map(data, "id"), ",");

        ListQueueOptimizer({
            sid: current_store_key,
            dt: "live",
            spid: sub_process_id,
            rnd: new Date().getTime()
        }).then(response => {
            if (isMountedRef.current) {
                if (response) {
                    let sub_processes = _.map(data, ({ id, name, settings, is_tracking }) => ({
                        id: id,
                        name: name,
                        warning_limit: settings.warning_limit,
                        message: settings.message,
                        capacity: settings.capacity,
                        camera_id: settings.camera_id,
                        is_tracking: is_tracking
                    }));

                    let queue_optimizers = _.map(response, ({ sub_process_id, live, created_at }) => ({
                        id: sub_process_id,
                        live: live,
                        created_at: created_at
                            ? moment(created_at)
                                  .add(getStoreTimezone(), "hours")
                                  .format("YYYY-MM-DD HH:mm:ss")
                            : "—"
                    }));

                    let result = _.filter(sub_processes, item => {
                        if (_.find(queue_optimizers, { id: item.id })) {
                            return _.merge(item, _.find(queue_optimizers, { id: item.id }));
                        }
                    });

                    setData(result);
                } else setData([]);
            }
        });
    };

    const listSubProcesses = () => {
        clearInterval(queueInterval.current);

        ListSubProcesses({
            store_id: current_store_id,
            main_process_id: main_process_id
        }).then(result => {
            if (isMountedRef.current) {
                let data = result?.data || [];

                if (data) {
                    listQueueOptimizer(data);
                    queueInterval.current = setInterval(
                        () => listQueueOptimizer(data),
                        REQUEST_DURATION.queue_optimizer
                    );
                }
            }
        });
    };

    useEffect(() => {
        listSubProcesses();

        const subProcessInterval = setInterval(listSubProcesses, REQUEST_DURATION.sub_process);

        return () => {
            clearInterval(subProcessInterval);
            clearInterval(queueInterval.current);
        };
    }, [isMountedRef, main_process_id]);

    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip();

        return () => $('[data-toggle="tooltip"]').tooltip("dispose");
    }, [data]);

    return (
        <div className="content container-fluid">
            <Modal {...values} />
            <PageHeader
                component={() => (
                    <Default
                        type="button"
                        data-toggle="modal"
                        data-target="#queueOptimizerModal"
                        onClick={() => setValues({})}>
                        <i className="fa fa-plus-square"></i> {t("Create Queue Area")}
                    </Default>
                )}>
                {t("Queue Optimizer")}
            </PageHeader>
            <div className="row">
                <DataLoader data={data}>
                    {data?.map(queue => {
                        const { is_tracking, name, id, capacity, created_at } = queue;
                        let live = is_tracking ? queue.live : 0;

                        return (
                            <div className="col-lg-3 col-md-4 col-sm-6 mb-5" key={id}>
                                <div className="card card-hover-shadow">
                                    <div className="card-progress-wrap">
                                        <div className="progress card-progress">
                                            <div
                                                className={`progress-bar bg-${generateStatus(queue).status}`}
                                                role="progressbar"
                                                style={{ width: generateProgressBar(live, capacity) + "%" }}
                                                aria-valuenow="0"
                                                aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="card-header">
                                        <h5 className="card-header-title">
                                            <span
                                                className={`legend-indicator bg-${
                                                    generateStatus(queue).status
                                                }`}></span>
                                            {name}
                                        </h5>
                                        <div>
                                            <button
                                                className="btn btn-sm btn-icon btn-ghost-secondary rounded-circle"
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `/queue-optimizer/detail/${id}`,
                                                        state: { name: name }
                                                    })
                                                }>
                                                <i className="tio tio-chart-bar-4"></i>
                                            </button>
                                            <button
                                                className="btn btn-sm btn-icon btn-ghost-secondary rounded-circle"
                                                onClick={() => setValues(queue)}
                                                data-toggle="modal"
                                                data-target="#queueOptimizerModal">
                                                <i className="fa fa-cogs"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body py-7">
                                        <div className="row">
                                            <div className="col text-center">
                                                <span
                                                    className={`d-block display-3 mb-0 text-${
                                                        generateStatus(queue).status
                                                    }`}>
                                                    {live}
                                                </span>
                                                <span className="d-block h4 text-muted font-weight-normal mb-0">
                                                    {t("Live")}
                                                </span>
                                            </div>
                                            <div className="col column-divider text-center">
                                                <span className="d-block display-3 mb-0">{capacity}</span>
                                                <span className="d-block h4 text-muted font-weight-normal mb-0">
                                                    {t("Capacity")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between align-items-center">
                                        <div className="font-weight-bold">{generateStatus(queue).message}</div>
                                        <div
                                            className="small text-muted"
                                            data-toggle="tooltip"
                                            title={formatDate(created_at, "LL HH:mm:ss")}>
                                            {moment(created_at).isValid() ? moment(created_at).fromNow() : "—"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </DataLoader>
            </div>
        </div>
    );
};

export default QueueOptimizer;
