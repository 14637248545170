import axios from "axios";
import Swal from "sweetalert2";

import "../defaults";
import i18n from "../../utils/i18n";
import { CAMERAS_LIST, CAMERAS_THUMBNAIL_GET, CAMERA_WATCHER_STATUS_CHANGE } from "../constants";
import { getAccountInformation } from "../../utils/information";
import { captureException } from "../../utils/handlers";

export const ListCameras = data => {
    const { current_store_id } = getAccountInformation();

    try {
        return axios
            .post(CAMERAS_LIST + "?sk=" + current_store_id, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};

export const CameraWatcherStatusChange = data => {
    try {
        return axios
            .post(CAMERA_WATCHER_STATUS_CHANGE, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};

export const GetCameraThumbnail = data => {
    try {
        return axios
            .post(CAMERAS_THUMBNAIL_GET, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {
        captureException(error);
    }
};
