import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const DataLoader = ({ data, children }) => {
    const { t } = useTranslation();

    if (data && _.isArray(data) && !_.isEmpty(data)) {
        return children;
    } else if (_.isArray(data) && _.isEmpty(data)) {
        return <div className="text-muted font-italic text-center w-100">{t("Data not found...")}</div>;
    } else {
        return (
            <div className="spinner-border m-auto" role="status">
                <span className="sr-only">{t("Loading")}...</span>
            </div>
        );
    }
};

export default DataLoader;
