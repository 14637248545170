import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import PageHeader from "../../components/App/PageHeader";
import InternetSpeed from "../../components/TechnicalMetrics/Charts/InternetSpeed";
import PackageOutput from "../../components/TechnicalMetrics/Charts/PackageOutput";
import { useIsMountedRef } from "../../utils/core";
import { ListRaspiDevices } from "../../api/services/raspi-devices";
import { getAccountInformation } from "../../utils/information";

const TechnicalMetrics = () => {
    const [cards, setCards] = useState(null);
    const isMountedRef = useIsMountedRef();
    const { current_store_id } = getAccountInformation();
    const { t } = useTranslation();

    useEffect(() => {
        try {
            ListRaspiDevices({
                store_id: current_store_id,
                rnd: new Date().getTime()
            }).then(response => {
                if (isMountedRef.current) {
                    if (!_.isEmpty(response?.data)) setCards(response?.data?.map(card => card.name));
                    else setCards(null);
                }
            });
        } catch (error) {}
    }, [isMountedRef, current_store_id]);

    return (
        <div className="content container-fluid">
            <PageHeader>{t("Technical Metrics")}</PageHeader>
            <div className="row">
                <div className="col-12 mb-lg-5 mb-3">
                    <InternetSpeed cards={cards} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-lg-5 mb-3">
                    <PackageOutput cards={cards} />
                </div>
            </div>
        </div>
    );
};

export default TechnicalMetrics;
