import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import DataNotFound from "../../../components/State/DataNotFound";
import DateRange from "../../../components/DataFilter/DateRange";
import { GetRealTimeAnalytics } from "../../../api/services/social-distance-counter";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";
import ChartsScrollView from "../../Info/ChartsScrollView";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const DATE_FORMAT = "YYYY-MM-DD";

const chartConfigs = (data, translations, scrollView) => ({
    type: "scrollcolumn2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(scrollView),
            ...fusionChartExportDefaults(translations.chart),
            showLegend: "1",
            paletteColors: "#00c9a7",
            xAxisname: translations.date,
            yAxisname: translations.visitors
        },
        // Chart Data
        ...data
    }
});

const DailyMaximumDensity = () => {
    const [startDate, setStartDate] = useState(moment().subtract(30, "days"));
    const [endDate, setEndDate] = useState(moment().subtract(1, "days").endOf("day"));
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState("");
    const [data, setData] = useState([{}]);
    const [scrollView, setScrollView] = useState(true);
    const isMountedRef = useIsMountedRef();
    const { main_process_id } = useParams();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Daily Maximum Density"),
        date: t("Date"),
        visitors: `${t("Visitors Count")} (${t("Store Entry")})`
    };

    const handleStart = date => setStartDate(moment(date));
    const handleEnd = date => setEndDate(moment(date));
    const handleSubmit = () => {
        if (startDate && endDate && endDate >= startDate) {
            setErrors("");
            setLoading(true);
            fetchData();
        } else {
            setErrors("is-invalid");
        }
    };

    const fetchData = () => {
        try {
            GetRealTimeAnalytics({
                sid: getStoreKey(),
                dt: "daily_maximum",
                startDate: startDate.format(DATE_FORMAT),
                endDate: endDate.format(DATE_FORMAT),
                mpid: main_process_id,
                tz: getStoreTimezone(),
                rnd: new Date().getTime()
            }).then(result => {
                if (isMountedRef.current) {
                    if (!_.isEmpty(result?.data)) {
                        let generatedData = _.map(result.data, item => {
                            let date = moment(item.label).format("dddd, DD MMMM YYYY");

                            return {
                                value: item.value,
                                label: moment(item.label).format("DD MMM YY"),
                                tooltext: `
                                    <b>${TRANSLATIONS.date}:</b> ${date}<br/>
                                    <b>${TRANSLATIONS.visitors}:</b> ${item.value.readable()}
                                `
                            };
                        });

                        setData({
                            categories: [
                                {
                                    category: _.map(generatedData, ({ label }) => ({ label }))
                                }
                            ],
                            dataset: [{ data: _.map(generatedData, ({ value, tooltext }) => ({ value, tooltext })) }]
                        });
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [isMountedRef, main_process_id]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <DateRange
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    minDate={moment().subtract(90, "days").toDate()}
                    maxDate={moment().toDate()}
                    handleStart={handleStart}
                    handleEnd={handleEnd}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    className={errors}
                />
            </div>
            <ChartsScrollView onChange={event => setScrollView(event.target.checked)} />
            <div className="card-body pb-1 pl-1">
                {data ? <ReactFC {...chartConfigs(data, TRANSLATIONS)} /> : <DataNotFound className="pb-3 pl-3" />}
            </div>
        </div>
    );
};

export default DailyMaximumDensity;
