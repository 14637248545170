import React, { createContext, useState, useContext, useEffect } from "react";
import { encryptText, decryptText } from "../../../utils/crypto";

const DataContext = createContext();
const CACHE_NAME = "cmz-context-record";

export const DataProvider = ({ children }) => {
    const cache = sessionStorage.getItem(CACHE_NAME);
    const [data, setData] = useState({});

    const setValues = values => {
        setData(prevData => {
            let result = { ...prevData, ...values };

            encryptText(JSON.stringify(result)).then(result => sessionStorage.setItem(CACHE_NAME, result));

            return result;
        });
    };

    const clearValues = () => {
        setData({});
        sessionStorage.removeItem(CACHE_NAME);
    };

    useEffect(() => {
        if (cache) decryptText(cache).then(result => setData(JSON.parse(result)));
    }, []);

    return <DataContext.Provider value={{ data, setValues, clearValues }}>{children}</DataContext.Provider>;
};

export const useData = () => useContext(DataContext);
