import React, { useEffect, useState } from "react";

import { GetCameraThumbnail } from "../../../../api/services/cameras";
import { GetSubProcessConfigs } from "../../../../api/services/sub-processes";
import { useIsMountedRef } from "../../../../utils/core";
import { getLanguage } from "../../../../utils/i18n";
import { getAccountInformation } from "../../../../utils/information";
import { useData } from "../DataContext";

const Workspace = ({ onMessage }) => {
    const isMountedRef = useIsMountedRef();
    const { data } = useData();
    const [thumbnail, setThumbnail] = useState("");
    const [coords, setCoords] = useState({});
    const [prevCoords, setPrevCoords] = useState({});
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const { current_store_key } = getAccountInformation();

    const fetchCameraThumbnail = () => {
        GetCameraThumbnail({
            camera_id: data.camera
        }).then(result => {
            if (isMountedRef.current) {
                if (result.status === 200) {
                    setThumbnail(result?.url);
                }
            }
        });
    };

    const fetchConfigs = () => {
        GetSubProcessConfigs({
            store_key: current_store_key,
            sub_process_id: data.sub_process
        }).then(result => {
            if (isMountedRef.current) {
                if (result.status === 200) {
                    let data = result?.data;
                    let state = {
                        ...data,
                        LEFT: parseInt(data.LEFT),
                        BOTTOM: parseInt(data.BOTTOM),
                        TOP: parseInt(data.TOP),
                        RIGHT: parseInt(data.RIGHT)
                    };

                    setCoords(state);
                    setPrevCoords(state);
                }
            }
        });
    };

    const communicationIframe = event => {
        if (event.data.source === "framer") {
            onMessage({
                coords: { ...prevCoords, ...event.data.coords, CAMERA_ID: data.camera.toString() },
                prevCoords: prevCoords
            });
        }
    };

    useEffect(() => {
        window.workspace.addEventListener("load", () => setIframeLoaded(true));

        return () => window.workspace?.removeEventListener("load", () => setIframeLoaded(true));
    }, []);

    useEffect(() => {
        window.workspace.postMessage(
            {
                thumbnail: thumbnail,
                coords: coords
            },
            "*"
        );

        window.addEventListener("message", communicationIframe);
        return () => window.removeEventListener("message", communicationIframe);
    }, [iframeLoaded, thumbnail, coords]);

    useEffect(() => {
        fetchCameraThumbnail();
        fetchConfigs();
    }, [isMountedRef]);

    return (
        <div className="d-flex w-100">
            <div
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="/framer/index.html?lang=${getLanguage()}" name="workspace" frameBorder="0" width="100%" height="100%" class="position-absolute left-0 right-0 top-0 bottom-0 z-index-2"></iframe>`
                }}></div>
        </div>
    );
};

export default Workspace;
