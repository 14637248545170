import moment from "moment";

import i18n from "./i18n";

const windowWidth = window.innerWidth < 768;

const fusionChartGeneralDefaults = scrollView => ({
    theme: "fusion",
    numVDivLines: "10",
    showValues: "1",
    valueFontColor: "#ffffff",
    plotSpacePercent: "20",
    yAxisValueFontColor: "#9aa5b0",
    labelFontColor: "#9aa5b0",
    toolTipColor: "#ffffff",
    toolTipBorderThickness: "0",
    toolTipBgColor: "#132144",
    toolTipBgAlpha: "100",
    toolTipBorderRadius: "4",
    toolTipPadding: "8",
    divlineColor: "#999999",
    scrollToEnd: "1",
    divLineIsDashed: "1",
    divLineDashLen: "1",
    divLineGapLen: "1",
    formatNumberScale: "0",
    thousandSeparator: ".",
    placeValuesInside: "1",
    legendIconBorderThickness: 4,
    legendIconScale: 0.85,
    lineThickness: 3,
    anchorRadius: 4,
    anchorBorderThickness: 4,
    numVisiblePlot: scrollView ? (windowWidth ? 7 : 15) : 0,
    rotateValues: windowWidth ? 1 : 0
});

const fusionChartExportDefaults = fileName => ({
    exportEnabled: 1,
    exportFileName: `${fileName?.split(" ").join("_")}_${moment().format("YYYY-MMM-DD-HH-mm-ss")}`,
    exportFormats: `PNG=${i18n.t("Export as PNG")}|XLSX=${i18n.t("Export as XLSX")}|PDF=${i18n.t("Export as PDF")}`
});

export { fusionChartGeneralDefaults, windowWidth, fusionChartExportDefaults };
