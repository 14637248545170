import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import PageHeader from "../../components/App/PageHeader";
import BasicInformation from "../../components/Account/Profile/BasicInformation";
import Password from "../../components/Account/Profile/Password";
import Navigation from "../../components/Account/Profile/Navigation";
import { GetProfile } from "../../api/services/account";
import { getAccessToken } from "../../utils/information";
import { useIsMountedRef } from "../../utils/core";

const Profile = () => {
    const [profileData, setProfileData] = useState({});
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    useEffect(() => {
        GetProfile({ access_token: getAccessToken() }).then(result => {
            if (isMountedRef.current) {
                if (result?.status) {
                    const status = result.status;

                    if (status === 200) {
                        setProfileData(result.data);
                    } else {
                        Swal.fire({
                            title: `${t("Error code")}: ${status}`,
                            text: t(result.message),
                            icon: "error"
                        });
                    }
                }
            }
        });
    }, [isMountedRef]);

    return (
        <div
            className="content container-fluid"
            data-offset="80"
            data-cmz-scrollspy-options='{"target": "#navbarSettings"}'>
            <PageHeader>{t("Profile & Account")}</PageHeader>
            <div className="row">
                <div className="col-lg-3">
                    <Navigation />
                </div>
                <div className="col-lg-9">
                    <BasicInformation data={profileData} />
                    <Password />
                    <div id="stickyBlockEndPoint"></div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
