import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import DataNotFound from "../../State/DataNotFound";
import { fusionChartGeneralDefaults, fusionChartExportDefaults, windowWidth } from "../../../utils/settings";
import { getStoreTimezone } from "../../../utils/information";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const chartConfigs = (data, translations, scrollView) => ({
    type: "scrollline2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(scrollView),
            ...fusionChartExportDefaults(translations.time),
            showValues: "0",
            plotColorinTooltip: 0,
            xAxisname: translations.hour,
            yAxisname: translations.duration,
            formatNumberScale: "1",
            numberScaleValue: "60",
            scaleRecursively: "1",
            maxScaleRecursion: "-1",
            scaleSeparator: " ",
            numberScaleUnit: windowWidth ? translations.minShort : translations.min,
            defaultNumberScale: windowWidth ? translations.secShort : translations.sec
        },
        // Chart Data
        ...data
    }
});

const WaitingTime = ({ data, loading, scrollView }) => {
    const [dataset, setDataset] = useState({});
    const timezone = getStoreTimezone();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        time: t("Waiting Time"),
        duration: t("Duration"),
        closed: t("Queue is Closed"),
        average: t("Average"),
        maximum: t("Maximum"),
        min: t("min"),
        sec: t("sec"),
        minShort: t("minShort"),
        secShort: t("secShort"),
        hour: t("Hour")
    };

    useEffect(() => {
        if (data?.length) {
            let colors = { maximum: "#f5ca99", average: "#29c3be", closed: "#132144" };

            setDataset({
                categories: [
                    {
                        category: _.map(data, item => ({
                            label: moment(item.hour, "H").add(timezone, "hours").format("HH:00")
                        }))
                    }
                ],
                dataset: [
                    {
                        seriesname: TRANSLATIONS.maximum,
                        renderAs: "line",
                        anchorBorderColor: colors.maximum,
                        data: _.map(data, item => ({
                            color: colors.maximum,
                            anchorBorderColor: item.time.maximum ? colors.maximum : colors.closed,
                            value: item.time.maximum,
                            tooltext: `
                                    <div style="font-size: 110%">
                                    ${moment(item.hour, "H").add(timezone, "hours").format("HH:00")}
                                    </div>
                                    ${
                                        item.time.maximum
                                            ? `<b><span class="legend-indicator bg-warning"></span>${
                                                  TRANSLATIONS.maximum
                                              }</b><br/> ${item.time.maximum.duration()}`
                                            : `<b><span class="legend-indicator bg-secondary"></span>${TRANSLATIONS.closed}</b>`
                                    }
                                `
                        }))
                    },
                    {
                        seriesname: TRANSLATIONS.average,
                        renderAs: "line",
                        anchorBorderColor: colors.average,
                        data: _.map(data, item => ({
                            color: colors.average,
                            anchorBorderColor: item.time.average ? colors.average : colors.closed,
                            value: item.time.average,
                            tooltext: item.time.average
                                ? `
                                    ${
                                        item.time.average
                                            ? `<b><span class="legend-indicator bg-success"></span>${
                                                  TRANSLATIONS.average
                                              }</b><br/>
                                            ${item.time.average.duration()}`
                                            : `<b><span class="legend-indicator bg-secondary"></span>${TRANSLATIONS.closed}</b>`
                                    }
                                `
                                : "<span></span>"
                        }))
                    }
                ]
            });
        } else setDataset({});
    }, [data]);

    if (loading)
        return (
            <div className="w-100 text-center pt-3 pb-5 pl-3">
                <div className="spinner-border m-auto" role="status">
                    <span className="sr-only">{t("Loading")}...</span>
                </div>
            </div>
        );
    else if (_.isEmpty(dataset)) return <DataNotFound className="pt-3 pb-5" />;
    else return <ReactFC {...chartConfigs(dataset, TRANSLATIONS, scrollView)} />;
};

export default WaitingTime;
