import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import Default from "../../Buttons/Default";
import { default as PasswordInput } from "../../Forms/Password";
import { ChangePassword } from "../../../api/services/account";
import { getAccessToken } from "../../../utils/information";

const Password = () => {
    const { register, handleSubmit, errors, getValues } = useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const onSubmit = data => {
        setLoading(true);
        ChangePassword({
            access_token: getAccessToken(),
            current_password: data.currentPassword,
            new_password: data.newPassword
        }).then(result => {
            const status = result.status;

            if (status === 200) {
                Swal.fire({
                    title: "Successfull",
                    text: t(result.message),
                    icon: "success"
                }).then(condition => {
                    if (condition.value) {
                        history.push("/");
                    }
                });
            } else {
                Swal.fire({
                    title: `${t("Error code")}: ${status}`,
                    text: t(result.message),
                    icon: "error"
                });
            }
            setLoading(false);
        });
    };

    return (
        <div id="passwordSection" className="card mb-3 mb-lg-5">
            <div className="card-header">
                <h4 className="card-title">{t("Change your password")}</h4>
            </div>
            <div className="card-body">
                <form id="changePasswordForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row form-group">
                        <label htmlFor="currentPassword" className="col-sm-3 col-form-label input-label">
                            {t("Current password")}
                        </label>
                        <div className="col-sm-9">
                            <PasswordInput
                                ref={register({ required: true })}
                                name="currentPassword"
                                id="currentPassword"
                                placeholder={t("Enter your current password")}
                                toggle={true}
                                className={errors.currentPassword ? "is-invalid" : ""}
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <label htmlFor="newPassword" className="col-sm-3 col-form-label input-label">
                            {t("New password")}
                        </label>
                        <div className="col-sm-9">
                            <PasswordInput
                                ref={register({
                                    required: true,
                                    minLength: 8
                                })}
                                name="newPassword"
                                id="newPassword"
                                placeholder={t("Enter your new password")}
                                toggle={true}
                                className={errors.newPassword ? "is-invalid" : ""}
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <label htmlFor="confirmNewPassword" className="col-sm-3 col-form-label input-label">
                            {t("Confirm new password")}
                        </label>
                        <div className="col-sm-9">
                            <div className="mb-3">
                                <PasswordInput
                                    ref={register({
                                        required: true,
                                        minLength: 8,
                                        validate: value => value === getValues("newPassword")
                                    })}
                                    name="confirmNewPassword"
                                    id="confirmNewPassword"
                                    placeholder={t("Confirm your new password")}
                                    toggle={true}
                                    className={errors.confirmNewPassword ? "is-invalid" : ""}
                                />
                            </div>
                            <h5>{t("Password requirements")}:</h5>
                            <p className="font-size-sm mb-2">{t("Ensure that these requirements are met")}:</p>
                            <ul className="font-size-sm">
                                <li>{t("Minimum 8 characters long - the more, the better")}</li>
                                <li>{t("At least one lowercase character")}</li>
                                <li>{t("At least one uppercase character")}</li>
                                <li>{t("At least one number, symbol or whitespace character")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Default loading={loading}>{t("Save changes")}</Default>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Password;
