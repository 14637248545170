import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";

import Logo from "../Logo";
import { getMenus } from "../../utils/information";

const MenuItem = ({ id, to, name, icon, submenus }) => {
    const location = useLocation();
    const { t } = useTranslation();

    if (submenus && !_.isEmpty(submenus)) {
        let mainActive = location.pathname.indexOf(to) > -1 ? "active" : "";

        return (
            <li className="navbar-vertical-aside-has-menu" key={id}>
                <a
                    href=""
                    onClick={e => e.preventDefault()}
                    className={`js-navbar-vertical-aside-menu-link nav-link nav-link-toggle cursor-pointer ${mainActive}`}
                    title={t(name)}>
                    <i className={`nav-icon ${icon}`} />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t(name)}</span>
                </a>

                <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    {submenus.map((submenu, index) => (
                        <li className="nav-item" key={name + index}>
                            <NavLink
                                className="js-nav-tooltip-link nav-link"
                                activeClassName="active"
                                to={submenu.to}
                                title={submenu.name}
                                data-placement="left">
                                <span className="tio-circle nav-indicator-icon"></span>
                                <span className="text-truncate">{t(submenu.name)}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </li>
        );
    } else {
        return (
            <li className="nav-item" key={id}>
                <NavLink
                    className="js-nav-tooltip-link nav-link"
                    activeClassName="active"
                    to={to}
                    title={name}
                    data-placement="left">
                    <i className={`nav-icon ${icon}`} />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t(name)}</span>
                </NavLink>
            </li>
        );
    }
};

const MenuDivider = id => {
    return (
        <li className="nav-item" key={id}>
            <div className="nav-divider"></div>
        </li>
    );
};

const MenuSubTitle = ({ id, name }) => {
    return (
        <li className="nav-item" key={id}>
            <small className="nav-subtitle" title={name}>
                {name}
            </small>
            <small className="tio-more-horizontal nav-subtitle-replacer"></small>
        </li>
    );
};

const renderMenuItem = () => {
    // Export Data (20) & Technical Metrics (36) menu filtered
    let filteredMenus = _.filter(getMenus(), menu => [20, 36].indexOf(menu.id) === -1);

    return filteredMenus.map((item, index) => {
        switch (item.type) {
            case "sub-title":
                return MenuSubTitle(item);
            case "divider":
                return MenuDivider(index);
            default:
                return MenuItem(item);
        }
    });
};

const Menu = () => {
    useEffect(() => {
        $(".js-navbar-vertical-aside").cmzSideNav();

        $(".js-nav-tooltip-link").tooltip({ boundary: "window" });

        $(".js-nav-tooltip-link").on("show.bs.tooltip", function(e) {
            if (!$("#app-body").hasClass("navbar-vertical-aside-mini-mode")) {
                return false;
            }
        });
    });

    return (
        <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-light navbar-bordered">
            <div className="navbar-vertical-container">
                <div className="navbar-vertical-footer-offset">
                    <div className="navbar-brand-wrapper justify-content-between">
                        <a className="navbar-brand" href="/" aria-label="Cameralyze">
                            <Logo className="navbar-brand-logo" />
                            <Logo className="navbar-brand-logo-mini" mini={true} />
                        </a>
                        <button
                            type="button"
                            className="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-toggle btn btn-icon btn-xs btn-ghost-dark">
                            <i className="tio-clear tio-lg" />
                        </button>
                    </div>
                    <div className="navbar-vertical-content">
                        <ul className="navbar-nav navbar-nav-lg nav-tabs">{renderMenuItem()}</ul>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default Menu;
