import axios from "axios";
import Swal from "sweetalert2";

import i18n from "../../utils/i18n";
import "../defaults";
import { ARINIOT_API_URL, GET_INTERNET_SPEEDS, GET_PACKAGE_OUTPUT } from "../constants";

export const GetInternetSpeeds = params => {
    try {
        return axios
            .post(ARINIOT_API_URL + GET_INTERNET_SPEEDS, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const GetPackageOutput = params => {
    try {
        return axios
            .post(ARINIOT_API_URL + GET_PACKAGE_OUTPUT, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};
