import axios from "axios";

import "../defaults";
import { SESSION_START, USER_REFRESH_TOKEN } from "../constants";
import { captureException } from "../../utils/handlers";

export const SessionStart = () => {
    try {
        return axios(SESSION_START + `?rnd=${new Date().getTime()}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error);
            });
    } catch (error) {}
};

export const RefreshToken = params => {
    try {
        return axios
            .post(USER_REFRESH_TOKEN, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error);
            });
    } catch (error) {}
};
