import React, { useEffect } from "react";
import $ from "jquery";
import CMZTogglePassword from "../../assets/vendor/toggle-password/toggle-password";

/**
 *
 * @param {string} [value] Input value
 * @param {string} [className] Input extra class
 * @param {boolen} [toggle=true] Password show/hide toggle [true, false]
 * @param {object} [toggleOptions] Password show/hide toggle options
 * @param {string} [placeholder] Input placeholder
 * @param {string} [size=md] Input size [sm, md, lg]
 * @param {string} name Input name
 * @param {string} id Input id
 */
const Password = React.forwardRef(
    ({ value, className, toggle = true, toggleOptions = {}, size = "md", name, id, placeholder, ...props }, ref) => {
        let toggleButtonId = `togglePasswordButton__${id}`;
        let toggleIconId = `togglePasswordIcon__${id}`;

        let toggleClass = toggle ? "js-toggle-password" : "";
        let toggleOptionAttribute = toggle
            ? {
                  "data-cmz-toggle-password-options": JSON.stringify({
                      target: `#${toggleButtonId}`,
                      classChangeTarget: `#${toggleIconId}`,
                      defaultClass: "tio-hidden-outlined",
                      showClass: "tio-visible-outlined",
                      ...toggleOptions
                  })
              }
            : {};

        useEffect(() => {
            $(".js-toggle-password").each(function() {
                if ($(this).attr("data-cmz-toggle-password-invoker") === undefined) {
                    new CMZTogglePassword(this).init();
                }
            });
        });

        return (
            <div className="input-group input-group-merge">
                <input
                    ref={ref}
                    type="password"
                    className={`${toggleClass} form-control form-control-${size} ${className}`}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    {...toggleOptionAttribute}
                    {...props}
                />
                {toggle && (
                    <div id={toggleButtonId} className="input-group-append cursor-pointer">
                        <span className="input-group-text">
                            <i id={toggleIconId} className="tio-visible-outlined" />
                        </span>
                    </div>
                )}
            </div>
        );
    }
);

export default Password;
