export const API_URL = "https://api.cameralyze.com";
export const ARINIOT_API_URL = "https://api.ariniot.com";

export const REAL_TIME_ANALYTICS_API_URL = "/social-distance-counter";
export const STORE_ANALYSIS_API_URL = "/visitor";
export const LOGIN = "/login";
export const USER_GET_PROFILE = "/user/profile/get";
export const USER_UPDATE_PROFILE = "/user/profile/update";
export const USER_CHANGE_PASSWORD = "/user/password/change";
export const USER_STORE_CHANGE = "/user/store/change";
export const USER_REFRESH_TOKEN = "/user/token/refresh";
export const GET_LANGUAGES = "/get/languages";
export const GET_TRANSLATIONS = "/get/translations";
export const GET_MAIN_PROCESS = "/main-process/get";
export const LIST_MAIN_PROCESSES = "/main-process/list";
export const UPDATE_MAIN_PROCESS = "/main-process/update";
export const REAL_TIME_ANALYTICS_LOGS = "/real-time-analytics/logs";
export const CREATE_EXPORT_REPORT = "/export-report/create";
export const LIST_EXPORT_REPORTS = "/export-report/list";
export const DOWNLOAD_REPORT = "/export-report/download";
export const LIST_STORES = "/store/list";
export const GET_STORE = "/store/get";
export const GET_INTERNET_SPEEDS = "/internet-speeds/get";
export const GET_PACKAGE_OUTPUT = "/output-package/get";
export const LIST_RASPI_DEVICES = "/raspi-device/list";
export const SESSION_START = "/session/start";
export const SUB_PROCESS_LIST = "/sub-process/list";
export const SUB_PROCESS_CREATE = "/sub-process/create";
export const SUB_PROCESS_UPDATE = "/sub-process/update";
export const SUB_PROCESS_CONFIGS_GET = "/sub-process/configs/get";
export const SUB_PROCESS_CONFIGS_SET = "/sub-process/configs/set";
export const CAMERAS_LIST = "/camera/list";
export const CAMERAS_THUMBNAIL_GET = "/camera/thumbnail/get";
export const CAMERA_WATCHER_STATUS_CHANGE = "/camera/watcher/status-change";
export const PRODUCTS_LIST = "/product/list";
export const PRODUCT_ALGORITHMS_LIST = "/product/algorithm/list";
export const QUEUE_OPTIMIZER_LIST = "/queue-optimizer";
export const ANALYSIS_LIST = "/analysis/list";
export const ANALYSIS_CREATE = "/analysis/create";
export const ANALYSIS_UPDATE = "/analysis/update";
export const HEATMAP_PROCESS_LIST = "/heatmap/process/list";
export const HEATMAP_IMAGE_GET = "/heatmap/image/get";
export const SHOPPING_CART_THIEF_PROTECTION_LIST = "/shopping-basket-thief-protection/logs";
