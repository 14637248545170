import $ from "jquery";

$(document).ready(function() {
    const appBody = document.getElementById("app-body");
    const isMini = localStorage.getItem("navbar-vertical-aside-mini");

    if (appBody && isMini) {
        appBody.classList.add("navbar-vertical-aside-mini-mode");
    }
});
