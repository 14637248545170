import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import { useIsMountedRef } from "../../../utils/core";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { GetRealTimeAnalytics } from "../../../api/services/social-distance-counter";

const Details = ({ subProcess, date, hour }) => {
    const isMountedRef = useIsMountedRef();
    const timezone = getStoreTimezone();
    const [visitors, setVisitors] = useState({ maximum: 0, total: 0 });
    const [loading, setLoading] = useState({ maximum: false, total: false });
    const { t } = useTranslation();

    const renderVisitorsCount = type => {
        return loading[type] ? (
            <div className="spinner-border spinner-border-sm text-body m-auto" role="status"></div>
        ) : (
            visitors[type].readable()
        );
    };

    const fetchData = () => {
        let startDate, endDate;

        if (hour === null) {
            startDate = date + " 00:00:00";
            endDate = date + " 23:59:59";
        } else {
            let dateWithTimezone = `${date} ${moment(hour, "H").add(timezone, "hours").format("HH:00:00")}`;

            startDate = moment(dateWithTimezone).subtract(timezone, "hours").format("YYYY-MM-DD 00:00:00");
            endDate = moment(dateWithTimezone).subtract(timezone, "hours").format("YYYY-MM-DD HH:59:59");
        }

        setLoading({ maximum: true, total: true });

        GetRealTimeAnalytics({
            sid: getStoreKey(),
            dt: "graph",
            spid: subProcess,
            startDate: startDate,
            endDate: endDate
        }).then(result => {
            if (isMountedRef.current) {
                let data = result.data;
                let total = 0;

                if (data && !_.isEmpty(data)) {
                    if (hour === null) total = _.sumBy(data, "in");
                    else total = _.find(data, item => parseInt(item.hour) === hour + timezone)?.in || 0;
                }

                setVisitors(prevState => ({ ...prevState, total: total }));
                setLoading(prevState => ({ ...prevState, total: false }));
            }
        });

        GetRealTimeAnalytics({
            sid: getStoreKey(),
            dt: "live",
            spid: subProcess,
            startDate: startDate,
            endDate: endDate
        }).then(result => {
            if (isMountedRef.current) {
                setVisitors(prevState => ({ ...prevState, maximum: result?.count || 0 }));
                setLoading(prevState => ({ ...prevState, maximum: false }));
            }
        });
    };

    useEffect(() => {
        if (subProcess && date) fetchData();
    }, [isMountedRef, subProcess, date, hour]);

    return (
        <div className="card h-100">
            <div className="card-header">
                <h4 className="card-header-title">{t("Details")}</h4>
            </div>
            <div className="card-body">
                <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
                    <div className="list-group-item">
                        <div className="media align-items-center">
                            <i className="fa fa-user fa-fw mr-2"></i>
                            <div className="media-body">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <span className="d-block">{t("Total Visitor")}</span>
                                    </div>
                                    <div className="col-auto">
                                        <h5 className="mb-0">{renderVisitorsCount("total")}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="media align-items-center">
                            <i className="fa fa-user-friends fa-fw mr-2"></i>
                            <div className="media-body">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <span className="d-block">{t("Maximum Visitor")}</span>
                                    </div>
                                    <div className="col-auto">
                                        <h5 className="mb-0">{renderVisitorsCount("maximum")}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;
