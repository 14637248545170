import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import Day from "../../DataFilter/Day";
import DataNotFound from "../../State/DataNotFound";
import { GetStoreAnalysis } from "../../../api/services/store-analysis";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const DATE_FORMAT = "YYYY-MM-DD";
const START_DATE = moment().subtract(30, "days").format(DATE_FORMAT);
const END_DATE = moment().format(DATE_FORMAT);

const chartConfigs = (data, translations) => ({
    type: "column2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(false),
            ...fusionChartExportDefaults(translations.chart),
            showPercentValues: "1",
            xAxisname: translations.date,
            yAxisname: translations.visitors
        },
        // chart Data
        data: data
    }
});

const allDatesRange = () => {
    let dateArray = [];
    let diff = moment(END_DATE).diff(moment(START_DATE), "days");

    for (let i = 0; i <= diff; i++) {
        dateArray.push(moment(START_DATE).add(i, "days").format(DATE_FORMAT));
    }

    return dateArray;
};

const DailyPerformance = ({ subProcesses }) => {
    const [filter, setFilter] = useState(moment().isoWeekday());
    const [data, setData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Daily Performance"),
        date: t("Date"),
        ratio: t("Ratio"),
        difference: t("Difference"),
        visitors: t("Visitors Count")
    };

    const generateData = day => {
        if (!_.isEmpty(rawData)) {
            let intervals = _.map(rawData, visitor => {
                return {
                    date: visitor.date,
                    count: visitor.visitor_count,
                    last_30_days_average: visitor.last_30_days_average
                };
            });

            let data = _.map(allDatesRange(), date => {
                let findData = _.find(intervals, interval => moment(interval.date).format(DATE_FORMAT) === date);

                return {
                    date: date,
                    count: findData?.count || 0,
                    last_30_days_average: findData?.last_30_days_average || 0
                };
            });

            let firstDate = moment(_.first(_.orderBy(data, "date")).date);
            let lastDate = moment(_.last(_.orderBy(data, "date")).date);
            let days = [];

            while (isMountedRef.current && firstDate.isoWeekday(7 + day).isSameOrBefore(lastDate)) {
                days.push(firstDate.format("YYYY-MM-DD"));
            }

            let filteredData = _.flatten(
                _.map(days, day => {
                    return _.find(data, item => {
                        return item["date"] === day;
                    });
                })
            );

            let processedData = _.map(filteredData, (item, index) => {
                if (index !== 0) {
                    let date = moment(item["date"]);
                    let beforeItem = filteredData[index - 1];
                    let diff = item["count"] - beforeItem["count"];
                    let finiteRatio = Number.isFinite(diff / item["count"]) ? diff / item["count"] : 0;
                    let ratio = (finiteRatio * 100).toFixed(2);

                    return {
                        label: date.format("DD MMM YYYY"),
                        value: item["count"],
                        displayValue: `${item["count"].readable()} (${ratio}%)`,
                        color: `${diff > 0 ? "#00c9a7" : "#ed4c78"}`,
                        tooltext: `<b>${TRANSLATIONS.date}:</b> ${date.format("DD MMMM YYYY")}<br/>
                               <b>${TRANSLATIONS.visitors}:</b> ${item["count"].readable()}<br/>
                               <b>${TRANSLATIONS.ratio}:</b> ${ratio}%<br/>
                               <b>${TRANSLATIONS.difference}:</b> ${diff.readable()}
                              `
                    };
                }
            });

            // set processed & generated data
            if (isMountedRef.current) setData(processedData.slice(1));
        }
    };

    const fetchData = () => {
        try {
            GetStoreAnalysis({
                sid: getStoreKey(),
                interval: "day",
                average: 1,
                startDate: START_DATE,
                endDate: END_DATE,
                tz: getStoreTimezone(),
                spid: subProcesses,
                rnd: new Date().getTime()
            }).then(data => {
                if (isMountedRef.current) {
                    if (data && !_.isEmpty(data)) {
                        setRawData(data.data.intervals);
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (subProcesses) fetchData();
    }, [isMountedRef, subProcesses]);

    useEffect(() => {
        generateData(filter);
    }, [rawData, filter, isMountedRef]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <Day currentDay={filter} onChange={day => setFilter(day)} loading={loading} />
            </div>
            <div className="card-body pb-1 pl-1">
                {data ? <ReactFC {...chartConfigs(data, TRANSLATIONS)} /> : <DataNotFound className="pb-3 pl-3" />}
            </div>
        </div>
    );
};

export default DailyPerformance;
