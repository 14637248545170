import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";

const CheckboxButtonGroup = ({ items, name, type = "checkbox", selected, error, onChange }) => {
    const { t } = useTranslation();

    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip();

        return () => $('[data-toggle="tooltip"]').tooltip("dispose");
    });

    return (
        <div className="input-group input-group-sm-down-break">
            {items.map(item => (
                <div
                    className={`form-control ${error ? "is-invalid" : ""}`}
                    data-toggle="tooltip"
                    title={item.value}
                    key={item.id}>
                    <div className={`custom-control custom-${type}`}>
                        <input
                            className="custom-control-input"
                            type={type}
                            name={name}
                            id={item.value}
                            onChange={() => onChange(item.id)}
                            checked={selected.indexOf(item.id) > -1}
                        />
                        <label className="custom-control-label" htmlFor={item.value}>
                            <div className="text-truncate">{item.value}</div>
                        </label>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CheckboxButtonGroup;
