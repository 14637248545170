import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

const Title = ({ title }) => {
    const { t } = useTranslation();
    
    return (
        <div>
            <Helmet>
                <title>{title ? `${t(title)} — Cameralyze Dashboard` : `Cameralyze Dashboard`}</title>
            </Helmet>
        </div>
    );
};

export default Title;
