import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import PageHeader from "../../components/App/PageHeader";
import VisitorCard from "../../components/SocialDistanceCounter/VisitorCard";
import HourlyVisitor from "../../components/SocialDistanceCounter/Charts/HourlyVisitor";
import SettingsForm from "../../components/SocialDistanceCounter/SettingsForm";
import Logs from "../../components/SocialDistanceCounter/Logs";
import RequestProvider from "../../components/RequestProvider";
import { ListSubProcesses } from "../../api/services/sub-processes";
import { getAccountInformation } from "../../utils/information";
import { useIsMountedRef } from "../../utils/core";
import { encryptText } from "../../utils/crypto";

const SocialDistanceCounter = () => {
    const [graphData, setGraphData] = useState([]);
    const [subProcesses, setSubProcesses] = useState("");
    const [boxKey, setBoxKey] = useState("");
    const { main_process_id } = useParams();
    const { current_store_id } = getAccountInformation();
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    useEffect(() => {
        ListSubProcesses({
            store_id: current_store_id,
            main_process_id: main_process_id
        }).then(result => {
            if (isMountedRef.current) {
                if (result?.data) {
                    let subProcesses = _.map(result.data, "id").join(",");

                    setSubProcesses(subProcesses);
                    encryptText(
                        JSON.stringify({
                            main_process_id: main_process_id,
                            sub_process_id: subProcesses
                        })
                    ).then(result => setBoxKey(result));
                }
            }
        });
    }, [isMountedRef, main_process_id]);

    return (
        <div className="content container-fluid position-relative">
            <RequestProvider>
                <PageHeader
                    component={() => (
                        <>
                            <Link
                                to={`/box/${boxKey}`}
                                className={`btn btn-primary ${boxKey ? "" : "btn-loading"}`}
                                onClick={() => sessionStorage.setItem("CMZ:BK", boxKey)}>
                                <i className="tio-imac mr-1"></i> {t("SDC Box")}
                            </Link>
                            <button
                                type="button"
                                className="btn btn-icon btn-soft-dark ml-2"
                                data-toggle="modal"
                                data-target="#settingsFormModal">
                                <i className="tio-tune"></i>
                            </button>
                        </>
                    )}>
                    {t("Social Distance Counter")}
                </PageHeader>
                <div className="row">
                    <div className="col-12">
                        <VisitorCard
                            onResponse={data => setGraphData(data)}
                            mainProcess={main_process_id}
                            subProcesses={subProcesses}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-lg-5 mb-3">
                        <HourlyVisitor data={graphData} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Logs />
                    </div>
                </div>
                <SettingsForm />
            </RequestProvider>
        </div>
    );
};

export default SocialDistanceCounter;
