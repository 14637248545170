import React from "react";
import { useTranslation } from "react-i18next";

const PageHeader = ({
    children,
    backButton,
    backText = "Go back",
    backClick,
    description,
    component: Component = () => null
}) => {
    const { t } = useTranslation();

    return (
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col-sm">
                    {backButton && (
                        <p className="page-header-text btn-link text-success cursor-pointer" onClick={backClick}>
                            <i className="tio-reply mr-1"></i>
                            {t(backText)}
                        </p>
                    )}
                    {description && <p className="page-header-text mb-0">{description}</p>}
                    <h1 className="page-header-title mb-0">{children}</h1>
                </div>
                {Component() && (
                    <div className="col-sm-auto mt-2 mt-sm-0">
                        <Component />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PageHeader;
