import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import DateRange from "../DataFilter/DateRange";
import { useIsMountedRef } from "../../utils/core";
import DataNotFound from "../State/DataNotFound";
import { GetRealTimeAnalyticsLogs } from "../../api/services/social-distance-counter";
import { getAccountInformation } from "../../utils/information";

const generateLogText = ratio => {
    if (ratio >= 100) {
        return {
            text: "Danger",
            icon: "tio-report",
            color: "text-danger",
            fullnessIcon: "tio-battery-horizontal"
        };
    } else if (ratio >= 80) {
        return {
            text: "Warning",
            icon: "tio-warning",
            color: "text-warning",
            fullnessIcon: "tio-battery-horizontal-half"
        };
    }
};

const Logs = () => {
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState("");
    const [logs, setLogs] = useState([]);
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();
    const { main_process_id } = useParams();
    const { current_store_id } = getAccountInformation();

    const handleStart = date => setStartDate(moment(date));
    const handleEnd = date => setEndDate(moment(date));
    const handleSubmit = () => {
        if (startDate && endDate && endDate >= startDate) {
            setErrors("");
            setLoading(true);
            fetchData();
        } else {
            setErrors("is-invalid");
        }
    };

    const fetchData = () => {
        GetRealTimeAnalyticsLogs({
            main_process_id: main_process_id,
            store_id: current_store_id,
            start_date: startDate.startOf("day").toISOString(),
            end_date: endDate.endOf("day").toISOString()
        }).then(result => {
            if (isMountedRef.current) {
                if (result?.data) {
                    let data = _.orderBy(result.data, "fullness_ratio", ["desc"]);

                    setLogs(data);
                } else {
                    setLogs([]);
                }
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, [isMountedRef]);

    return (
        <span className="card">
            <div className="card-header">
                <h4 className="card-header-title">{t("Social Distance Counter Logs")}</h4>
                <DateRange
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    maxDate={moment().toDate()}
                    handleStart={handleStart}
                    handleEnd={handleEnd}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    className={errors}
                />
            </div>
            <span className="card-body pb-1">
                <span className="row">
                    {logs && !_.isEmpty(logs) ? (
                        logs.map((log, key) => (
                            <span className="col-sm-6 mb-3" key={key}>
                                <span className="card">
                                    <div className="card-header">
                                        <h4
                                            className={`card-header-title mr-2 ${
                                                generateLogText(log.fullness_ratio).color
                                            }`}>
                                            <i className={generateLogText(log.fullness_ratio).icon}></i>{" "}
                                            {t(generateLogText(log.fullness_ratio).text)}
                                        </h4>
                                        {moment(log.created_at).format("ddd, LLL")}
                                    </div>
                                    <span className="card-body">
                                        <ul className="list-group">
                                            <li className="list-group-item d-flex align-items-center flex-column flex-md-row">
                                                <div className="font-weight-bold">
                                                    <i className="list-group-icon tio-battery-horizontal-3-quarters"></i>
                                                    {t("Capacity")}:
                                                </div>
                                                <span className="ml-md-auto mt-md-0 mt-2">{log.capacity}</span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center flex-column flex-md-row">
                                                <div className="font-weight-bold">
                                                    <i
                                                        className={`list-group-icon ${
                                                            generateLogText(log.fullness_ratio).fullnessIcon
                                                        }`}></i>
                                                    {t("Fullness Ratio")}:
                                                </div>
                                                <span className="ml-md-auto mt-md-0 mt-2">%{log.fullness_ratio}</span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center flex-column flex-md-row">
                                                <div className="font-weight-bold">
                                                    <i className="list-group-icon tio-group-senior"></i>
                                                    {t("Customer Count")}:
                                                </div>
                                                <span className="ml-md-auto mt-md-0 mt-2">
                                                    {Math.round((log.capacity * log.fullness_ratio) / 100)}
                                                </span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center flex-column flex-md-row">
                                                <div className="font-weight-bold">
                                                    <i className="tio-time list-group-icon"></i>
                                                    {t("Duration")}:
                                                </div>
                                                <span className="ml-md-auto mt-md-0 mt-2">
                                                    {log.duration.duration()}
                                                </span>
                                            </li>
                                        </ul>
                                    </span>
                                </span>
                            </span>
                        ))
                    ) : (
                        <div className="col-12">
                            <DataNotFound className="pb-3" />
                        </div>
                    )}
                </span>
            </span>
        </span>
    );
};

export default Logs;
