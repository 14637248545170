import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";

import CMZUnfold from "../../assets/vendor/unfold/unfold";
import Logo from "../../components/Logo";
import { getAccountInformation, fullnameGenerator, avatarPlaceholder, getMenus } from "../../utils/information";
import LanguageSwitcher from "../LanguageSwitcher";
import SelectStore from "./Navbar/SelectStore";

const Navbar = () => {
    const { name, surname, email, picture } = getAccountInformation();
    const { t } = useTranslation();
    const profileMenus = [
        {
            type: "divider"
        },
        {
            type: "menu",
            to: "/account/profile",
            name: "Profile & Account",
            icon: "tio-user"
        },
        {
            type: "divider"
        },
        ..._.filter(getMenus(), menu => [20, 36].indexOf(menu.id) > -1),
        {
            type: "divider"
        },
        {
            type: "menu",
            to: "/auth/login",
            name: "Sign out",
            icon: "tio-sign-out",
            props: {
                onClick: () => {
                    localStorage.removeItem("CMZ:L");
                    localStorage.removeItem("CMZ:T");
                    localStorage.removeItem("CMZ:R");
                }
            }
        }
    ];

    useEffect(() => {
        $(".js-navbar-vertical-aside-toggle-invoker").on("click", function() {
            $(".js-navbar-vertical-aside-toggle-invoker i").tooltip("hide");
        });

        $(".js-cmz-unfold-invoker").each(function() {
            if ($(this).attr("data-cmz-unfold-invoker") === undefined) {
                new CMZUnfold($(this)).init();
            }
        });

        return () => {
            $(".js-navbar-vertical-aside-toggle-invoker").off("click");
        };
    });

    return (
        <header
            id="header"
            className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered">
            <div className="navbar-nav-wrap">
                <div className="navbar-brand-wrapper order-0">
                    <Link className="navbar-brand" to="/" aria-label="Cameralyze">
                        <Logo className="navbar-brand-logo" />
                        <Logo className="navbar-brand-logo-mini" mini={true} />
                    </Link>
                </div>
                <button type="button" className="js-navbar-vertical-aside-toggle-invoker close mr-3 order-1">
                    <i
                        className="tio-first-page navbar-vertical-aside-toggle-short-align"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Collapse"
                    />
                    <i
                        className="tio-menu-hamburger navbar-vertical-aside-toggle-full-align"
                        data-template='<div class="tooltip d-none d-sm-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Expand"
                    />
                </button>

                <div className="navbar-nav-wrap-content-right order-2 order-md-3">
                    <ul className="navbar-nav align-items-center flex-row">
                        <li className="nav-item">
                            <LanguageSwitcher />
                        </li>
                        <li className="nav-item">
                            <div className="cmz-unfold">
                                <div
                                    className="js-cmz-unfold-invoker navbar-dropdown-account-wrapper"
                                    data-cmz-unfold-options='{"target": "#accountNavbarDropdown","type": "css-animation"}'>
                                    <div className="avatar avatar-sm avatar-soft-secondary avatar-circle">
                                        {picture ? (
                                            <img
                                                className="avatar-img"
                                                src={picture}
                                                alt={avatarPlaceholder(name, surname)}
                                            />
                                        ) : (
                                            <span className="avatar-initials">{avatarPlaceholder(name, surname)}</span>
                                        )}
                                        <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                                    </div>
                                </div>
                                <div
                                    id="accountNavbarDropdown"
                                    className="cmz-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account"
                                    style={{ width: "16rem" }}>
                                    <div className="dropdown-item">
                                        <div className="media align-items-center">
                                            <div className="avatar avatar-sm avatar-soft-dark avatar-circle mr-2">
                                                {picture ? (
                                                    <img
                                                        className="avatar-img"
                                                        src={picture}
                                                        alt={avatarPlaceholder(name, surname)}
                                                    />
                                                ) : (
                                                    <span className="avatar-initials">
                                                        {avatarPlaceholder(name, surname)}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="media-body overflow-hidden">
                                                <span className="card-title h5 text-truncate">
                                                    {fullnameGenerator(name, surname)}
                                                </span>
                                                <span className="card-text text-truncate">{email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {profileMenus.map((menu, key) => {
                                        if (menu.type === "divider") {
                                            return <div key={key} className="dropdown-divider"></div>;
                                        } else {
                                            return (
                                                <Link key={key} className="dropdown-item" to={menu.to} {...menu.props}>
                                                    <span className="text-truncate pr-2" title={t(menu.name)}>
                                                        <i className={`dropdown-item-icon ${menu.icon}`}></i>
                                                        {t(menu.name)}
                                                    </span>
                                                </Link>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="navbar-nav-wrap-content-left w-100 w-md-auto pb-3 pb-md-0 order-3 order-md-2">
                    <SelectStore />
                </div>
            </div>
        </header>
    );
};

export default Navbar;
