import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import _ from "lodash";

import Default from "../../Buttons/Default";
import Text from "../../Forms/Text";
import { UpdateProfile } from "../../../api/services/account";
import { getAccessToken } from "../../../utils/information";

const BasicInformation = ({ data }) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const onSubmit = data => {
        const { name, surname, phone_number } = data;
        setLoading(true);
        UpdateProfile({
            attributes: {
                name,
                surname,
                phone_number: phone_number
                    .split(" ")
                    .join("")
                    .replace("(", "")
                    .replace(")", "")
            },
            access_token: getAccessToken()
        }).then(result => {
            const status = result.status;

            if (status === 200) {
                Swal.fire({
                    title: "Successfull",
                    text: t(result.message),
                    icon: "success"
                });
            } else {
                Swal.fire({
                    title: `${t("Error code")}: ${status}`,
                    text: t(result.message),
                    icon: "error"
                });
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (data && !_.isEmpty(data)) {
            setValue("name", data.name);
            setValue("surname", data.surname);
            setValue("email", data.email);
            setValue("phone_number", data.phone_number);
            setLoading(false);
        }
    }, [data]);

    return (
        <div className="card mb-3 mb-lg-5">
            <div className="card-header">
                <h2 className="card-title h4">{t("Basic information")}</h2>
            </div>
            <form className="card-body" onSubmit={handleSubmit(onSubmit)}>
                <div className="row form-group">
                    <label htmlFor="email" className="col-sm-3 col-form-label input-label">
                        {t("Email")}
                    </label>
                    <div className="col-sm-9">
                        <Text ref={register} className="bg-light" name="email" id="email" readOnly disabled />
                    </div>
                </div>
                <div className="row form-group">
                    <label htmlFor="name" className="col-sm-3 col-form-label input-label">
                        {t("Full name")}
                    </label>
                    <div className="col-sm-9">
                        <div className="input-group input-group-sm-down-break">
                            <Text
                                ref={register({ required: true, minLength: 2 })}
                                name="name"
                                id="name"
                                placeholder={t("First name")}
                                className={errors.name ? "is-invalid" : ""}
                            />
                            <Text
                                ref={register({ required: true, minLength: 2 })}
                                name="surname"
                                id="surname"
                                placeholder={t("Last name")}
                                className={errors.surname ? "is-invalid" : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className="row form-group">
                    <label htmlFor="phone_number" className="col-sm-3 col-form-label input-label">
                        {t("Phone")} <span className="input-label-secondary">({t("Optional")})</span>
                    </label>
                    <div className="col-sm-9">
                        <MaskedInput
                            mask={[
                                "+",
                                /\d/,
                                /\d/,
                                " ",
                                "(",
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ")",
                                " ",
                                /\d/,
                                /\d/,
                                /\d/,
                                " ",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/
                            ]}
                            ref={ref =>
                                ref &&
                                register(ref.inputElement, {
                                    minLength: 18
                                })
                            }
                            guide={false}
                            type="text"
                            keepCharPositions={true}
                            placeholder="+90 (555) 123 4567"
                            className={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                            name="phone_number"
                            id="phone_number"
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <Default loading={loading}>{t("Save changes")}</Default>
                </div>
            </form>
        </div>
    );
};

export default BasicInformation;
