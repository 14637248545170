import React from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const chartConfigs = (data, translations) => ({
    type: "msline",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(false),
            ...fusionChartExportDefaults(translations.chart),
            showValues: "0",
            showLegend: "1",
            plotColorinTooltip: 0,
            xAxisname: translations.hour,
            yAxisname: translations.count
        },
        // Chart Data
        ...data
    }
});

const HourlyVisitor = ({ data }) => {
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Hourly Customer Chart"),
        entry: t("Store Entry"),
        exit: t("Store Exit"),
        hour: t("Hour"),
        count: t("Visitors Count")
    };

    data = {
        categories: [
            {
                category: _.map(data, item => {
                    return {
                        label: moment(item.hour, "H").format("HH:00")
                    };
                })
            }
        ],
        dataset: [
            {
                seriesname: TRANSLATIONS.entry,
                color: "#00c9a7",
                data: _.map(data, item => {
                    let value = item.in;

                    return {
                        value: value,
                        tooltext: `
                            <div style="font-size: 110%">
                                ${moment(item.hour, "H").format("HH:00")}
                            </div><br/>
                            <b><span class="legend-indicator bg-success"></span>
                            ${TRANSLATIONS.entry}:</b> ${value.readable()}
                        `
                    };
                })
            },
            {
                seriesname: TRANSLATIONS.exit,
                color: "#ed4c78",
                data: _.map(data, item => {
                    let value = item.out;

                    return {
                        value: value,
                        tooltext: `
                            <b><span class="legend-indicator bg-danger"></span>
                            ${TRANSLATIONS.exit}:</b> ${value.readable()}
                        `
                    };
                })
            }
        ]
    };

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
            </div>
            <div className="card-body pb-1 pl-1">
                {data && !_.isEmpty(data) && <ReactFC {...chartConfigs(data, TRANSLATIONS)} />}
            </div>
        </div>
    );
};

export default HourlyVisitor;
