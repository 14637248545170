import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Default from "../Buttons/Default";

/**
 *
 * @param {string} [dateFormat=yyyy-MM-dd]: Picker format
 * @param {string} startDate: Start Date
 * @param {string} endDate: End Date
 * @param {string} minDate: Min. Date
 * @param {string} maxDate: Max. Date
 * @param {function} handleStart: Start picker handle on change
 * @param {function} handleEnd: End picker handle on change
 * @param {function} handleSubmit: Picker submit button handle on click
 * @param {boolean} [time=false]: Picker added time field [true/false]
 * @param {string} [placement=bottom-end]: Picker popover placement ["auto", "auto-left", "auto-right", "bottom", "bottom-end", "bottom-start", "left", "left-end", "left-start", "right", "right-end", "right-start", "top", "top-end", "top-start"]
 * @param {string} [size=sm]: Picker input size [sm, md, lg]
 * @param {boolean} loading: Picker submit button loading state [true/false]
 * @param {string} className: Picker input's extra class
 */
const DateRange = ({
    dateFormat = "yyyy-MM-dd",
    startDate,
    endDate,
    minDate,
    maxDate,
    handleStart,
    handleEnd,
    handleSubmit,
    time = false,
    placement = "bottom-end",
    size = "sm",
    loading = false,
    className,
    props
}) => {
    const { t } = useTranslation();

    return (
        <div className="row gx-1 mt-2 mt-sm-0">
            <div className="col">
                <DatePicker
                    dateFormat={dateFormat}
                    className={`form-control form-control-${size} ${className}`}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleStart}
                    selectsStart
                    showTimeInput={time}
                    timeInputLabel={t("Time")}
                    placeholderText={startDate ? moment(startDate).format("YYYY-MM-DD") : ""}
                    popperPlacement={placement}
                    disabledKeyboardNavigation
                    {...props}
                />
            </div>
            <div className="col">
                <DatePicker
                    dateFormat={dateFormat}
                    className={`form-control form-control-${size} ${className}`}
                    selected={endDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleEnd}
                    selectsEnd
                    showTimeInput={time}
                    timeInputLabel={t("Time")}
                    placeholderText={endDate ? moment(endDate).format("YYYY-MM-DD") : ""}
                    popperPlacement={placement}
                    disabledKeyboardNavigation
                    {...props}
                />
            </div>
            <div className="col-auto">
                <Default className="btn-sm" color="secondary" onClick={handleSubmit} loading={loading}>
                    <i className="tio-search"></i>
                </Default>
            </div>
        </div>
    );
};

export default DateRange;
