import React from "react";

const Loading = ({ loading }) => {
    return (
        <>
            {loading && (
                <div className="position-absolute top-0 left-0 right-0 bottom-0 bg-white z-index-2 d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default Loading;
