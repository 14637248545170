import React from "react";
import { useTranslation } from "react-i18next";

const ChartsScrollView = ({ onChange }) => {
    const { t } = useTranslation();

    return (
        <div className="alert alert-soft-dark card-alert text-left" role="alert">
            <div className="form-check-inline ml-2">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        id={`scrollView-${new Date().getTime()}`}
                        className="custom-control-input"
                        defaultChecked
                        onChange={onChange}
                    />
                    <label className="custom-control-label" htmlFor={`scrollView-${new Date().getTime()}`}>
                        {t("Turn on the scroll feature to view the chart in a larger view.")}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ChartsScrollView;
