import React, { useState, useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";
import $ from "jquery";
import moment from "moment";
import "moment/min/locales";

import CMZUnfold from "../assets/vendor/unfold/unfold";
import i18n, { getLanguage } from "../utils/i18n";
import { GetLanguages } from "../api/services/translations";
import trIcon from "../assets/vendor/flag-icon-css/flags/1x1/tr-TR.svg";
import enIcon from "../assets/vendor/flag-icon-css/flags/1x1/en-US.svg";
import { useIsMountedRef } from "../utils/core";

registerLocale("tr-TR", tr);
registerLocale("en-US", en);
moment.defaultFormat = "YYYY-MM-DD";

const LANGUAGE_FLAG = {
    "tr-TR": trIcon,
    "en-US": enIcon
};

const setMomentLocale = lang => {
    let splitted = lang.split("-")[0];

    moment.locale(splitted, {
        week: {
            dow: 1
        }
    });
};

const LanguageSwitcher = () => {
    const [languages, setLanguages] = useState([]);
    const [language, setLanguage] = useState("");
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const changeLanguage = language => {
        if (language !== getLanguage()) {
            setLanguage(language);
            setDefaultLocale(language);
            setMomentLocale(language);

            i18n.changeLanguage(language);
            window.location.reload();
        }
    };

    const DropdownItem = language => {
        return (
            <button
                type="button"
                className="dropdown-item"
                onClick={() => changeLanguage(language.code)}
                key={language.id}>
                <img
                    className="avatar avatar-xss avatar-circle mr-2"
                    src={LANGUAGE_FLAG[language.code]}
                    alt={`${language.name} Flag`}
                />
                <span className="text-truncate pr-2" title={language.name}>
                    {language.name}
                </span>
            </button>
        );
    };

    useEffect(() => {
        $(".js-cmz-unfold-invoker").each(function() {
            if ($(this).attr("data-cmz-unfold-invoker") === undefined) {
                new CMZUnfold($(this)).init();
            }
        });
    }, []);

    useEffect(() => {
        setMomentLocale(getLanguage());

        GetLanguages().then(result => {
            if (isMountedRef.current) {
                setLanguages(result || []);
                setLanguage(getLanguage());
                setDefaultLocale(getLanguage());
            }
        });
    }, [isMountedRef]);

    return (
        <div className="cmz-unfold">
            <div
                className="js-cmz-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                data-cmz-unfold-options='{"target": "#languageDropdown","type": "css-animation"}'>
                <img
                    className="avatar avatar-xss avatar-circle"
                    src={LANGUAGE_FLAG[language]}
                    alt={languages.find(lng => lng.code === language)?.name || ""}
                />
            </div>
            <div
                id="languageDropdown"
                className="cmz-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account">
                <span className="dropdown-header">{t("Select language")}</span>
                {languages.map(language => DropdownItem(language))}
            </div>
        </div>
    );
};

export default LanguageSwitcher;
