import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import CustomSelect from "../../DataFilter/CustomSelect";
import DataNotFound from "../../State/DataNotFound";
import { GetStoreAnalysis } from "../../../api/services/store-analysis";
import { getStoreKey, getStoreTimezone } from "../../../utils/information";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const chartConfigs = (data, translations) => ({
    type: "column2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(false),
            ...fusionChartExportDefaults(translations.chart),
            showPercentValues: "1",
            paletteColors: "#00c9a7",
            xAxisname: translations.day,
            yAxisname: translations.visitors
        },
        // chart Data
        data: data
    }
});

const MostIntenseDay = ({ subProcesses }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dates, setDates] = useState();
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Most Intense Day"),
        day: t("Day"),
        visitors: t("Visitors Count")
    };

    const generateToolText = (text, day, value) => `
        <div style="font-size: 110%;margin-bottom:5px">${t(text)}</div>
        <b>${TRANSLATIONS.day}:</b> ${moment.weekdays(day)}<br/>
        <b>${TRANSLATIONS.visitors}:</b> ${value.readable()}
    `;

    const fetchData = () => {
        try {
            const [startDate, endDate] = dates;
            setLoading(true);

            GetStoreAnalysis({
                sid: getStoreKey(),
                interval: "days",
                startDate: startDate,
                endDate: endDate,
                tz: getStoreTimezone(),
                spid: subProcesses,
                rnd: new Date().getTime()
            }).then(data => {
                if (isMountedRef.current) {
                    if (!_.isEmpty(data?.data?.day_of_weeks)) {
                        let dayOfWeeks = data.data.day_of_weeks;
                        let visitors = _.map(_.range(1, 8), day => {
                            let weekOfDay = _.find(dayOfWeeks, item => item.weekOfDay === day);
                            let value = weekOfDay && !_.isArray(weekOfDay.visitor_count) ? weekOfDay.visitor_count : 0;

                            return {
                                label: moment.weekdaysShort(day),
                                value: value,
                                day: day,
                                color: "#71869d",
                                tooltext: generateToolText("Average Zone", day, value)
                            };
                        });

                        // Peak Zone coloring
                        let firstPeakZone = _.maxBy(visitors, "value");
                        let secondPeakZone = _.maxBy(
                            _.filter(visitors, item => item.label !== firstPeakZone.label),
                            "value"
                        );

                        firstPeakZone.color = "#00c9a7";
                        secondPeakZone.color = "#00c9a7";
                        firstPeakZone.tooltext = generateToolText("Peak Zone", firstPeakZone.day, firstPeakZone.value);
                        secondPeakZone.tooltext = generateToolText(
                            "Peak Zone",
                            secondPeakZone.day,
                            secondPeakZone.value
                        );

                        setData(visitors);
                    } else {
                        setData(null);
                    }
                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (dates && subProcesses) fetchData();
    }, [dates, isMountedRef, subProcesses]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                <CustomSelect
                    options={[
                        {
                            id: 0,
                            name: "This Week",
                            interval: [moment().startOf("isoweek"), moment().endOf("isoweek")]
                        },
                        {
                            id: 1,
                            name: "This Month",
                            interval: [moment().startOf("month"), moment().endOf("month")]
                        },
                        {
                            id: 2,
                            name: "This Quarter",
                            interval: [
                                moment().quarter(moment().quarter()).startOf("quarter"),
                                moment().quarter(moment().quarter()).endOf("quarter")
                            ]
                        },
                        {
                            id: 3,
                            name: "This Year",
                            interval: [moment().startOf("year"), moment().endOf("year")]
                        }
                    ]}
                    selected={0}
                    onChange={interval => setDates(interval)}
                    loading={loading}
                />
            </div>
            <div className="card-body pb-1 pl-1">
                {data ? <ReactFC {...chartConfigs(data, TRANSLATIONS)} /> : <DataNotFound className="pb-3 pl-3" />}
            </div>
        </div>
    );
};

export default MostIntenseDay;
