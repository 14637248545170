import React from "react";
import { useTranslation } from "react-i18next";

const CardCheckbox = ({ id, title, name, selected, buttonText, description, icon, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className={`js-custom-checkbox-card card custom-checkbox-card ${selected === id ? "checked" : ""}`}>
            <div className="card-body text-center">
                <input
                    type="radio"
                    id={name + id}
                    name={name}
                    className="js-custom-checkbox-card-input custom-checkbox-card-input"
                    onChange={() => onChange(id)}
                    checked={selected === id}
                />
                <label
                    className="custom-checkbox-card-label custom-checkbox-card-label-stretched"
                    htmlFor={name + id}></label>
                <div className="icon icon-lg icon-dark icon-circle my-4">
                    <i className={icon}></i>
                </div>
                <h3 className="mb-1">{t(title)}</h3>
                {description && <p className="text-muted font-size-sm mb-0">{t(description)}</p>}
            </div>
            <div className="card-body pt-2">
                <button type="button" className="btn btn-sm btn-block btn-white custom-checkbox-card-btn">
                    {selected === id ? t(`Selected ${buttonText}`) : t(`Select ${buttonText}`)}
                </button>
            </div>
        </div>
    );
};

export default CardCheckbox;
