import React, { useState } from "react";
import axios from "axios";

import { SESSION_START } from "../api/constants";

const RequestProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    axios.interceptors.request.use(
        config => {
            if (config.url.indexOf(SESSION_START) === -1) {
                setLoading(true);
            }

            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => {
            setLoading(false);

            return response;
        },
        error => Promise.reject(error)
    );

    return (
        <>
            {loading && <div className="position-fixed top-0 left-0 right-0 bottom-0 bg-white opacity z-index-2"></div>}
            {children}
        </>
    );
};

export default RequestProvider;
