import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import moment from "moment";
import _ from "lodash";

import "../../utils/datatables-settings";
import PageHeader from "../../components/App/PageHeader";
import Default from "../../components/Buttons/Default";
import CreateModal from "../../components/Account/ExportReport/CreateModal";
import { LIST_EXPORT_REPORTS, API_URL } from "../../api/constants";
import { DownloadReport } from "../../api/services/export-report";
import { getAccessToken, getAccountInformation, formatDate, getStoreTimezone } from "../../utils/information";
import { captureException } from "../../utils/handlers";

$.DataTable = require("datatables.net-responsive");

const ExportReport = () => {
    const tableEl = useRef();
    const { t } = useTranslation();
    const { user_id, current_store_id } = getAccountInformation();
    const [reload, setReload] = useState(false);
    const REQUEST_DURATION = 1000 * 5; // ms * sec

    useEffect(() => {
        const table = $(tableEl.current).DataTable({
            order: [0, "desc"],
            aLengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, t("All")]
            ],
            processing: true,
            ajax: {
                url: API_URL + LIST_EXPORT_REPORTS,
                type: "POST",
                datatype: "json",
                contentType: "application/json",
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", getAccessToken());
                },
                data: function (d) {
                    return JSON.stringify({
                        user_id: user_id,
                        store_id: current_store_id
                    });
                },
                dataSrc: function (d) {
                    if (d.status !== 200) {
                        return [];
                    } else {
                        if (d.data.filter(item => !item.report_path).length > 0) setReload(true);
                        else setReload(false);

                        return _.orderBy(d.data, ["id", "desc"]);
                    }
                }
            },
            columnDefs: [
                ...$.fn.dataTable.defaults.columnDefs,
                {
                    targets: "status",
                    createdCell: (td, cellData, { status, report_path }) => {
                        ReactDOM.render(
                            <>
                                {report_path && status === 1 ? (
                                    <Default
                                        className="download-button"
                                        color="success"
                                        onClick={event => {
                                            let that = $(event.target);
                                            that.addClass("btn-loading");

                                            DownloadReport(report_path).then(response => {
                                                if (response && response.status !== 500) window.open(response.data);

                                                that.removeClass("btn-loading");
                                            });

                                            table.draw();
                                        }}>
                                        <i className="fa fa-download"></i> {t("Download")}
                                    </Default>
                                ) : (
                                    <button className="btn btn-warning" disabled>
                                        <span
                                            className="spinner-border spinner-border-sm mr-2"
                                            role="status"
                                            aria-hidden="true"></span>
                                        {t("Preparing")}
                                    </button>
                                )}
                            </>,
                            td
                        );
                    }
                }
            ],
            columns: [
                {
                    data: "id",
                    responsivePriority: 3,
                    render: function (data, type, row, meta) {
                        return meta.row + 1;
                    }
                },
                {
                    data: "store",
                    responsivePriority: 0
                },
                {
                    data: "process_name",
                    responsivePriority: 2
                },
                {
                    data: "start_date",
                    responsivePriority: 3,
                    render: function (data) {
                        return formatDate(data);
                    }
                },
                {
                    data: "end_date",
                    responsivePriority: 3,
                    render: function (data) {
                        return formatDate(data);
                    }
                },
                {
                    data: "created_at",
                    responsivePriority: 4,
                    render: function (data, type) {
                        return formatDate(
                            moment(data).add(getStoreTimezone(), "hours"),
                            type === "sort" ? "YYYY-MM-DD HH:mm:ss" : "DD MMMM YYYY HH:mm:ss"
                        );
                    }
                },
                {
                    data: "status",
                    class: "text-center",
                    responsivePriority: 1
                }
            ]
        });

        table.on("error.dt", function (e, settings, techNote, message) {
            captureException("An error has been reported by DataTables", message);
        });

        table.on("draw.dt", function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        let dataTableInverval = setInterval(() => {
            if (reload) table.ajax.reload();
        }, REQUEST_DURATION);

        return () => {
            $(".data-table-wrapper").find("table").DataTable().destroy(true);
            clearInterval(dataTableInverval);
        };
    }, [reload]);

    return (
        <div className="content container-fluid">
            <PageHeader
                component={() => (
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#createReportModal">
                        <i className="fa fa-plus-square"></i> {t("Create")}
                    </button>
                )}>
                {t("Export Reports")}
            </PageHeader>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">{t("Report List")}</h4>
                        </div>
                        <div className="datatable-custom">
                            <table
                                ref={tableEl}
                                className="table table-bordered table-nowrap table-align-middle card-table w-100">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="id">#</th>
                                        <th className="no-sort">{t("Store")}</th>
                                        <th className="no-sort">{t("Process Name")}</th>
                                        <th>{t("Start Date")}</th>
                                        <th>{t("End Date")}</th>
                                        <th>{t("Created At")}</th>
                                        <th className="status no-sort"></th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <CreateModal
                onCreated={() => {
                    $(tableEl.current).DataTable().ajax.reload();
                    setReload(true);
                }}
            />
        </div>
    );
};

export default ExportReport;
