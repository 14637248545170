import axios from "axios";

import "../defaults";
import { GET_LANGUAGES, GET_TRANSLATIONS } from "../constants";
import { captureException } from "../../utils/handlers";

export const GetLanguages = () => {
    try {
        return axios(GET_LANGUAGES)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error);
            });
    } catch (error) {}
};

export const GetTranslations = () => {
    try {
        return axios(GET_TRANSLATIONS)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error);
            });
    } catch (error) {}
};
