import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

import { selectCustomStyles } from "../../../utils/react-select";
import Text from "../../Forms/Text";
import { ListCameras } from "../../../api/services/cameras";
import { getAccountInformation } from "../../../utils/information";
import { useIsMountedRef } from "../../../utils/core";
import { CreateSubProcess, UpdateSubProcess } from "../../../api/services/sub-processes";
import Default from "../../Buttons/Default";

const Modal = ({ id, name, capacity, warning_limit, camera_id, message }) => {
    const [cameras, setCameras] = useState([]);
    const [loading, setLoading] = useState({
        cameras: true,
        create_button: false
    });
    const [disabledCameras, setDisabledCameras] = useState(false);
    const { t } = useTranslation();
    const { current_store_id } = getAccountInformation();
    const { main_process_id } = useParams();
    const isMountedRef = useIsMountedRef();
    const { register, control, errors, handleSubmit, setValue, getValues } = useForm();

    const onSubmit = ({ name, warning_limit, capacity, cameras, message }) => {
        setLoading(prevState => ({ ...prevState, create_button: true }));

        let requestData = {
            store_id: current_store_id,
            main_process_id: parseInt(main_process_id),
            name: name,
            camera_id: cameras.value,
            settings: {
                warning_limit: warning_limit,
                capacity: capacity,
                camera_id: cameras.value,
                message: message
            }
        };

        if (id) {
            UpdateSubProcess({ ...requestData, sub_process_id: id }).then(result => {
                if (result?.status === 200) window.location.reload();
                else setLoading(prevState => ({ ...prevState, create_button: false }));
            });
        } else {
            CreateSubProcess(requestData).then(result => {
                if (result?.status === 200) window.location.reload();
                else setLoading(prevState => ({ ...prevState, create_button: false }));
            });
        }
    };

    const fetchCameras = () => {
        ListCameras({ store_id: current_store_id }).then(result => {
            if (isMountedRef.current) {
                if (result.status === 200) {
                    let data = result?.data?.map(camera => ({
                        value: camera.id,
                        label: camera.name
                    }));

                    setCameras(data);
                }

                setLoading(prevState => ({ ...prevState, cameras: false }));
            }
        });
    };

    useEffect(() => {
        fetchCameras();
    }, [isMountedRef]);

    useEffect(() => {
        let camera = cameras.find(camera => camera.value === camera_id) || null;

        if (camera) setDisabledCameras(true);
        else setDisabledCameras(false);

        setValue("name", name);
        setValue("capacity", capacity);
        setValue("warning_limit", warning_limit);
        setValue("cameras", camera);
        setValue("message", message)
    }, [id]);

    return (
        <div
            className="modal fade"
            id="queueOptimizerModal"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="queueOptimizerModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="queueOptimizerModalLabel">
                            {t("Create Queue Area")}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-xs btn-icon btn-ghost-dark"
                            data-dismiss="modal"
                            aria-label="Close">
                            <i className="tio-clear tio-lg"></i>
                        </button>
                    </div>
                    <form className="modal-body" id="queueOptimizerForm" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name">{t("Name")}</label>
                                    <Text
                                        ref={register({
                                            required: true
                                        })}
                                        id="name"
                                        name="name"
                                        className={errors.name ? "is-invalid" : ""}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-0">
                                    <label htmlFor="camera">{t("Select Camera")}</label>
                                    <Controller
                                        name="cameras"
                                        placeholder={t("Select...")}
                                        options={cameras}
                                        control={control}
                                        as={Select}
                                        rules={{ required: true }}
                                        styles={selectCustomStyles}
                                        isLoading={loading.cameras}
                                        isDisabled={!disabledCameras ? loading.cameras : disabledCameras}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-0">
                                    <label htmlFor="warning_limit">{t("Warning Limit")}</label>
                                    <Text
                                        ref={register({
                                            required: true,
                                            valueAsNumber: true,
                                            validate: () =>
                                                Number(getValues("warning_limit")) <= Number(getValues("capacity"))
                                        })}
                                        type="number"
                                        id="warning_limit"
                                        name="warning_limit"
                                        className={errors.warning_limit ? "is-invalid" : ""}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="capacity">{t("Capacity")}</label>
                                    <Text
                                        ref={register({
                                            required: true,
                                            valueAsNumber: true
                                        })}
                                        type="number"
                                        id="capacity"
                                        name="capacity"
                                        className={errors.capacity ? "is-invalid" : ""}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name">{t("Text Message Body")}</label>
                                    <Text
                                        ref={register({
                                            required: true
                                        })}
                                        id="message"
                                        name="message"
                                        className={errors.name ? "is-invalid" : ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">
                            {t("Close")}
                        </button>
                        <Default form="queueOptimizerForm" loading={loading.create_button}>
                            {t("Create")}
                        </Default>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
