import _ from "lodash";

export const selectCustomStyles = {
    option: (styles, { data, isSelected }) => ({
        ...styles,
        cursor: "pointer",
        fontWeight: data.value === "all" ? "bold" : "normal",
        backgroundColor: isSelected ? "rgba(0, 201, 167, 0.8)" : "#fff",
        color: isSelected ? "#fff" : "inherit",
        ":hover": {
            backgroundColor: "rgba(0, 201, 167, 0.4)"
        },
        ":active": {
            backgroundColor: "rgba(0, 201, 167, 0.4)"
        }
    }),
    control: (styles, state) => ({
        ...styles,
        width: "100%",
        height: `calc(1.6em + 1.21875rem)`,
        borderColor: state.isFocused ? "rgba(0, 201, 167, 0.4)" : "#e7eaf3",
        borderWidth: "0.0625rem",
        borderRadius: "0.3125rem",
        boxShadow: state.isFocused ? "0 0 10px rgba(0, 201, 167, 0.1)" : "",
        ":hover": {
            borderColor: state.isFocused ? "rgba(0, 201, 167, 0.4)" : "#00c9a7",
            cursor: "pointer"
        }
    }),
    valueContainer: styles => ({ ...styles, height: "100%" }),
    input: styles => ({ ...styles, position: "absolute" }),
    singleValue: styles => _.omit(styles, ["maxWidth", "position", "transform"]),
    menu: styles => ({
        ...styles,
        zIndex: "99999",
        whiteSpace: "nowrap",
        width: "auto",
        minWidth: "100%",
        left: 0
    })
};
