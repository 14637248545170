import React from "react";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {string} title Card Title
 * @param {integer} [count=0] Card Value
 * @param {function} trending up/down
 * @param {string} [icon=tio-evet] Card Icon
 * @param {color} [color=primary] Icon Bg Color
 */
const Card = ({
    title,
    trending: Component = () => null,
    count = 0,
    icon = "tio-event",
    color = "primary",
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <div className="card card-hover-shadow h-100 cursor-pointer">
            <div className="card-body">
                <div className="row g-0">
                    <div className="col">
                        <h6 className="card-subtitle">{t(title)}</h6>
                        <span className="card-title h2">{count}</span>
                        <Component />
                    </div>
                    <div className="col-auto">
                        <span className={`icon icon-soft-${color}`}>
                            <i className={icon}></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
