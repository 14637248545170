import Login from "../views/Auth/Login";
import _404 from "../views/Pages/404";
import ForgotPassword from "../views/Auth/ForgotPassword";

const authRoutes = [
    {
        path: "/login",
        title: "Sign in",
        component: Login,
        layout: "/auth",
        prop: { exact: true },
        condition: true
    },
    {
        path: "/forgot-password",
        title: "Forgot Password?",
        component: ForgotPassword,
        layout: "/auth",
        prop: { exact: true },
        condition: true
    },
    {
        path: "*",
        layout: "",
        title: "404",
        component: _404
    }
];

export default authRoutes;
