import React from "react";

/**
 * @param {string} children: Button Text
 * @param {string} [color=primary]: [primary, secondary, success, danger, warning, info, light, dark]
 * @param {string} [type=submit]: [button, submit, clear]
 * @param {boolean} [loading=false]: [true/false]
 * @param {boolean} [loadingWithText=false]: [true/false]
 *
 */
const Default = React.forwardRef(
    (
        {
            children,
            color = "primary",
            type = "submit",
            loading = false,
            loadingWithText = false,
            className = "",
            ...props
        },
        ref
    ) => {
        return (
            <button
                ref={ref}
                className={`btn btn-${color} ${loading ? "disabled" : ""} ${
                    loading && !loadingWithText ? "btn-loading" : ""
                } ${className}`}
                type={type}
                disabled={loading}
                {...props}>
                {loading && loadingWithText ? (
                    <span>
                        <span
                            className={`spinner-border spinner-border-${
                                className?.indexOf("btn-sm") === -1 ? "md" : "sm"
                            }`}
                            role="status"
                            aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                    </span>
                ) : (
                    children
                )}
            </button>
        );
    }
);

export default Default;
