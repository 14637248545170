import React from "react";

/**
 *
 * @param {string} [value] Input value
 * @param {string} [className] Input extra class
 * @param {string} [placeholder] Input Placeholder
 * @param {string} [size=md] Input Size [sm, md, lg]
 * @param {string} [text=text] Input Type
 * @param {string} name Input name
 * @param {string} id Input id
 */
const Text = React.forwardRef(
    ({ value, className, size = "md", type = "text", name, id, placeholder, ...props }, ref) => {
        return (
            <input
                ref={ref}
                type={type}
                className={`form-control form-control-${size} ${className}`}
                name={name}
                id={id}
                placeholder={placeholder}
                value={value}
                {...props}
            />
        );
    }
);

export default Text;
