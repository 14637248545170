import axios from "axios";
import Swal from "sweetalert2";

import i18n from "../../utils/i18n";
import "../defaults";
import { GET_MAIN_PROCESS, UPDATE_MAIN_PROCESS, LIST_MAIN_PROCESSES } from "../constants";

export const GetMainProcess = params => {
    try {
        return axios
            .post(GET_MAIN_PROCESS, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const UpdateMainProcess = params => {
    try {
        return axios
            .post(UPDATE_MAIN_PROCESS, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};

export const ListMainProcesses = params => {
    try {
        return axios
            .post(LIST_MAIN_PROCESSES, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                Swal.fire({
                    title: "Oops!",
                    text: i18n.t("Sorry, your request failed. Please try again later."),
                    icon: "error"
                });
            });
    } catch (error) {}
};
