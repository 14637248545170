import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { API_URL, GET_TRANSLATIONS } from "../api/constants";

const nonExplicitCode = {
    tr: "tr-TR",
    en: "en-US",
    "tr-TR": "tr-TR",
    "en-US": "en-US"
};

export const getLanguage = () => {
    return (
        i18n.language ||
        (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
        nonExplicitCode[navigator.language]
    );
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        whitelist: ["tr-TR", "en-US"],
        supportedLngs: ["tr-TR", "en-US"],
        fallbackLng: false,
        returnEmptyString: false, // if key missing, returns the key
        interpolation: {
            escapeValue: false
        },
        keySeparator: false,
        backend: {
            loadPath: `${API_URL}${GET_TRANSLATIONS}?lng={{lng}}`
        }
    });

export default i18n;
