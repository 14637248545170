import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";

import Mini from "../Cards/Mini";
import { GetRealTimeAnalytics } from "../../api/services/social-distance-counter";
import { GetMainProcess } from "../../api/services/main-processes";
import { getStoreKey, getAccountInformation } from "../../utils/information";
import { useIsMountedRef } from "../../utils/core";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";
const REQUEST_DURATION = 60000;

const capacityColor = fullnessRatio => {
    if (fullnessRatio < 80) return "success";
    else if (fullnessRatio < 100) return "warning";
    else return "danger";
};

const capacityIcon = fullnessRatio => {
    if (fullnessRatio < 80) return "fa fa-user";
    else if (fullnessRatio < 100) return "fa fa-user-friends";
    else return "fa fa-users";
};

const updatedTime = time => (
    <div className="h4 mb-0">
        <span className="badge badge-soft-secondary mt-2">
            <i className="tio-time"></i> {time.format(DATE_FORMAT)}
        </span>
    </div>
);

const VisitorCard = ({ onResponse, mainProcess, subProcesses }) => {
    const [settings, setSettings] = useState({});
    const { current_store_id } = getAccountInformation();

    const [currentData, setCurrentData] = useState({
        count: 0,
        date: undefined,
        remainingLimit: 0,
        fullnessRatio: 0
    });
    const [totalTodayVisitors, setTotalTodayVisitors] = useState(0);
    const [updatedAt, setUpdatedAt] = useState(moment());
    const isMountedRef = useIsMountedRef();

    const generateCardData = (data, settings) => {
        let capacity = settings?.capacity;
        capacity = parseInt(capacity) || 0;

        let remainingLimit = capacity - (data?.count || 0);
        let fullnessRatio = 100 - Math.round((remainingLimit * 100) / capacity);

        setCurrentData({
            ...data,
            remainingLimit,
            fullnessRatio: isNaN(fullnessRatio) ? 0 : fullnessRatio
        });
    };

    const generateGraphData = data => {
        setTotalTodayVisitors(_.sumBy(data.data, "in"));
        onResponse(data.data);
    };

    const fetchData = (dataType, callback) => {
        GetRealTimeAnalytics({
            sid: getStoreKey(),
            dt: dataType,
            spid: subProcesses,
            rnd: new Date().getTime()
        }).then(result => {
            if (isMountedRef.current) {
                if (result) callback(result);
                setUpdatedAt(moment());
            }
        });
    };

    useEffect(() => {
        let fetchInterval;

        GetMainProcess({
            main_process_id: mainProcess,
            store_id: current_store_id
        }).then(response => {
            if (isMountedRef.current) {
                let { settings } = response.data;

                setSettings(settings);

                if (subProcesses) {
                    fetchData("live", result => generateCardData(result, settings));
                    fetchData("graph", generateGraphData);

                    fetchInterval = setInterval(() => {
                        fetchData("live", result => generateCardData(result, settings));
                        fetchData("graph", generateGraphData);
                    }, REQUEST_DURATION);
                }
            }
        });

        return () => {
            clearInterval(fetchInterval);
        };
    }, [isMountedRef, subProcesses]);

    return (
        <div className="row">
            <div className="col-md-6 mb-3 mb-lg-5">
                <Mini
                    title="Current Customer Count"
                    icon="fa fa-user"
                    count={currentData.count}
                    trending={() => updatedTime(updatedAt)}
                />
            </div>
            <div className="col-md-6 mb-3 mb-lg-5">
                <Mini
                    title="Total Count Customers Daily"
                    icon="fa fa-users"
                    color="info"
                    count={totalTodayVisitors}
                    trending={() => updatedTime(updatedAt)}
                />
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
                <Mini
                    title="Current Fullness Ratio"
                    icon={capacityIcon(currentData.fullnessRatio)}
                    color={capacityColor(currentData.fullnessRatio)}
                    count={`${currentData.fullnessRatio}%`}
                    trending={() => updatedTime(updatedAt)}
                />
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
                <Mini
                    title="Remaining Capacity"
                    icon={capacityIcon(currentData.fullnessRatio)}
                    color={capacityColor(currentData.fullnessRatio)}
                    count={currentData.remainingLimit}
                    trending={() => updatedTime(updatedAt)}
                />
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
                <Mini
                    title="Maximum Capacity"
                    icon="fa fa-users"
                    color="secondary"
                    count={settings.capacity}
                    trending={() => updatedTime(updatedAt)}
                />
            </div>
        </div>
    );
};

export default VisitorCard;
