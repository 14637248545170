import $ from "jquery";

import removeFullScreenClass from "./methods/remove-class";
import toggleFullScreenClass from "./methods/toggle-class";

export default class CMZFullscreen {
    constructor(elem, settings) {
        this.elem = elem;
        this.defaults = {
            targetEl: null,
            mainContainerSelector: "body",
            toggleClassName: ".cmz-fullscreen",
            preventScrollClassName: ".cmz-fullscreen-on"
        };
        this.settings = settings;
    }

    init() {
        const context = this,
            $el = context.elem,
            dataSettings = $el.attr("data-cmz-fullscreen-options")
                ? JSON.parse($el.attr("data-cmz-fullscreen-options"))
                : {},
            options = $.extend(true, context.defaults, dataSettings, context.settings);

        $el.attr("data-cmz-fullscreen-invoker", "");

        $el.on("click", function() {
            toggleFullScreenClass($(options.targetEl), options);
        });

        $(document).on("keydown", function(e) {
            if (!$(options.targetEl).hasClass(options.toggleClassName.slice(1))) return;
            if (e.keyCode === 27) removeFullScreenClass($(options.targetEl), options);
        });
    }
}
