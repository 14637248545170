import moment from "moment";
import _ from "lodash";

const getAccountInformation = (key = "user") => {
    try {
        let info = JSON.parse(localStorage.getItem("CMZ:L") || {});

        return info[key] || {};
    } catch (error) {
        return {};
    }
};

const getAccessToken = () => {
    try {
        return JSON.parse(localStorage.getItem("CMZ:T")).token;
    } catch (error) {
        return null;
    }
};

const getRefreshToken = () => {
    return localStorage.getItem("CMZ:R");
};

const getEmail = () => {
    return localStorage.getItem("CMZ:email");
};

const getStoreKey = () => {
    try {
        return getAccountInformation().current_store_key;
    } catch (error) {
        return null;
    }
};

const getMenus = () => {
    try {
        let menus = getAccountInformation("menus");

        return _.isEmpty(menus) ? [] : menus;
    } catch (error) {
        return [];
    }
};

const getUserStores = () => {
    try {
        return getAccountInformation().stores;
    } catch (error) {
        return [];
    }
};

const getStoreTimezone = () => {
    try {
        return getAccountInformation("store").timezone;
    } catch (error) {
        return 0;
    }
};

const isUserGroup = user_group => {
    try {
        let groups = getAccountInformation().user_groups;

        return groups.indexOf(user_group) > -1;
    } catch (error) {
        return false;
    }
};

const nullCheck = (value, fallback) => {
    try {
        if (fallback === undefined) fallback = "—";

        return value ? value : fallback;
    } catch (error) {
        return value;
    }
};

const fullnameGenerator = (name, surname) => {
    return `${nullCheck(name, "")} ${nullCheck(surname, "")}`;
};

const avatarPlaceholder = (name, surname) => {
    return nullCheck(name, "").slice(0, 1) + nullCheck(surname, "").slice(0, 1);
};

const formatDate = (date, format = "YYYY-MM-DD") => {
    return date && moment(date).isValid() ? moment(date).format(format) : "—";
};

export {
    getAccountInformation,
    getAccessToken,
    getRefreshToken,
    getEmail,
    getStoreKey,
    getMenus,
    getUserStores,
    getStoreTimezone,
    isUserGroup,
    nullCheck,
    fullnameGenerator,
    avatarPlaceholder,
    formatDate
};
