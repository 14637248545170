import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 *
 * @param {string} [dateFormat=HH:mm]: Picker format
 * @param {string} startTime: Start Time
 * @param {string} endTime: End Time
 * @param {function} handleStart: Start picker handle on change
 * @param {function} handleEnd: End picker handle on change
 * @param {string} [placement=bottom-end]: Picker popover placement ["auto", "auto-left", "auto-right", "bottom", "bottom-end", "bottom-start", "left", "left-end", "left-start", "right", "right-end", "right-start", "top", "top-end", "top-start"]
 * @param {string} [size=sm]: Picker input size [sm, md, lg]
 * @param {integer} [timeIntervals=5]: Time picker interval
 * @param {string} className: Picker input's extra class
 */
const TimeRange = ({
    dateFormat = "HH:mm",
    startTime,
    endTime,
    placement = "bottom-end",
    size = "sm",
    timeIntervals = 5,
    className,
    handleStart,
    handleEnd,
    props
}) => {
    const { t } = useTranslation();

    return (
        <div className="row gx-1 mt-2 mt-sm-0">
            <div className="col">
                <DatePicker
                    className={`form-control form-control-${size} ${className}`}
                    startDate={startTime}
                    placeholderText={endTime ? moment(endTime).format(dateFormat) : ""}
                    onChange={handleStart}
                    selected={startTime}
                    dateFormat={dateFormat}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={timeIntervals}
                    timeCaption={t("Hour")}
                    popperPlacement={placement}
                    disabledKeyboardNavigation
                    {...props}
                />
            </div>
            <div className="col">
                <DatePicker
                    className={`form-control form-control-${size} ${className}`}
                    endDate={startTime}
                    placeholderText={endTime ? moment(endTime).format(dateFormat) : ""}
                    onChange={handleEnd}
                    selected={endTime}
                    dateFormat={dateFormat}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={timeIntervals}
                    timeCaption={t("Hour")}
                    popperPlacement={placement}
                    disabledKeyboardNavigation
                    {...props}
                />
            </div>
        </div>
    );
};

export default TimeRange;
