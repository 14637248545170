import $ from "jquery";

import addFullScreenClass from "./add-class";
import removeFullScreenClass from "./remove-class";

export default function toggleFullScreenClass(el, params) {
    const options = params;

    if (!el.hasClass(options.toggleClassName.slice(1))) {
        addFullScreenClass(el, options);
    } else {
        removeFullScreenClass(el, options);
    }
}
