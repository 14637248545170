import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

/**
 *
 * @param {integer} [currentDay] Select the default option, matches option ids
 * @param {function} onChange Returns a date range when options change
 * @param {boolean} [loading=false] [true/false]
 */
const Day = ({ currentDay, onChange, loading = false }) => {
    const { t } = useTranslation();

    return (
        <div className="btn-group mt-2 mt-sm-0">
            <button
                className={`btn btn-secondary dropdown-toggle ${loading ? "disabled" : ""}`}
                type="button"
                id="dayDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={loading}>
                {loading ? (
                    <span>
                        <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                    </span>
                ) : (
                    t(
                        moment()
                            .day(currentDay)
                            .format("dddd")
                    )
                )}
            </button>
            <div className="dropdown-menu" aria-labelledby="dayDropdown">
                {_.map(moment.weekdays(true), (day, index) => {
                    let dayId = index + 1;

                    return (
                        <button
                            type="button"
                            className={`dropdown-item ${dayId === currentDay ? "active" : ""}`}
                            onClick={() => {
                                if (dayId !== currentDay) {
                                    onChange(dayId);
                                }
                            }}
                            key={dayId}>
                            {t(day)}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Day;
