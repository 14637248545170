import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageHeader from "../../components/App/PageHeader";
import PermissionChecker from "../../components/PermissionChecker";
import { isUserGroup } from "../../utils/information";

const Analysis = () => {
    const { t } = useTranslation();

    return (
        <PermissionChecker condition={isUserGroup("support")} description="You don't have permission for Analysis.">
            <div className="content container-fluid">
                <PageHeader
                    component={() => (
                        <Link to="/analysis/select-camera" className="btn btn-primary">
                            <i className="fa fa-plus-square"></i> {t("Create Analysis")}
                        </Link>
                    )}>
                    {t("Analysis")}
                </PageHeader>
            </div>
        </PermissionChecker>
    );
};

export default Analysis;
