import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import PageHeader from "../../PageHeader";
import DataLoader from "../../../../components/DataLoader";
import CardCheckbox from "../components/CardCheckbox";
import Default from "../../../Buttons/Default";
import PermissionChecker from "../../../PermissionChecker";
import { useData } from "../DataContext";
import { isUserGroup } from "../../../../utils/information";
import { ListAlgorithms } from "../../../../api/services/products";
import { UpdateAnalysis } from "../../../../api/services/analysis";
import { useIsMountedRef } from "../../../../utils/core";

const Algorithms = () => {
    const { setValues, data } = useData();
    const isMountedRef = useIsMountedRef();
    const [selected, setSelected] = useState(data?.algorithm || 0);
    const [algorithms, setAlgorithms] = useState(null);
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();
    const { t } = useTranslation();

    const onSubmit = () => {
        setLoading(true);

        if (data.product) {
            if (data.algorithm !== selected) {
                UpdateAnalysis({
                    sub_process_id: data.sub_process,
                    product_algorithm_id: selected
                }).then(result => {
                    if (isMountedRef.current) {
                        if (result?.status === 200) {
                            setValues({ algorithm: selected });
                            push("/analysis/framer");
                        }

                        setLoading(false);
                    }
                });
            } else push("/analysis/framer");
        }
    };

    const fetchAlgorithms = () => {
        ListAlgorithms({ product_id: data.product }).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) {
                    setAlgorithms(result?.data || []);
                } else {
                    setAlgorithms([]);
                    setSelected(0);
                    setValues({ algorithm: 0 });
                }
            }
        });
    };

    useEffect(() => {
        if (!data.camera) push("/analysis/select-camera");
        else if (!data.product) push("/analysis/select-product");
    }, []);

    useEffect(() => {
        fetchAlgorithms();
    }, [isMountedRef]);

    return (
        <PermissionChecker condition={isUserGroup("support")} description="You don't have permission for Analysis.">
            <div className="content container-fluid">
                <PageHeader
                    description={data.name}
                    component={() => (
                        <>
                            <Link to="/analysis/select-product" className="btn btn-outline-secondary mr-2">
                                {t("Back")}
                            </Link>
                            <Default
                                loading={loading}
                                type="button"
                                color="success"
                                disabled={selected ? false : true}
                                onClick={onSubmit}>
                                {t("Next")}
                            </Default>
                        </>
                    )}>
                    {t("Select Algorithm")}
                </PageHeader>
                <div className="row">
                    <DataLoader data={algorithms}>
                        {algorithms?.map(algorithm => (
                            <div key={algorithm.id} className="col-md-4 col-sm-6 mb-4">
                                <CardCheckbox
                                    id={algorithm.id}
                                    title={algorithm.name}
                                    onChange={setSelected}
                                    selected={selected}
                                    buttonText="Algorithm"
                                    name="algorithms"
                                    description={algorithm.description}
                                    icon={algorithm.icon}
                                />
                            </div>
                        ))}
                    </DataLoader>
                </div>
            </div>
        </PermissionChecker>
    );
};

export default Algorithms;
