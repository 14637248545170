import React, { useEffect } from "react";
import $ from "jquery";

const IconPopover = ({
    title,
    content,
    placement = "bottom",
    trigger = "hover",
    icon = "tio-info-outined",
    className
}) => {
    useEffect(() => {
        $('[data-toggle="popover"]').popover({ trigger: trigger });

        return () => $('[data-toggle="popover"]').popover("dispose");
    });

    return (
        <span
            className={`d-inline text-center cursor-pointer ${className}`}
            data-container="body"
            title={title}
            data-content={content}
            data-placement={placement}
            data-toggle="popover">
            <i className={icon}></i>
        </span>
    );
};

export default IconPopover;
