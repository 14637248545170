import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { useIsMountedRef } from "../utils/core";
import { SessionStart, RefreshToken } from "../api/services/sessions";
import SuspenseLoader from "./SuspenseLoader";
import { getAccessToken, getRefreshToken, getEmail } from "../utils/information";

const SessionProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const isMountedRef = useIsMountedRef();
    const REQUEST_DURATION = 1000 * 60 * 1; // ms * sec * min
    const TOKEN_CHECK_DURATION = 1000 * 5; // ms * sec

    const fetchSession = () => {
        axios.defaults.headers.common["Authorization"] = getAccessToken();

        SessionStart().then(result => {
            if (isMountedRef.current) {
                if (result?.data) {
                    let partner = result?.data?.partner;

                    localStorage.setItem("CMZ:L", JSON.stringify({ ...result.data }));

                    if (partner && !partner.is_active) {
                        localStorage.removeItem("CMZ:L");
                        localStorage.removeItem("CMZ:T");
                        localStorage.removeItem("CMZ:R");

                        history.push("/auth/login");
                    }
                }

                setLoading(false);
            }
        });
    };

    const fetchToken = () => {
        if (!getAccessToken()) {
            RefreshToken({
                email: getEmail(),
                refresh_token: getRefreshToken()
            }).then(result => {
                let data = result.data;
                let expired = moment()
                    .add(23, "hours")
                    .format("YYYY-MM-DD HH:mm:ss");

                localStorage.setItem("CMZ:R", data.refresh_token);
                localStorage.setItem(
                    "CMZ:T",
                    JSON.stringify({
                        token: data.access_token,
                        expired: expired
                    })
                );

                fetchSession();
            });
        } else fetchSession();
    };

    const checkTokenExpireTime = () => {
        try {
            let expired = JSON.parse(localStorage.getItem("CMZ:T")).expired;

            if (expired && moment().isAfter(moment(expired))) {
                localStorage.removeItem("CMZ:T");
                fetchToken();
            }
        } catch (error) {
            localStorage.removeItem("CMZ:T");
            fetchToken();
        }
    };

    useEffect(() => {
        checkTokenExpireTime();
        fetchToken();

        const fetchInterval = setInterval(fetchToken, REQUEST_DURATION);
        const tokenCheckInterval = setInterval(checkTokenExpireTime, TOKEN_CHECK_DURATION);

        return () => {
            clearInterval(fetchInterval);
            clearInterval(tokenCheckInterval);
        };
    }, [isMountedRef]);

    return <>{loading ? <SuspenseLoader /> : children}</>;
};

export default SessionProvider;
