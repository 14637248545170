import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ExcelExports from "fusioncharts/fusioncharts.excelexport";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import DataNotFound from "../../State/DataNotFound";
import SingleDate from "../../DataFilter/SingleDate";
import { GetPackageOutput } from "../../../api/services/technical-metrics";
import { fusionChartGeneralDefaults, fusionChartExportDefaults } from "../../../utils/settings";
import { useIsMountedRef } from "../../../utils/core";
import { captureException } from "../../../utils/handlers";
import ChartsScrollView from "../../Info/ChartsScrollView";

ReactFC.fcRoot(FusionCharts, Charts, ExcelExports, FusionTheme);

const chartConfigs = (data, translations, scrollView) => ({
    type: "scrollcolumn2d",
    width: "100%",
    height: "450",
    dataFormat: "json",
    dataSource: {
        chart: {
            ...fusionChartGeneralDefaults(scrollView),
            ...fusionChartExportDefaults(translations.chart),
            showLegend: "0",
            formatNumberScale: "1",
            numberScaleValue: "1024,1024,1024",
            numberScaleUnit: " KB, MB, GB",
            defaultNumberScale: " Bytes",
            scaleRecursively: "0",
            paletteColors: "#00c9a7",
            xAxisname: translations.hour,
            yAxisname: translations.size
        },
        // Chart Data
        ...data
    }
});

const PackageOutput = ({ cards }) => {
    const [selected, setSelected] = useState(moment());
    const [data, setData] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const [scrollView, setScrollView] = useState(true);
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    const TRANSLATIONS = {
        chart: t("Package Output"),
        hour: t("Hour"),
        size: t("Package Size")
    };

    const handleChange = date => {
        date = moment(date);

        if (date.isValid()) setSelected(date);
    };
    const handleSubmit = () => {
        if (selected.isValid()) {
            setLoading(true);
            fetchData();
        }
    };

    const fetchData = () => {
        try {
            let date = selected.format("YYYY-MM-DD");

            GetPackageOutput({
                start_date: date,
                end_date: date,
                cards: cards
            }).then(response => {
                if (isMountedRef.current) {
                    if (!_.isEmpty(response.data)) {
                        let category = _.map(response.data, function (item) {
                            return {
                                label: moment(item.date).add(3, "hours").format("HH:mm")
                            };
                        });

                        let package_list = {
                            seriesname: TRANSLATIONS.size,
                            data: _.map(response.data, function (item) {
                                return {
                                    value: item.value
                                };
                            })
                        };

                        setData({
                            categories: [
                                {
                                    category
                                }
                            ],
                            dataset: [package_list]
                        });
                    } else setData(null);

                    setLoading(false);
                }
            });
        } catch (error) {
            captureException(error);
        }
    };

    useEffect(() => {
        if (cards) fetchData();
    }, [isMountedRef, cards]);

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">{TRANSLATIONS.chart}</h4>
                    <SingleDate
                        selected={selected.toDate()}
                        maxDate={moment().toDate()}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        loading={loading}
                    />
                </div>
                <ChartsScrollView onChange={event => setScrollView(event.target.checked)} />
                <div className="card-body pb-1 pl-1">
                    {data ? (
                        <ReactFC {...chartConfigs(data, TRANSLATIONS, scrollView)} />
                    ) : (
                        <DataNotFound className="pb-3 pl-3" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PackageOutput;
