import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { w3cwebsocket } from "websocket";
import $ from "jquery";

import PermissionChecker from "../../../components/PermissionChecker";
import Fullscreen from "../../../components/Buttons/Fullscreen";
import FramePlayer from "../../../utils/frame-player";
import { isUserGroup, getAccountInformation } from "../../../utils/information";
import { CameraWatcherStatusChange } from "../../../api/services/cameras";
import { useIsMountedRef } from "../../../utils/core";

const Watch = () => {
    const websocket = useRef(null);
    const isMountedRef = useIsMountedRef();
    const [loading, setLoading] = useState({ broadcast: true, stop: false });
    const [error, setError] = useState(false);
    const { camera_id } = useParams();
    const { location, push } = useHistory();
    const { current_store_key, current_store_id, user_id } = getAccountInformation();
    const { t } = useTranslation();

    const stopWatcher = () => {
        setLoading(prevState => ({ ...prevState, stop: true }));

        CameraWatcherStatusChange({
            camera_id: camera_id,
            user_id: user_id,
            store_id: current_store_id,
            status: 0
        }).then(response => {
            if (isMountedRef.current) {
                if (response && response.status === 200) push("/live-cameras");
            }
        });
    };

    const startWebsocket = () => {
        if (websocket.current) websocket.current.close();

        setLoading(prevState => ({ ...prevState, broadcast: true }));
        $("#player > canvas").remove();
        setError(false);

        websocket.current = new w3cwebsocket(
            `wss://device.api.camera.cameralyze.com/${current_store_key}?ct=receiver&cid=${camera_id}`
        );
        websocket.current.onclose = () => setLoading(prevState => ({ ...prevState, broadcast: true }));
        websocket.current.onmessage = e => {
            try {
                let data = JSON.parse(e.data);

                if (data?.frames) {
                    setLoading(prevState => ({ ...prevState, broadcast: false }));

                    let options = {
                        fps: 3,
                        data: data.frames
                    };

                    new FramePlayer("#player", options).play();
                }
            } catch (error) {
                setError(true);
                websocket.current.close();
            }
        };
    };

    useEffect(() => {
        if (!camera_id || !location?.state?.camera_name) push("/live-cameras");

        if (isUserGroup("operation-staff")) {
            startWebsocket();

            return () => {
                websocket.current.close();
            };
        }
    }, []);

    return (
        <PermissionChecker
            condition={isUserGroup("operation-staff")}
            description="You don't have permission for Live Cameras.">
            <div
                className="content container-fluid d-flex flex-column"
                style={{ minHeight: `calc(100vh - 3.75rem - 3.5rem)`, flex: 1 }}>
                <div className="row row-cards row-deck" style={{ flex: 1 }}>
                    <div className="col-12">
                        <div className="card" id="videoPlayer">
                            <div className="card-header">
                                <h4 className="card-header-title">{location?.state?.camera_name}</h4>
                                <div className="ml-0 ml-sm-auto mt-3 mt-sm-0">
                                    <button
                                        className={`btn btn-sm btn-outline-danger ${loading.stop ? "btn-loading" : ""}`}
                                        onClick={stopWatcher}>
                                        <i className="tio-pause mr-1"></i>
                                        {t("Stop")}
                                    </button>
                                    <button
                                        className="btn btn-sm btn-soft-secondary mx-2"
                                        onClick={() => push("/live-cameras")}>
                                        <i className="tio-format-points mr-1"></i>
                                        {t("Other Cameras")}
                                    </button>
                                    <Fullscreen cardId="#videoPlayer" />
                                </div>
                            </div>
                            <div className="card-body p-2 p-sm-4 d-flex justify-content-center align-items-center position-relative">
                                {error && (
                                    <div className="d-flex justify-content-center align-items-center flex-column position-absolute left-0 right-0 top-0 bottom-0 z-index-2 bg-white-75">
                                        <div className="text-dark-50 font-italic mb-2">
                                            {t("An error occurred. Please try again later.")}
                                        </div>
                                        <button className="btn btn-ghost-dark" onClick={startWebsocket}>
                                            <i className="fa fa-sync-alt"></i> {t("Try Again")}
                                        </button>
                                    </div>
                                )}
                                {!error && loading.broadcast ? (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">{t("Loading")}...</span>
                                    </div>
                                ) : (
                                    <div
                                        className="img-fluid rounded overflow-hidden d-flex h-100 w-100"
                                        id="player"></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PermissionChecker>
    );
};

export default Watch;
