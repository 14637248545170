import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import PageHeader from "../../components/App/PageHeader";
import PermissionChecker from "../../components/PermissionChecker";
import DataLoader from "../../components/DataLoader";
import { isUserGroup, getAccountInformation } from "../../utils/information";
import { ListCameras, CameraWatcherStatusChange } from "../../api/services/cameras";
import { useIsMountedRef } from "../../utils/core";

const LiveCameras = () => {
    const isMountedRef = useIsMountedRef();
    const [cameras, setCameras] = useState(null);
    const [loading, setLoading] = useState({});
    const { current_store_id, user_id } = getAccountInformation();
    const { push } = useHistory();
    const { t } = useTranslation();
    const REQUEST_DURATION = 1000 * 25; // ms * sec

    const watcherStatusChange = (camera_id, status) => {
        setLoading(prevState => ({ ...prevState, [camera_id]: true }));

        CameraWatcherStatusChange({
            camera_id: camera_id,
            user_id: user_id,
            store_id: current_store_id,
            status: status
        }).then(() => {
            if (isMountedRef.current) {
                listCameras();
                setLoading(prevState => ({ ...prevState, [camera_id]: false }));
            }
        });
    };

    const generateCameraStatusBadge = status => {
        let statusToTextAndColor = {
            0: { text: "Offline", color: "dark" },
            1: { text: "Online", color: "success" },
            2: { text: "Preparing", color: "warning" },
            3: { text: "Error", color: "danger" }
        };

        return (
            <div className="row align-items-center text-left mb-4">
                <div className="col">
                    <span className={`badge badge-soft-${statusToTextAndColor[status].color} p-2`}>
                        {t(statusToTextAndColor[status].text)}
                    </span>
                </div>
            </div>
        );
    };

    const generateCameraCardFooter = ({ id, name, is_open }) => {
        const loadingClass = loading[id] ? "btn-loading" : "";

        if (is_open === 0) {
            return (
                <button
                    className={`btn btn-outline-success btn-block ${loadingClass}`}
                    onClick={() => watcherStatusChange(id, 2)}>
                    <i className="tio-play mr-1"></i>
                    {t("Start")}
                </button>
            );
        } else if (is_open === 1) {
            return (
                <div className="row mb-n2 gx-2">
                    <div className="col mb-2">
                        <button
                            className="btn btn-outline-secondary btn-block"
                            onClick={() =>
                                push({
                                    pathname: `/live-cameras/watch/${id}`,
                                    state: { camera_name: name }
                                })
                            }>
                            <i className="tio-visible mr-1"></i>
                            {t("Watch")}
                        </button>
                    </div>
                    <div className="col mb-2">
                        <button
                            className={`btn btn-outline-danger btn-block ${loadingClass}`}
                            onClick={() => watcherStatusChange(id, 0)}>
                            <i className="tio-pause mr-1"></i>
                            {t("Stop")}
                        </button>
                    </div>
                </div>
            );
        } else if (is_open === 2) {
            return <button className="btn btn-outline-warning btn-block btn-loading">{t("Preparing")}</button>;
        } else if (is_open === 3) {
            return (
                <button
                    className={`btn btn-outline-dark btn-block ${loadingClass}`}
                    onClick={() => watcherStatusChange(id, 2)}>
                    <i className="tio-refresh mr-1"></i>
                    {t("Try Again")}
                </button>
            );
        }
    };

    const listCameras = () => {
        ListCameras({
            store_id: current_store_id
        }).then(result => {
            if (isMountedRef.current) {
                if (result && result.status === 200) {
                    setCameras(_.orderBy(result.data, "id"));
                } else setCameras([]);
            }
        });
    };

    useEffect(() => {
        if (isUserGroup("operation-staff")) {
            listCameras();

            const fetchInterval = setInterval(listCameras, REQUEST_DURATION);

            return () => clearInterval(fetchInterval);
        }
    }, [isMountedRef]);

    return (
        <PermissionChecker
            condition={isUserGroup("operation-staff")}
            description="You don't have permission for Live Cameras.">
            <div className="content container-fluid">
                <PageHeader>{t("Live Cameras")}</PageHeader>
                <div className="row row-deck">
                    <DataLoader data={cameras}>
                        {_.map(cameras, camera => (
                            <div className="col-sm-6 col-md-3 mb-3 mb-sm-4" key={camera.id}>
                                <div className="card card-hover-shadow text-center">
                                    <div className="card-body">
                                        {generateCameraStatusBadge(camera.is_open)}
                                        <div className="d-flex justify-content-center mb-3">
                                            <span className="icon icon-md icon-dark icon-circle">
                                                <i className="tio-surveillance-camera"></i>
                                            </span>
                                        </div>
                                        <h4>{camera.name}</h4>
                                    </div>
                                    <div className="card-footer">{generateCameraCardFooter(camera)}</div>
                                </div>
                            </div>
                        ))}
                    </DataLoader>
                </div>
            </div>
        </PermissionChecker>
    );
};

export default LiveCameras;
