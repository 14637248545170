import aes from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";

const SECRET_KEY = "cmz-sec-key";

const encryptText = text => {
    let encrypted = aes.encrypt(text, SECRET_KEY);

    return new Promise((resolve, reject) => {
        if (encrypted) {
            resolve(
                encrypted
                    .toString()
                    .replaceAll("+", "xMl3Jk")
                    .replaceAll("/", "Por21Ld")
                    .replaceAll("=", "Ml32")
            );
        } else {
            reject("encrypt error");
        }
    });
};

const decryptText = encrypted => {
    encrypted = encrypted
        .replaceAll("xMl3Jk", "+")
        .replaceAll("Por21Ld", "/")
        .replaceAll("Ml32", "=");

    let dencrypted = aes.decrypt(encrypted, SECRET_KEY);

    return new Promise((resolve, reject) => {
        if (dencrypted) {
            resolve(dencrypted.toString(enc));
        } else {
            reject("dencrypt error");
        }
    });
};

export { encryptText, decryptText };
