import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";

import CMZStickyBlock from "../../../assets/vendor/sticky-block/sticky-block";
import CMZScrollspy from "../../../assets/vendor/scrollspy/scrollspy";

const navItems = [
    {
        id: 0,
        name: "Basic information",
        icon: "tio-user-outlined",
        href: "#content",
        active: true
    },
    {
        id: 1,
        name: "Change your password",
        icon: "tio-lock-outlined",
        href: "#passwordSection",
        active: true
    },
    {
        id: 2,
        name: "Preferences",
        icon: "tio-settings-outlined",
        href: "#preferencesSection",
        active: false
    },
    {
        id: 3,
        name: "Two-step verification",
        icon: "tio-fingerprint",
        href: "#twoStepVerificationSection",
        active: false
    },
    {
        id: 4,
        name: "Recent devices",
        icon: "tio-devices-apple",
        href: "#recentDevicesSection",
        active: false
    },
    {
        id: 5,
        name: "Notifications",
        icon: "tio-notifications-on-outlined",
        href: "#notificationsSection",
        active: false
    }
];

const Navigation = () => {
    const [activeMenu, setActiveMenu] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        let content = $(".content");

        $(".js-sticky-block").each(function() {
            new CMZStickyBlock($(this), {
                targetSelector: $("#header").hasClass("navbar-fixed") ? "#header" : null
            }).init();
        });

        if (content.attr("data-cmz-scrollspy-invoker") === undefined) {
            new CMZScrollspy(content, {
                // !SETTING "resolve" PARAMETER AND RETURNING "resolve('completed')" IS REQUIRED
                beforeScroll: function(resolve) {
                    if (window.innerWidth < 992) {
                        $("#navbarVerticalNavMenu")
                            .collapse("hide")
                            .on("hidden.bs.collapse", function() {
                                return resolve("completed");
                            });
                    } else {
                        return resolve("completed");
                    }
                }
            }).init();
        }
    });

    return (
        <div className="navbar-vertical navbar-expand-lg mb-3 mb-lg-5">
            <button
                type="button"
                className="navbar-toggler btn btn-block btn-white mb-3"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navbarVerticalNavMenu"
                data-toggle="collapse"
                data-target="#navbarVerticalNavMenu">
                <span className="d-flex justify-content-between align-items-center">
                    <span className="h5 mb-0">Navigation</span>
                    <span className="navbar-toggle-default">
                        <i className="tio-menu-hamburger"></i>
                    </span>
                    <span className="navbar-toggle-toggled">
                        <i className="tio-clear"></i>
                    </span>
                </span>
            </button>
            <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
                <ul
                    id="navbarSettings"
                    className="js-sticky-block js-scrollspy navbar-nav navbar-nav-lg nav-tabs card card-navbar-nav"
                    data-cmz-sticky-block-options='{"parentSelector": "#navbarVerticalNavMenu","breakpoint": "lg","startPoint": "#navbarVerticalNavMenu","endPoint": "#stickyBlockEndPoint","stickyOffsetTop": 20}'>
                    {navItems.map(item => {
                        if (item.active) {
                            let activeClass = item.id === activeMenu ? "active" : "";

                            return (
                                <li className="nav-item" key={item.id} onClick={() => setActiveMenu(item.id)}>
                                    <a className={`nav-link ${activeClass}`} href={item.href}>
                                        <i className={`nav-icon ${item.icon}`}></i>
                                        {t(item.name)}
                                    </a>
                                </li>
                            );
                        } else return null;
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Navigation;
