import axios from "axios";

import "../defaults";
import { USER_GET_PROFILE, USER_UPDATE_PROFILE, USER_CHANGE_PASSWORD } from "../constants";
import { captureException } from "../../utils/handlers";

export const GetProfile = data => {
    try {
        return axios
            .post(USER_GET_PROFILE, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error, data);
            });
    } catch (error) {}
};

export const UpdateProfile = data => {
    try {
        return axios
            .post(USER_UPDATE_PROFILE, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error);
            });
    } catch (error) {}
};

export const ChangePassword = data => {
    try {
        return axios
            .post(USER_CHANGE_PASSWORD, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                captureException(error);
            });
    } catch (error) {}
};
