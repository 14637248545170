import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import moment from "moment";

import PageHeader from "../../components/App/PageHeader";
import { LIST_STORES, API_URL } from "../../api/constants";
import { ChangeUserStores } from "../../api/services/stores";
import { getAccountInformation, getAccessToken, isUserGroup, getStoreTimezone } from "../../utils/information";
import { useIsMountedRef } from "../../utils/core";
import { captureException } from "../../utils/handlers";

$.DataTable = require("datatables.net-responsive");

const Stores = () => {
    const { t } = useTranslation();
    const { user_id, current_store_id } = getAccountInformation();
    const tableEl = useRef();
    const isMountedRef = useIsMountedRef();

    const changeStore = store_id => {
        ChangeUserStores({
            user_id: user_id,
            store_id: store_id
        }).then(result => {
            if (isMountedRef.current) {
                if (result?.status === 200) {
                    window.location.reload();
                }
            }
        });
    };

    useEffect(() => {
        const table = $(tableEl.current).DataTable({
            order: [0, "asc"],
            aLengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, t("All")]
            ],
            ajax: {
                url: API_URL + LIST_STORES,
                type: "POST",
                datatype: "json",
                contentType: "application/json",
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", getAccessToken());
                },
                data: function(d) {
                    return JSON.stringify({
                        user_id: user_id
                    });
                },
                dataSrc: function(d) {
                    if (d.status !== 200) {
                        return [];
                    } else {
                        return d.data;
                    }
                }
            },
            columnDefs: [
                ...$.fn.dataTable.defaults.columnDefs,
                {
                    targets: "name",
                    createdCell: (td, cellData, { id }) => {
                        ReactDOM.render(
                            <div className="btn-link text-dark" onClick={() => changeStore(id)}>
                                {current_store_id === id && <i className="tio-star"></i>} {cellData}
                            </div>,
                            td
                        );
                    }
                }
            ],
            columns: [
                {
                    data: "name",
                    responsivePriority: 1
                },
                {
                    data: "store_key",
                    visible: isUserGroup("support"),
                    responsivePriority: 2
                },
                {
                    data: "opening_hour",
                    responsivePriority: 3,
                    render: function(data) {
                        return moment(data, "HH:mm:ss")
                            .add(getStoreTimezone(), "hours")
                            .format("HH:mm:ss");
                    }
                },
                {
                    data: "closing_hour",
                    responsivePriority: 3,
                    render: function(data) {
                        return moment(data, "HH:mm:ss")
                            .add(getStoreTimezone(), "hours")
                            .format("HH:mm:ss");
                    }
                },
                {
                    data: "town",
                    responsivePriority: 4
                },
                {
                    data: "city",
                    responsivePriority: 4
                },
                {
                    data: "region",
                    responsivePriority: 5
                },
                {
                    data: "country",
                    responsivePriority: 5
                }
            ]
        });

        table.on("error.dt", function(e, settings, techNote, message) {
            captureException("An error has been reported by DataTables", message);
        });

        table.on("draw.dt", function() {
            $('[data-toggle="tooltip"]').tooltip();
        });

        return () =>
            $(".data-table-wrapper")
                .find("table")
                .DataTable()
                .destroy(true);
    }, []);

    return (
        <div className="content container-fluid">
            <PageHeader>{t("Stores")}</PageHeader>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">{t("Store List")}</h4>
                        </div>
                        <div className="datatable-custom">
                            <table
                                ref={tableEl}
                                className="table table-bordered table-nowrap table-align-middle card-table w-100">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="name">{t("Store")}</th>
                                        <th className="store_key">{t("Store Key")}</th>
                                        <th className="opening_hour">{t("Opening Hour")}</th>
                                        <th className="closing_hour">{t("Closing Hour")}</th>
                                        <th className="town">{t("Town")}</th>
                                        <th className="city">{t("City")}</th>
                                        <th className="region">{t("Region")}</th>
                                        <th className="country">{t("Country")}</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stores;
