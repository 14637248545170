import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import Title from "../../components/Title";
import { getStoreKey, getAccountInformation } from "../../utils/information";
import { decryptText } from "../../utils/crypto";
import { GetRealTimeAnalytics } from "../../api/services/social-distance-counter";
import { GetMainProcess } from "../../api/services/main-processes";
import { useIsMountedRef } from "../../utils/core";
import { captureException } from "../../utils/handlers";
import AlertBar from "../../components/AlertBar";

const REQUEST_DURATION = 3000;
const MESSAGE_DURATION = 10000;

const SocialDistanceCounterBox = () => {
    const [settings, setSettings] = useState({});
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState("There is a connection issue.");
    const [count, setCount] = useState(0);
    const [ratio, setRatio] = useState(0);
    const [messageIndex, setMessageIndex] = useState(0);
    const [messageEffect, setMessageEffect] = useState("show");
    const isMountedRef = useIsMountedRef();
    const { box_key } = useParams();
    const { push } = useHistory();
    const { current_store_id } = getAccountInformation();

    const loopBoxMessage = settings => {
        let messageLength = settings.messages.length - 1;

        setMessageEffect("fade");

        setTimeout(() => {
            setMessageIndex(prevCount => (prevCount < messageLength ? prevCount + 1 : 0));
            setMessageEffect("show");
        }, 300);
    };

    const generateMessageAndIntenseColor = ratio => {
        let color = settings.danger_color,
            message = settings.warning_message,
            icon = "tio-warning";

        if (ratio < 80) {
            color = settings.success_color;
            message = settings.entry_message;
            icon = "tio-group-senior";
        } else if (ratio < 100) {
            color = settings.warning_color;
            message = settings.entry_message;
            icon = "tio-group-senior";
        }

        return { color, message, icon };
    };

    const showError = () => {
        setAlert(true);
        setAlertText("An error occurred. You will be redirected in 5 seconds...");
        setTimeout(() => push("/"), 5000);
    };

    const fetchCount = (settings, subProcesses) => {
        GetRealTimeAnalytics(
            {
                sid: getStoreKey(),
                dt: "live",
                spid: subProcesses,
                rnd: new Date().getTime()
            },
            false
        ).then(response => {
            if (isMountedRef.current) {
                if (response) {
                    let count = response.count;
                    let calculateRatio = Math.round((count * 100) / settings.capacity);

                    setAlert(false);
                    setCount(count);
                    setRatio(calculateRatio);
                } else setAlert(true);
            }
        });
    };

    useEffect(() => {
        let fetchInterval, messageInterval;
        let key = box_key ? box_key : sessionStorage.getItem("CMZ:BK");

        decryptText(key).then(result => {
            try {
                result = JSON.parse(result || {});

                if (_.isObject(result) && !_.isEmpty(result)) {
                    GetMainProcess({
                        main_process_id: result.main_process_id,
                        store_id: current_store_id
                    }).then(response => {
                        if (isMountedRef.current) {
                            if (response?.status === 200) {
                                const { settings } = response.data;

                                setSettings(settings);
                                fetchCount(settings, result.sub_process_id);

                                fetchInterval = setInterval(
                                    () => fetchCount(settings, result.sub_process_id),
                                    REQUEST_DURATION
                                );
                                messageInterval = setInterval(() => loopBoxMessage(settings), MESSAGE_DURATION);
                            } else setAlert(true);
                        }
                    });
                } else {
                    showError();
                }
            } catch (error) {
                captureException(error);
                showError();
            }
        });

        return () => {
            clearInterval(fetchInterval);
            clearInterval(messageInterval);
        };
    }, [isMountedRef]);

    return (
        <main id="content" role="main" className="main bg-light">
            <Title title="Social Distance Counter" />
            {alert && <AlertBar errorText={alertText} />}
            <div className="container-fluid d-flex flex-column pt-5 pb-7" style={{ minHeight: "100vh", flex: 1 }}>
                <div className="row row-cards row-deck gx-1" style={{ flex: 1 }}>
                    <div className="col-lg-3 col-md-3 col-sm-3 mb-4">
                        <div className="card rounded">
                            <div className="card-body d-flex justify-content-center align-items-center p-2">
                                {settings.logo && <img className="w-100" src={settings.logo} alt="Partner Logo" />}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 mb-4">
                        <div className="card rounded">
                            <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                <div className="display-1 text-center mb-2" style={{ color: settings.title_color }}>
                                    {settings.title}
                                </div>
                                <div
                                    className="display-2 font-weight-normal text-center"
                                    style={{ color: generateMessageAndIntenseColor(ratio).color }}>
                                    {generateMessageAndIntenseColor(ratio).message}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cards row-deck" style={{ flex: 1 }}>
                    <div className="col-12 mb-4">
                        <div className="card rounded">
                            <div className="card-body d-flex justify-content-center align-items-center">
                                <div
                                    className={`display-4 text-center ${messageEffect}`}
                                    style={{ color: settings.messages_color }}>
                                    {settings.messages ? settings.messages[messageIndex] : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cards row-deck gx-1" style={{ flex: 1 }}>
                    <div className="col-lg-6 col-md-6 mb-4">
                        <div className="card rounded">
                            <div
                                className="card-body p-7 text-dark d-flex justify-content-center align-items-center flex-column"
                                style={{ backgroundColor: generateMessageAndIntenseColor(ratio).color }}>
                                <div className="display-4">{settings.visitor_count_text}</div>
                                <div className="display-lg-1 display-breakpoint">
                                    <i className={`${generateMessageAndIntenseColor(ratio).icon} mr-1`}></i>
                                    {count}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                        <div className="card rounded">
                            <div
                                className="card-body p-7 text-dark d-flex justify-content-center align-items-center flex-column"
                                style={{ backgroundColor: generateMessageAndIntenseColor(ratio).color }}>
                                <div className="display-4">{settings.fullness_ratio_text}</div>
                                <div className="display-lg-1">%{ratio}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card rounded">
                            <div className="card-body p-3">
                                {settings.max_fullness_text && (
                                    <div className="display-4 text-center">
                                        {settings.max_fullness_text}{" "}
                                        <span className="text-dark">{settings.capacity}</span> kişidir.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-dark text-white-50 p-1 font-size-sm position-absolute bottom-0 left-0 w-100 text-right">
                    Copyright &copy;{" "}
                    <span>
                        {moment().year()} Cameralyze,{" "}
                        <a className="text-white-50" href="mailto:info@cameralyze.com">
                            info@cameralyze.com
                        </a>
                    </span>
                </div>
            </div>
        </main>
    );
};

export default SocialDistanceCounterBox;
