import React from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import App from "./App";
import Auth from "./Auth";
import _404 from "../views/Pages/404";
import SocialDistanceCounterBox from "../views/App/SocialDistanceCounterBox";
import { getAccessToken, getRefreshToken } from "../utils/information";
import { DataProvider } from "../components/App/Analysis/DataContext";

const auth = () => (getAccessToken() && getRefreshToken() ? true : false);

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (auth() === true ? <Component {...props} /> : <Redirect to="/auth" />)} />
);

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (auth() === false ? <Component {...props} /> : <Redirect to="/" />)} />
);

const Core = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/404" component={_404} />
                <PublicRoute path="/auth" component={Auth} />
                <PrivateRoute path="/box/:box_key?" component={SocialDistanceCounterBox} />
                <DataProvider>
                    <PrivateRoute exact path="/account" component={App} />
                    <PrivateRoute path="/" component={App} />
                </DataProvider>
            </Switch>
        </Router>
    );
};

export default Core;
