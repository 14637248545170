import { useEffect, useRef } from "react";
import _ from "lodash";

import i18n from "./i18n";

Number.prototype.readable = function readable() {
    try {
        let convert_string = this.toLocaleString("fullwide", { useGrouping: false });

        return convert_string.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    } catch (error) {
        return 0;
    }
};

Number.prototype.duration = function duration() {
    let duration = Number(this);
    let hour = Math.floor(duration / 3600);
    let minute = Math.floor((duration % 3600) / 60);
    let second = Math.floor((duration % 3600) % 60);

    let hourDisplay = hour > 0 ? hour + (hour === 1 ? ` ${i18n.t("hour")} ` : ` ${i18n.t("hours")} `) : "";
    let minutesDisplay = minute > 0 ? minute + (minute === 1 ? ` ${i18n.t("minute")} ` : ` ${i18n.t("minutes")} `) : "";
    let secondDisplay = second > 0 ? second + ` ${i18n.t("seconds")}` : "";

    return (hourDisplay + minutesDisplay + secondDisplay).trim();
};

export const useIsMountedRef = () => {
    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;

        return () => (isMountedRef.current = false);
    });

    return isMountedRef;
};
